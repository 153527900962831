import React from 'react';
import Modal from 'react-modal';
Modal.setAppElement('body');

function ClearReasonPopup({
    open,
    close,
    clearReason,
    updateClearReason,
    handleClearSave,
    type,
    stageText,
    setStageText,
}) {
    const handleRadioChange = (e) => {
        updateClearReason(e.target.value);
    };
    return (
        <div className='orderDetails'>
            <Modal
                isOpen={open ? true : false}
                onRequestClose={close}
                className={'modal-dialog modal-md modal-order-details'}
                overlayClassName={'modal-backdrop '}
                contentLabel='Example Modal'
            >
                <div
                    className='modal-content'
                    aria-modal='true'
                    id='modal-content-block'
                >
                    <div className='modal-header'>
                        <div className='title'>Clear Reason</div>
                        <button className='btn btn-icon' onClick={close}>
                            <i className='fa fa-times'></i>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <div className='form_group'>
                            <input
                                id='stage1'
                                name='stage'
                                type='radio'
                                value='query'
                                onChange={handleRadioChange}
                                checked={clearReason === 'query'}
                            />{' '}
                            <label htmlFor='stage1'>Query</label>
                        </div>
                        {type === 'spa' && (
                            <>
                                <div className='form_group'>
                                    <input
                                        id='stage1'
                                        name='stage'
                                        type='radio'
                                        value='session_completed'
                                        onChange={handleRadioChange}
                                        checked={
                                            clearReason === 'session_completed'
                                        }
                                    />{' '}
                                    <label htmlFor='stage1'>Confirm</label>
                                </div>
                                <div className='form_group'>
                                    <input
                                        id='stage2'
                                        name='stage'
                                        type='radio'
                                        value='rescheduled'
                                        onChange={handleRadioChange}
                                        checked={clearReason === 'rescheduled'}
                                    />{' '}
                                    <label htmlFor='stage2'>
                                        Booking rescheduled
                                    </label>
                                </div>
                            </>
                        )}
                        {type === 'laundry' && (
                            <>
                                <div className='form_group'>
                                    <input
                                        id='stage3'
                                        name='stage'
                                        type='radio'
                                        value='laundry_picked_up'
                                        onChange={handleRadioChange}
                                        checked={
                                            clearReason === 'laundry_picked_up'
                                        }
                                    />{' '}
                                    <label htmlFor='stage3'>
                                        Laundry picked up/Delivered to guest
                                    </label>
                                </div>
                                <div className='form_group'>
                                    <input
                                        id='stage2'
                                        name='stage'
                                        type='radio'
                                        value='pickup_rescheduled'
                                        onChange={handleRadioChange}
                                        checked={
                                            clearReason === 'pickup_rescheduled'
                                        }
                                    />{' '}
                                    <label htmlFor='stage2'>
                                        Pickup rescheduled
                                    </label>
                                </div>
                            </>
                        )}
                        <div className='form_group'>
                            <input
                                id='stage4'
                                name='stage'
                                type='radio'
                                value='cancelled_by_guest'
                                onChange={handleRadioChange}
                                checked={clearReason === 'cancelled_by_guest'}
                            />{' '}
                            <label htmlFor='stage4'>Cancelled by guest</label>
                        </div>
                        <div className='form_group'>
                            <input
                                id='stage5'
                                name='stage'
                                type='radio'
                                value='booked_by_mistake'
                                onChange={handleRadioChange}
                                checked={clearReason === 'booked_by_mistake'}
                            />{' '}
                            <label htmlFor='stage5'>Booked by mistake</label>
                        </div>
                        <div className='form_group'>
                            <input
                                id='stage6'
                                name='stage'
                                type='radio'
                                value='other'
                                onChange={handleRadioChange}
                                checked={clearReason === 'other'}
                            />{' '}
                            <label htmlFor='stage6'>Others</label>
                        </div>
                        {clearReason === 'other' && (
                            <div className='form_group'>
                                {/* <label htmlFor="stage7">Other Reason</label> {" "} */}
                                <input
                                    id='stage7'
                                    name='stage_text'
                                    className='form-control'
                                    placeholder='Enter reason'
                                    type='text'
                                    value={stageText}
                                    onChange={(e) =>
                                        setStageText(e.target.value)
                                    }
                                />
                            </div>
                        )}
                    </div>

                    <div className='modal-footer'>
                        <button className='btn' onClick={close}>
                            Close
                        </button>
                        <button
                            className='btn btn-primary'
                            disabled={!clearReason}
                            onClick={() => handleClearSave(open)}
                        >
                            Save changes
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ClearReasonPopup;

// Query
// Confirm
// Booking rescheduled
// Cancelled by guest
// Booked by mistake
// Others
