import React, { useEffect, useState } from 'react';
import Loading from '../../../Components/common/Loading';
import OrderCard from '../../../Components/common/OrderCard';
import OrderDetailsPopup from '../../../Components/common/OrderDetailsPopup';
import TransitionLoader from '../../../Components/common/TransitionLoader';
import AppConstant from '../../../constant/AppConstant';
import { notification } from 'antd';
import { fetchSpaOrderList } from '../../../store/slices/spa/spaSlice';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import ClearReasonPopup from '../../../Components/common/ClearReasonPopup';

const Accepted = (props) => {
    const [showItemPopup, setShowItemPopup] = useState(false);
    const [detailedViewItem, setDetailedViewItem] = useState({});

    const [isClearPopup, setIsClearPopup] = useState(null);
    const [clearReason, setClearReason] = useState('');
    const [stageText, setStageText] = useState('');

    const [showTransitionLoader, setShowTransitionLoader] = useState(false);
    const { data, loading } = props;

    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title = '', msg = '') => {
        api[type]({
            message: (title = ''),
            description: msg,
        });
    };

    const handleClosePopup = () => {
        setShowItemPopup(false);
        setDetailedViewItem({});
    };
    const handleShowPopup = (item) => {
        setShowItemPopup(true);
        setDetailedViewItem(item);
    };

    const handleClearSave = async (id) => {
        try {
            setShowTransitionLoader(true);
            let response = await axios.put(
                AppConstant.config.appBaseUrl +
                    `/sanitarium/order/dispatch/` +
                    id
            );
            if (response.status === 202) {
                const msg = response.data.message || '';
                moveToHistory(id);
            }
        } catch (error) {
        } finally {
            setTimeout(() => {
                setShowTransitionLoader(false);
            }, 1000);
        }
    };

    const moveToHistory = async (id) => {
        try {
            let payload = {
                stage: clearReason,
                stage_text: stageText,
            };
            let response = await axios.post(
                `/sanitarium/order/clear/${id}`,
                payload
            );
            if (response.status === 202) {
                const msg = response.data.message || '';
                openNotificationWithIcon('success', '', msg);
                setTimeout(() => {
                    dispatch(fetchSpaOrderList());
                }, 1000);
            }
            console.log(response);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsClearPopup(null);
            setClearReason('');
            setStageText('');
        }
    };

    const compChange = async (value, item) => {
        try {
            let response = await axios.put(
                AppConstant.config.appBaseUrl +
                    `/common/order/complementary/${value}/` +
                    item.order_detail.order_id
            );
            if (response.status === 200) {
                let msg =
                    value === 'enable'
                        ? 'Meal marked as complimentary!'
                        : 'Meal unmarked as complimentary!';
                openNotificationWithIcon('success', '', msg);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <section className='order-list-container'>
            {contextHolder}

            {loading ? (
                <Loading />
            ) : data.length === 0 ? (
                <div className='container'>
                    <div className='col-md-12'>
                        <div
                            className=''
                            style={{
                                margin: '25vh auto',
                                textAlign: 'center',
                                fontSize: '30px',
                                color: '#8e8a8a',
                            }}
                        >
                            <h3>No Orders</h3>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='home-screen'>
                    {data.length > 0
                        ? data.map((item, index) => {
                              return (
                                  <OrderCard
                                      ServiceName={'SPA'}
                                      tabType={'NewOrder'}
                                      OrderType={'Service Time'}
                                      key={item.id}
                                      item={item}
                                      viewDetails={() => handleShowPopup(item)}
                                      //   footerBtn={() => handleClearSave(item)}
                                      footerBtn={() =>
                                          setIsClearPopup(
                                              item?.order_detail.order_id
                                          )
                                      }
                                      btnTitle={'Clear'}
                                      timerTime={
                                          item?.order_detail?.accepted_at
                                      }
                                      complimentaryChange={compChange}
                                  />
                              );
                          })
                        : null}
                </div>
            )}
            {showItemPopup && (
                <OrderDetailsPopup
                    open={showItemPopup}
                    close={handleClosePopup}
                    item={detailedViewItem}
                />
            )}
            {isClearPopup && (
                <ClearReasonPopup
                    type={'spa'}
                    open={isClearPopup}
                    clearReason={clearReason}
                    updateClearReason={(val) => setClearReason(val)}
                    close={() => setIsClearPopup(false)}
                    handleClearSave={handleClearSave}
                    setStageText={(value) => setStageText(value)}
                    stageText={stageText}
                />
            )}
            {showTransitionLoader && (
                <TransitionLoader
                    previousStage={'New Order'}
                    newStage={'Accepted'}
                />
            )}
        </section>
    );
};

export default Accepted;
