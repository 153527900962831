import { Alert, Breadcrumb, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { indexOf } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loading from '../../Components/common/Loading';
import NoDataFound from '../../Components/common/NoDataFound';
import OrderDetailsPopup from '../../Components/common/OrderDetailsPopup';
import {
    fetchSpaOrderHistory,
    spaSelector,
} from '../../store/slices/spa/spaSlice';

const { RangePicker } = DatePicker;
const OrderHistory = (props) => {
    const [dateRange, setDateRange] = useState([
        dayjs().format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
    ]);
    const [showItemPopup, setShowItemPopup] = useState(false);
    const [detailedViewItem, setDetailedViewItem] = useState({});
    const { orderhistory, loading } = useSelector(spaSelector);

    const dispatch = useDispatch();
    useEffect(() => {
        const fromDate = dateRange[0];
        const toDate = dateRange[1];
        dispatch(
            fetchSpaOrderHistory('/sanitarium/order/history', fromDate, toDate)
        );
    }, [dateRange]);

    const handleClosePopup = () => {
        setShowItemPopup(false);
        setDetailedViewItem({});
    };
    const handleShowPopup = (item) => {
        setShowItemPopup(true);
        setDetailedViewItem(item);
    };
    const changeDate = (complete, date) => {
        setDateRange(date);
    };

    const ActionHeader = () => {
        return (
            <div className='action-header'>
                <div className='action-left'>
                    <div className='page-title'>SPA</div>
                    <Breadcrumb
                        items={[
                            {
                                title: <a href='/spa'>Spa</a>,
                            },
                            {
                                title: 'Order History',
                            },
                        ]}
                    />
                </div>
                <div className='action-right' style={{ position: 'relative' }}>
                    <RangePicker
                        allowClear={false}
                        size={'middle'}
                        onChange={changeDate}
                        format='YYYY-MM-DD'
                        value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
                    />{' '}
                    <ReactHtmlTableToExcel
                        id='test-table-xls-button'
                        className='download-table-xls-button btn btn-back'
                        table='table-spa'
                        filename='tablexls'
                        sheet='allOrders'
                        buttonText='Download as XLS'
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <ActionHeader />
            <section className='order-history-wrapper'>
                <div className='home-order-screen' style={{ width: '100%' }}>
                    <table
                        id='table-spa'
                        className='table table-striped table-hover table-history'
                    >
                        <thead>
                            <tr>
                                <th className='text-center'>
                                    ROOM/VILLA NUMBER
                                </th>
                                <th className='text-center'>GUEST</th>
                                <th className='text-center'>GUEST NAME</th>
                                <th className='text-center'>ITEMS</th>
                                <th className='text-center'>
                                    ORDER RECEIVED TIME
                                </th>
                                <th className='text-center'>
                                    ORDER ACCEPTED TIME
                                </th>
                                <th className='text-center'>
                                    ORDER CLEARED TIME
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr
                                    style={{
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <td colSpan='9'>
                                        <Loading />
                                    </td>
                                </tr>
                            ) : orderhistory.length === 0 ? (
                                <tr
                                    style={{
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <td colSpan='9'>
                                        <NoDataFound />
                                    </td>
                                </tr>
                            ) : (
                                orderhistory.map((item, index) => {
                                    return (
                                        <tr
                                            key={item.id}
                                            className='cursor-point'
                                            onClick={() =>
                                                handleShowPopup(item)
                                            }
                                        >
                                            <td className='text-center'>
                                                <span className='type-tile'>
                                                    {item?.is_complementary ? (
                                                        <i className='fa fa-gift'></i>
                                                    ) : (
                                                        ''
                                                    )}{' '}
                                                    {item?.primises?.premise_no}
                                                </span>
                                            </td>
                                            <td>{item.no_of_guest}</td>
                                            <td className='text-center'>
                                                {item?.guest?.name}
                                            </td>
                                            <td className='text-center'>
                                                {item.order_spa_items.map(
                                                    (item) => (
                                                        <div key={item.id}>
                                                            {item?.item?.name}
                                                        </div>
                                                    )
                                                )}
                                            </td>

                                            <td className='text-center'>
                                                {new Date(
                                                    item.order_detail.created_at
                                                ).toDateString()}{' '}
                                                {new Date(
                                                    item.order_detail.created_at
                                                ).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                            </td>
                                            <td className='text-center'>
                                                {new Date(
                                                    item.order_detail.accepted_at
                                                ).toDateString()}{' '}
                                                {new Date(
                                                    item.order_detail.accepted_at
                                                ).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                            </td>
                                            <td className='text-center'>
                                                {new Date(
                                                    item.order_detail.cleared_at
                                                ).toDateString()}{' '}
                                                {new Date(
                                                    item.order_detail.cleared_at
                                                ).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </table>
                </div>
            </section>

            {showItemPopup && (
                <OrderDetailsPopup
                    open={showItemPopup}
                    close={handleClosePopup}
                    item={detailedViewItem}
                />
            )}
        </>
    );
};

export default OrderHistory;
