import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { commonSelector } from "../../../store/slices/common/commonSlice";
import Select from 'react-dropdown-select';
import { DeleteOutlined } from '@ant-design/icons';

const AddEditTranslations = ({
  translations,
  rows,
  setRows,
  showDescriptionField,
  tagsExists
}) => {
  const { hotelDetails } = useSelector(commonSelector);
  const hotelLanguages = hotelDetails.hotel?.languages || [];

  const [languageList, setLanguageList] = useState(hotelLanguages);

  useEffect(() => {
    updateLanguages();
  }, [rows]);

  useEffect(() => {
    setRows(translations);
  }, [translations]);

  const updateLanguages = () => {
    if (rows) {
      const list = rows.map((item) => +item.hotel_language_id);
      const newList = hotelLanguages.filter(
        (item) => !list.includes(item.id)
      );
      setLanguageList(newList);
    }
  };

  const handleChange = (e, idx) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    let newObj = {
      ...updatedRows[idx],
      [name]: value,
    };
    if (value && name === 'hotel_language_id') {
      let data = languageList.find((d) => d.id == value);
      newObj['hotel_language_id'] = data.id;
      newObj['language_code'] = data.lang_code;
      newObj['language_name'] = data.lang_name;
    }
    updatedRows[idx] = newObj;
    setRows(updatedRows);
  };

  const handleTagChange = (value, idx) => {
    const updatedRows = [...rows];
    // Store tags as a JSON array string
    updatedRows[idx].tags = JSON.stringify(value.map(tag => tag.value));
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    if (languageList.length === 0) {
      alert('No available languages to add.');
      return;
    }

    const newItem = {
      hotel_language_id: '',
      name: '',
      description: '',
      disclaimer: '',
      language_name: '',
      language_code: '',
      tags:'[]'
    };

    if (languageList.length === 1) {
      newItem['language_code'] = languageList[0].lang_code;
      newItem['language_name'] = languageList[0].lang_name;
      newItem['hotel_language_id'] = languageList[0].id;
    }

    setRows([...rows, newItem]);
    updateLanguages();
  };

  const handleRemoveRow = (idx) => {
    if (!window.confirm('Are you sure you want to remove this translation?'))
      return;
    const updatedRows = rows.filter((item, index) => index !== idx);
    setRows(updatedRows);
  };

  

  return (
    <React.Fragment>
      <table className="table table-bordered table-hover" id="tab_logic">
        <thead>
          <tr>
            <th className="text-center">Language </th>
            <th className="text-center">Name </th>
            {showDescriptionField && (
              <th className="text-center">Description </th>
            )}
            {tagsExists && (
            <th className="text-center">Tags 
            <small style={{ fontSize: '10px' }}>
             {' '} (Add tag and press Enter)
            </small>
            </th>
            
            )}
            {/* <th className='text-center'>Disclaimer </th> */}
          </tr>
        </thead>
        <tbody>
          {rows &&
            rows.map((item, idx) => (
              <tr id={`addr${idx}`} key={idx}>
                <td style={{width:'5rem'}}>
                  {item?.hotel_language_id ? (
                    item.language_name
                  ) : (
                    <select
                        className="form-control"
                        onChange={(e) => handleChange(e, idx)}
                        disabled={item?.language_code}
                        name='hotel_language_id'
                        value={item.hotel_language_id ? item.hotel_language_id.toString() : ''}
                    >
                      <option value="">
                        Select Language
                      </option>
                      {languageList.map((language) => (
                        <option
                          key={language.id}
                          value={language.id.toString()}
                          disabled={item?.id}
                        >
                          {language.lang_name || ""}
                        </option>
                      ))}
                    </select>

                  )}
                </td>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={item.name || ""}
                    onChange={(e) => handleChange(e, idx)}
                    className="form-control"
                  />
                </td>
                {showDescriptionField && (
                  <td style={{width :'24rem'}}>
                    {/* <input
                    type='text'
                    name='description'
                    value={item.description || ''}
                    onChange={(e) => handleChange(e, idx)}
                    className='form-control'
                  /> */}
                      <textarea
                        type='text'
                        className='form-control'
                        rows='5'
                        cols='10'
                        placeholder='Description'
                        name='description'
                        value={item.description}
                        onChange={(e)=>handleChange(e,idx)}
                      >
                      </textarea>
                  </td>
                )}
                {tagsExists && (
                <td style={{width:'5rem'}}>
                <Select
                    multi
                    create
                    values={JSON.parse(item.tags || '[]').map(tag => ({ label: tag, value: tag }))}
                    placeholder='Type and press Enter...'
                    addPlaceholder='Type and press Enter...'
                    dropdownHandle={false}
                    onChange={(value) => handleTagChange(value, idx)}
                  />
                </td> )}
                {/* <td>
                <input
                  type='text'
                  name='disclaimer'
                  value={item.disclaimer || ''}
                  onChange={(e) => handleChange(e, idx)}
                  className='form-control'
                />
              </td> */}
                <td>
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => handleRemoveRow(idx)}
                  >
                    <DeleteOutlined />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <button
        onClick={handleAddRow}
        className="btn btn-primary"
        disabled={languageList.length <= 0}
      >
        Add Row
      </button>
    </React.Fragment>
  );
};

export default AddEditTranslations;
