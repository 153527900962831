import { createSlice } from '@reduxjs/toolkit';
import AppConstant from '../../../constant/AppConstant';
import { getOrderSource } from '../../../services/apiService';
import initialState from './initialState';
import axios from 'axios';
const irdSlice = createSlice({
    name: 'ird',
    initialState,
    reducers: {
        updateDeviceToken(state, { payload }) {
            state.deviceToken = payload;
        },
        updateLoading(state, { payload }) {
            state.loading = payload;
        },
        updateOrderList(state, { payload }) {
            state.orderList = payload;
        },
        updatePagination(state, { payload }) {
            const newState = {
                ...state.pagination,
                ...payload,
            };
            state.pagination = newState;
        },
        updateOrderHistory(state, { payload }) {
            state.orderhistory = payload;
        },
        updateItemlist(state, { payload }) {
            state.itemList = payload;
        },
        updateMaxPageLimit(state, { payload }) {
            state.maxPageLimit = payload;
        },
        updateTotalOrderCount(state, { payload }) {
            state.totalOrderCount = payload;
        },
        updateRevenueData(state, { payload }) {
            state.revenueData = payload;
            state.revenueOrderList = payload.orders;
        },
        updateAnalyticsData(state, { payload }) {
            state.analyticsData = payload;
        },
        updateMenuList(state, { payload }) {
            state.menuList = payload;
        },
        updateMessage(state, { payload }) {
            state.message = payload;
        },
        updateActionTakenOnOrderID(state, { payload }) {
            state.actionTakenOnOrderID = payload;
        },
    },
});

export const irdSelector = (state) => state.irdSlice;
export const {
    updateLoading,
    updateDeviceToken,
    updateOrderList,
    updateOrderHistory,
    updateItemlist,
    updateMaxPageLimit,
    updateTotalOrderCount,
    updateRevenueData,
    updateAnalyticsData,
    updateMenuList,
    updateMessage,
    updateActionTakenOnOrderID,
    updatePagination,
} = irdSlice.actions;

export default irdSlice.reducer;

const getHeaders = () => {
    let option = {
        method: 'GET',
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('user'),
        },
    };
    return option;
};

export function fetchIRDOrderList(search) {
    return async (dispatch, getState) => {
        try {
            const { pagination, orderList } = getState().irdSlice;
            dispatch(updateLoading(true));
            let orderSource = getOrderSource();
            //  axio header setup
            let baseURL =
                process.env.REACT_APP_NODE_ENV === 'production'
                    ? `https://vserve-api.valet2you.in/api/v5.0.2/`
                    : `https://test-vserve-api.valet2you.in/api/v5.0.2/`;
            baseURL += `${orderSource}/order?sort_order=asc&page=1&per_page=100`;
            if (search) {
                baseURL += `&search=${search}`;
            }
            // if (search) {
            //     baseURL += `search=${search}`;
            // } else if (pagination.next_page_url) {
            //     baseURL = `${pagination.next_page_url}&per_page=${pagination.per_page}`;
            // } else {
            //     baseURL += `page=1&per_page=${pagination.per_page}`;
            // }
            const response = await axios.get(`${baseURL}`);
            if (response.status === 200) {
                const newList = response.data.data || [];
                console.log(newList);
                // if (search) {
                //     dispatch(updateOrderList([...newList]));
                // } else {
                //     dispatch(updateOrderList([...orderList, ...newList]));
                // }
                dispatch(updateOrderList(newList));
                // const pagination = {
                //     current_page: result.current_page,
                //     per_page: result.per_page,
                //     total: result.total,
                //     last_page: result.last_page,
                //     next_page_url: result.next_page_url,
                // };
                // dispatch(updatePagination(pagination));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
export function fetchOrderHistory(
    roomNo,
    name,
    fromDate,
    toDate,
    pageNumber,
    per_page
) {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            console.log(roomNo, name, fromDate, toDate);
            let sQuery = '';
            if (roomNo) {
                sQuery += `room_no=${roomNo}&`;
            }
            // if (itemid) {
            //   sQuery += `item_id=${itemid}&`;
            //

            if (name) {
                sQuery += `guest_name=${name}&`;
            }
            if (fromDate) {
                sQuery += `date_start=${fromDate}&`;
            }
            if (toDate) {
                sQuery += `date_end=${toDate}&`;
            }
            let eventData = await fetch(
                `${
                    AppConstant.config.appBaseUrl
                }/${getOrderSource()}/order/history?${sQuery}page=${pageNumber}&per_page=${per_page}`,
                getHeaders()
            );
            const { data } = await eventData.json();
            dispatch(updateMaxPageLimit(data.last_page));
            dispatch(updateTotalOrderCount(data.total));
            if (data?.data?.length) {
                const sortedData = data?.data?.sort(
                    (a, b) =>
                        +new Date(b.order_detail.created_at) -
                        +new Date(a.order_detail.created_at)
                );
                dispatch(updateOrderHistory(sortedData));
            } else {
                dispatch(updateOrderHistory(data?.data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

// only for a hotel to applu filter based on the item
export function fethItemsList() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            let eventData = await fetch(
                `${AppConstant.config.appBaseUrl}/${getOrderSource()}/menu`,
                getHeaders()
            );
            const { data } = await eventData.json();
            let itemlist = [];

            const updateItem = (item) => {
                const objItem = {
                    itemId: item.id,
                    itemName: item.name,
                };
                itemlist.push(objItem);
            };
            if (data.length) {
                data.forEach(({ categories }) => {
                    categories.length &&
                        categories.forEach((catItem) => {
                            catItem.sub_categories.length &&
                                catItem.sub_categories.forEach((itemslist) => {
                                    itemslist.items.length &&
                                        itemslist.items.forEach((mainItem) =>
                                            updateItem(mainItem)
                                        );
                                });
                            catItem.without_sub_category_items.length &&
                                catItem.without_sub_category_items.forEach(
                                    (withOutCatItem) => {
                                        updateItem(withOutCatItem);
                                    }
                                );
                        });
                });
            }
            dispatch(updateItemlist(itemlist));
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

export function fetchRevenueData(fromDate, toDate, pageNumber, per_page) {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            let sQuery = '';
            if (fromDate) {
                sQuery += `from=${fromDate}&`;
            }
            if (toDate) {
                sQuery += `to=${toDate}&`;
            }
            let eventData = await fetch(
                `${
                    AppConstant.config.appBaseUrl
                }/${getOrderSource()}/orders?${sQuery}page=${pageNumber}&per_page=${per_page}`,
                getHeaders()
            );
            const { data } = await eventData.json();
            dispatch(updateRevenueData(data));
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

export function fetchAnalyticsData(fromDate, toDate, meal_type, itemCount) {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            let sQuery = '';
            if (fromDate) {
                sQuery += `start_date=${fromDate}`;
            }
            if (toDate) {
                sQuery += `&end_date=${toDate}`;
            }
            if (meal_type) {
                sQuery += `&meal_type=${meal_type}`;
            }
            if (itemCount) {
                sQuery += `&limit=${itemCount}`;
            }
            let response = await fetch(
                `${
                    AppConstant.config.appBaseUrlV5_0_3
                }/${getOrderSource()}/analytics/top-sell?${sQuery}`,
                getHeaders()
            );

            if (response.status === 200) {
                const data = await response.json();
                const nodeList = data.data || [];
                dispatch(updateAnalyticsData(nodeList));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
export function fetchMenuList() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.get(`/${getOrderSource()}/menu`);
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateMenuList(data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
export function enableDisableMenuList(url, id) {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.put(
                `/${getOrderSource()}/${url}/${id}`
            );
            if (response.status === 202) {
                const data = response.data.data || [];
                dispatch(fetchMenuList());
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

// export function  addRemoveComplimentary(){
//     return async (dispatch) => {
//         try {
//             dispatch(updateLoading(true));
//             const response = await axios.put(`/${getOrderSource()}/${url}/${id}`);
//             if (response.status === 202) {
//                 const data = response.data.data || [];
//                 dispatch(fetchMenuList());
//             }
//         } catch (error) {
//             console.log(error);
//         } finally {
//             dispatch(updateLoading(false));
//         }
//     };

// }
