import React, { useEffect, useState } from 'react';
import { deleteFeedContent, getFeedInterest } from '../../services/apiService';
import CreateFeed from './CreateFeed';
import CreateFeedContent from './CreateFeedContent';

const FeedCard = (props) => {
    const {
        description,
        views_count,
        id,
        addFeedClick,
        feedId,
        feed_contents,
        onFeedContentSuccess,
        deletFeedHandler,
        is_pinned,
        fetchAllFeeds,
        unreadFeedInterestCount,
        setOpenPopup,
        interest_action_btn_enabled,
        interest_action_btn_text,
        interest_action_response,
        translations,
        ...feed
    } = props;
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(null);
    const [isAddContent, setIsAddContent] = useState(false);

    useEffect(() => {
        if (feedId && feedId === id) {
            setIsAddContent(false)
        }
    }, [feedId]);

    const deletFeedContentHandler = async (id) => {
        if (window.confirm('Are you sure you want to delete')) {
            try {
                setLoading(true);
                const response = await deleteFeedContent(id);
                if (response.status === 200) {
                    onFeedContentSuccess();
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
    };
    const handleFeedEdit = (id) => {
        if (id === isEdit) setIsEdit(null);
        else setIsEdit(id);
    };
    const feedUpdateSucess = () => {
        setIsEdit(null);
        fetchAllFeeds();
    };
    return (
        <div className={'feedcard-container ' + (unreadFeedInterestCount > 0 ? "unread ":"") + (feedId ===id? "active":"")}>
            <div className='feed-name-wrapper' onClick={() => addFeedClick(id)}>
                <div className='feed-actions-name-wrapper'>
                    <div className='feed-name'>
                        <strong>Description:</strong>
                        {isEdit ? (
                            <></>
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            ></span>
                        )}
                    </div>
                    <div className='toggle-icon'>
                        {feedId === id ? (
                            <i className='fa fa-angle-down'></i>
                        ) : (
                            <i className='fa fa-angle-right'></i>
                        )}
                    </div>
                </div>
            </div>
            {feedId === id && (
                <div className='feed-details-body'>
                    <div className='feed-action-container'>
                        {Boolean(is_pinned) && (
                            <div className='pin-icon'>
                                <i className='fa fa-thumb-tack'></i>
                            </div>
                        )}
                        
                        <button
                            className='btn'
                            onClick={() => setIsAddContent(!isAddContent)}
                        >
                            Add Image / Video
                        </button>
                        <button
                            className='btn btn-icon'
                            onClick={() => handleFeedEdit(id)}
                        >
                            <i className='fa fa-pencil'></i>
                        </button>
                        <button
                            className='btn btn-icon btn-danger'
                            onClick={() => deletFeedHandler(id)}
                        >
                            <i className='fa fa-trash-o' aria-hidden='true'></i>
                        </button>
                        <button
                            className='btn'
                            onClick={() => setOpenPopup(true)}
                        >
                            Interested Guest Data
                        </button>
                    </div>
                    {isEdit && (
                        <CreateFeed
                            description={description}
                            is_pinned={Boolean(is_pinned)}
                            isEdit={isEdit}
                            isEditMode={true}
                            closeFeed={() => setIsEdit(null)}
                            feedUpdateSucess={feedUpdateSucess}
                            interest_action_btn_enabled={interest_action_btn_enabled}
                            interest_action_btn_text={interest_action_btn_text}
                            interest_action_response={interest_action_response}
                            translations={translations}
                        />
                    )}

                    {views_count > 0 && (
                        <div className='view-count'>
                            <strong>Total views:</strong> {views_count} views
                        </div>
                    )}
                    <div className='feed-list-interest-wrapper'>
                        <div className='feedcontent-list'>
                            {feed_contents?.length > 0 &&
                                feed_contents?.map((item, index) => {
                                    return (
                                        <div
                                            className='feedcontent-item'
                                            key={index}
                                        >
                                            <div className='feedcontent-assets'>
                                                {item.content_type ===
                                                'video' ? (
                                                    <video
                                                        width='65'
                                                        height='65'
                                                        src={`https://vserve-feeds.s3.ap-south-1.amazonaws.com/${item.content_src}`}
                                                    />
                                                ) : (
                                                    <img
                                                        src={`https://vserve-feeds.s3.ap-south-1.amazonaws.com/${item.content_src}`}
                                                        alt='feed-title'
                                                    />
                                                )}
                                            </div>

                                            <div className='content-actions'>
                                                <div className='content-type'>
                                                    {item.content_type}
                                                </div>
                                                <button
                                                    className='btn btn-icon btn-danger'
                                                    onClick={() =>
                                                        deletFeedContentHandler(
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    {/* {loading ? (
                                                <i className='fa fa-spinner fa-spin'></i>
                                            ) : (
                                                ''
                                            )} */}
                                                    <i
                                                        className='fa fa-trash-o'
                                                        aria-hidden='true'
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                    </div>
                    {isAddContent && feedId === id && (
                        <CreateFeedContent
                            feedId={feedId}
                            onFeedContentSuccess={onFeedContentSuccess}
                            addFeedClick={addFeedClick}
                            closeClickHandler={() => setIsAddContent(false)}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default FeedCard;
