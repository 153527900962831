import axios from 'axios';
import { removeUserDataFromBrowserStorage } from '../helper/_commonHelper';

export const getOrderSource = () => {
    const userType = localStorage.getItem('user_type');
    if (userType === 'hotel_admin') {
        return 'ird';
    } else if (userType === 'restaurant_manager') {
        return 'restaurant';
    } else {
        return 'ird';
    }
};
export const getToken = () => {
    const token = localStorage.getItem('user');
    return token;
};
export const  BASE_URL = process.env.REACT_APP_NODE_ENV === 'production'
? `https://vserve-api.valet2you.in/`
: `https://test-vserve-api.valet2you.in/`;
//  axio header setup
axios.defaults.baseURL =`${BASE_URL}api/v5.0.1/`
   

axios.defaults.headers.common['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers.post['Content-Type'] = 'application/json';

// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        const token = getToken();
        // Do something before request is sent
        if (token) {
            config.headers.common['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const {response, ...errorObj} = error;
        if(response.status === 401){
            removeUserDataFromBrowserStorage();
            window.location.replace("/login")
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export const loginUser = (data) => {
    return axios.post(`login`, data);
};

export const getIRDOrders = () => {
    let orderSource = getOrderSource();
    return axios.get(`${orderSource}/order`);
};

export const getSPAOrders = () => {
    return axios.get(`sanitarium/order`);
};

export const getLaundryOrders = () => {
    return axios.get(`wash/order`);
};
export const getConnectOrders = () => {
    return axios.get(`connect/order`);
};

export const getHeaderServices = () => {
    return axios.get(`hotel/available-services`);
};

export const getLastOrderIds = () => {
    return axios.get(`hotel/last-orders`);
};

export const getAllFeeds = (hotel_id, page = 1, count = 500) => {
    return axios({
        method: 'GET',
        url: `feeds?hotel_id=${hotel_id}&page=${page}&per_page=${count}`,
    });
};
export const createNewFeed = (payload) => {
    return axios({
        method: 'POST',
        url: 'feeds',
        data: payload,
    });
};

export const updateFeed = (id, payload) => {
    return axios({
        method: 'POST',
        url: `feeds/${id}`,
        data: payload,
    });
};

export const deleteFeed = (feed_id) => {
    return axios({
        method: 'DELETE',
        url: `feeds/${feed_id}`,
    });
};
export const createNewFeedContent = (payload) => {
    return axios({
        method: 'POST',
        url: 'feeds/contents',
        data: payload,
    });
};

export const deleteFeedContent = (feed_content_id) => {
    return axios({
        method: 'DELETE',
        url: `feeds/contents/${feed_content_id}`,
    });
};

export const getFeedInterest = (feed_id, page = 1, per_page = 4) => {
    return axios({
        method: 'GET',
        url: `feeds/interests/${feed_id}?page=${page}&per_page=${per_page}`,
    });
};


//start menu managment
export const getMenuTaxes = (itemId)=>{
    let orderSource = getOrderSource();

    return axios.get(`${orderSource}/menu/item/taxes/${itemId}`);

}

export const getAddonTaxes=(addOnId)=>{
    let orderSource = getOrderSource();

    return axios.get(`${orderSource}/menu/addon/taxes/${addOnId}`)
}

export const addEntitySchedule = (formData) => {
  return axios.post('entity-schedulers', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  });
};

export const updateEntitySchedule= (formData, service, entity_id)=>{
  return axios.post(`entity-schedulers/update/${service}/${entity_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  })
}
  
export const getIRDMenus = (type ='') => {
    return axios.get(`entity-schedulers?service=${type}`);
};
//end menu managment