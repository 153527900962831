import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import axios from 'axios';
const spaSlice = createSlice({
    name: 'spa',
    initialState,
    reducers: {
        updateLoading(state, { payload }) {
            state.loading = payload;
        },
        updateOrderList(state, { payload }) {
            state.orderList = payload;
        },
        updateMenuList(state, { payload }) {
            state.menuList = payload;
        },
        updateMessage(state, { payload }) {
            state.message = payload;
        },
        updateOrderHistory(state, { payload }) {
            state.orderhistory = payload;
        },
    },
});

export const spaSelector = (state) => state.spaSlice;
export const {
    updateLoading,
    updateOrderList,
    updateMenuList,
    updateMessage,
    updateOrderHistory,
} = spaSlice.actions;

export default spaSlice.reducer;

export function fetchSpaOrderList() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.get('sanitarium/order');
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateOrderList(data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

export function fetchSpaMenuList() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.get('sanitarium/spa');
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateMenuList(data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
export function enableDisableSpaMenuList(url, id) {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.put(`${url}/${id}`);
            if (response.status === 202) {
                const data = response.data.data || [];
                dispatch(fetchSpaMenuList());
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

export function fetchSpaOrderHistory(url, fromDate,toDate) {
    return async (dispatch) => {
        try {
            let sQuery = '';
            if (fromDate) {
                sQuery += `date_start=${fromDate}&`;
            }
            if (toDate) {
                sQuery += `date_end=${toDate}`;
            }
            dispatch(updateLoading(true));
            const response = await axios.get(`${url}?${sQuery}`);
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateOrderHistory(data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
