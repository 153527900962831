import React from "react";
import { useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CreateDescription=(props)=>{

    const { description, onChange } = props;

    const [editorData, setEditorData] = useState({
        genericDescription: description || ''
    });

    return(
        <div className="createfeed-container">
        <div className='form-group'>
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: {
                            items: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                '|',
                                'link',
                                '|',
                                'undo',
                                'redo',
                            ],
                        },
                    }}
                    data={editorData.genericDescription}
                    onChange={(event,editor)=>{
                        const data=editor.getData();
                        setEditorData({ genericDescription: data });
                        onChange({ target: { name: 'description', value: data } });
                    }}
                />
            </div>
            </div>
    )
}

export default CreateDescription;