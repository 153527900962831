import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setCookie } from '../helper/_cookieHelper';
import { useDispatch } from 'react-redux';
import {
    commonSelector,
    fetchUserProfile,
} from '../store/slices/common/commonSlice';
import { useSelector } from 'react-redux';

const AuthCallback = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, hotelDetails } = useSelector(commonSelector);
    useEffect(() => {
        checkStatus();
    }, []);

    const checkStatus = () => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const status = queryParams.get('status');
        if (status === 'success') {
            setCookie('token', `${token}`, 1);
            localStorage.setItem('user', token);
            dispatch(fetchUserProfile());
            //   navigate("/", { replace: true });
        } else {
            navigate(`/login`, { replace: true, msg: 'failure' });
        }
    };

    if (loading) return <div>loading...</div>;
    if (hotelDetails && hotelDetails.type) {
        switch (hotelDetails.type) {
            case 'hotel_admin':
            case 'ird_manager':
            case 'restaurant_manager':
                navigate('/ird', { replace: true });
                break;
            case 'spa_manager':
                navigate('/spa', { replace: true });
                break;
            case 'connect_manager':
                navigate('/connect', { replace: true });
                break;
            case 'laundry_manager':
                navigate('/laundry', { replace: true });
                break;
            case 'feed_manager':
                navigate('/feed', { replace: true });
                break;
            case 'booking_manager':
                navigate('/booking', { replace: true });
                break;
            default:
                navigate('/', { replace: true });
                break;
        }
    }
    return <div>loading...</div>;
};

export default AuthCallback;
