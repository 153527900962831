import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Input } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import ReactPaginate from 'react-paginate';
import Loading from '../../Components/common/Loading';
import NoDataFound from '../../Components/common/NoDataFound';
import { printCurrencySymbol } from '../../helper/_commonHelper';

function getNewDate() {
    let tDate = new Date().toLocaleDateString().split('/');
    let newDateToday = `${tDate[2]}-${tDate[1]}-${tDate[0]}`;
    return newDateToday;
}
const BookingOrderHistory = () => {
    const [loading, setLoading] = useState(false);
    const [orderHistoryList, setOrderHistoryList] = useState([]);
    const [showItemPopup, setShowItemPopup] = useState(false);
    const [detailedViewItem, setDetailedViewItem] = useState({});
    const [pageNumber, setpageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(10);
    const [maxPageLimit, setMaxPageLimit] = useState(0);
    const [totalOrderCount, setTotalOrderCount] = useState(0);

    const [dataSelection, setDataSelection] = useState({
        from_date: getNewDate(),
        to_date: getNewDate(),
    });
    const currencySymbol = printCurrencySymbol();
    useEffect(() => {
        fethOrders();
    }, [pageNumber, pageCount]);

    const fethOrders = async (startDate, endDate) => {
        setLoading(true);
        try {
            let sDate = startDate || dataSelection.from_date;
            let eDate = endDate || dataSelection.to_date;
            let response = await axios.get(
                `/booking-service/history-order?page=${pageNumber}&per_page=${pageCount}&from_date=${sDate}&to_date=${eDate}`
            );
            if (response.status === 200) {
                const data = response.data.data || {};
                setTotalOrderCount(data?.total);
                setMaxPageLimit(data?.last_page);
                setOrderHistoryList(data.data || []);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDatecChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSelection({ ...dataSelection, [name]: value });
    };

    const handleClosePopup = () => {
        setShowItemPopup(false);
        setDetailedViewItem({});
    };
    const handleShowPopup = (item) => {
        setShowItemPopup(true);
        setDetailedViewItem(item);
    };

    const converTime = (time) => {
        if (time) {
            let covertedTime = time;
            let H = +covertedTime.substr(0, 2);
            let h = H % 12 || 12;
            h = h < 10 ? '0' + h : h;
            let ampm = H < 12 ? ' AM' : ' PM';
            covertedTime = h + covertedTime.substr(2, 3) + ampm;
            return covertedTime;
        } else {
            return time;
        }
    };
    const handlePageChange = (page) => {
        setpageNumber(page);
    };
    const applyFilters = () => {
        fethOrders();
    };
    const clearFilters = () => {
        let d = getNewDate();
        setDataSelection({ from_date: d, to_date: d });
        fethOrders(d, d);
    };
    const ActionHeader = () => {
        return (
            <div className='action-header'>
                <div className='action-left'>
                    <div className='page-title'>BOOKING</div>
                    <Breadcrumb
                        items={[
                            {
                                title: <a href='/booking'>Booking</a>,
                            },
                            {
                                title: 'Order History',
                            },
                        ]}
                    />
                </div>
                <div className='action-right'>
                    {dataSelection.from_date && dataSelection.to_date ? (
                        <span className='filter-text'>
                            Showing result <b>"{dataSelection.from_date}"</b> to{' '}
                            <b>"{dataSelection.to_date}"</b>
                        </span>
                    ) : null} {" "}
                    <ReactHtmlTableToExcel
                        id='test-table-xls-button'
                        className='download-table-xls-button btn btn-back'
                        table='table-booking'
                        filename='tablexls'
                        sheet='allOrders'
                        buttonText='Download as XLS'
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <ActionHeader />
            <section className='order-history-wrapper'>
                <div className='aside-right'>
                    {loading ? (
                        <div
                            className='home-order-screen'
                            style={{ position: 'relative' }}
                        >
                            <Loading />
                        </div>
                    ) : (
                        <div className='home-order-screen'>
                            <table
                                id='table-booking'
                                className='table table-striped table-hover table-history'
                            >
                                <thead>
                                    <tr>
                                        <th className='text-center'>
                                            ORDER ID
                                        </th>
                                        <th className='text-center'>
                                            ROOM/VILLA NUMBER
                                        </th>
                                        <th className='text-center'>GUEST</th>
                                        <th className='text-center'>
                                            GUEST NAME
                                        </th>
                                        <th className='text-center'>SERVICE</th>
                                        <th className='text-center'>
                                            BOOKED SLOT
                                        </th>
                                        <th className='text-center'>
                                            BOOKED AT
                                        </th>
                                        {/* <th className='text-center'>ORDER CLEARED TIME</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderHistoryList.length === 0 ? (
                                        <tr
                                            style={{
                                                backgroundColor: 'transparent',
                                            }}
                                        >
                                            <td colspan='7'>
                                                <NoDataFound />
                                            </td>
                                        </tr>
                                    ) : (
                                        orderHistoryList?.map((item, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className='cursor-point'
                                                    onClick={() =>
                                                        handleShowPopup(item)
                                                    }
                                                >
                                                    <td className='text-center '>
                                                        {item.id}
                                                    </td>
                                                    <td className='text-center '>
                                                        <span className='type-tile'>
                                                            {
                                                                item?.primises
                                                                    ?.premise_no
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className='text-center '>
                                                        {item.no_of_guest}
                                                    </td>
                                                    <td className='text-center '>
                                                        {item?.guest?.name}
                                                    </td>
                                                    <td className='text-center '>
                                                        {item.order_booking_services.map(
                                                            (item) => (
                                                                <div
                                                                    key={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {
                                                                        item.booking_service_name
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className='text-center'>
                                                        {converTime(
                                                            item?.order_detail
                                                                ?.booking_service_timing
                                                                ?.opening_time
                                                        )}{' '}
                                                        -{' '}
                                                        {converTime(
                                                            item?.order_detail
                                                                ?.booking_service_timing
                                                                ?.closing_time
                                                        )}
                                                        <p>
                                                            {new Date(
                                                                item?.order_detail?.pickup_slot
                                                            ).toDateString()}{' '}
                                                        </p>
                                                    </td>
                                                    <td className='text-center'>
                                                        {new Date(
                                                            item?.order_detail?.created_at
                                                        ).toDateString()}{' '}
                                                        {new Date(
                                                            item?.order_detail?.created_at
                                                        ).toLocaleTimeString(
                                                            [],
                                                            {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                            }
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <div className='pagination-container'>
                        <div className='form-group'>
                            <label>Total Orders: </label>{' '}
                            <label>
                                <b>{totalOrderCount}</b>
                            </label>
                        </div>
                        <div className='form-group'>
                            <label>Row per Page: </label>
                            <select
                                name='count'
                                id='count'
                                value={pageCount}
                                onChange={(e) => setPageCount(e.target.value)}
                            >
                                <option defaultValue='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='75'>75</option>
                                <option value='100'>100</option>
                            </select>
                        </div>
                        <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={maxPageLimit}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={(e) => {
                                handlePageChange(e.selected + 1);
                            }}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>

                <div className='aside-left'>
                    <div className='aside_title'>Data Filters</div>
                    <div className='aside_main_wrapper'>
                        <div className='filter-type-option'>
                            <label htmlFor='from_date'>Start Date:</label>
                            <input
                                type='date'
                                id='from_date'
                                name='from_date'
                                placeholder='Enter start Date'
                                onChange={(e) => handleDatecChange(e)}
                                value={dataSelection.from_date}
                            />
                        </div>
                        <div className='filter-type-option'>
                            <label htmlFor='to_date'>End Date:</label>
                            <input
                                type='date'
                                id='to_date'
                                name='to_date'
                                placeholder='Enter end Date'
                                onChange={(e) => handleDatecChange(e)}
                                value={dataSelection.to_date}
                            />
                        </div>

                        <div className='btns-wrapper'>
                            <button
                                className={'btn btn-primary'}
                                onClick={() => applyFilters()}
                            >
                                Apply Filters
                            </button>
                            <button
                                className='btn '
                                onClick={() => clearFilters()}
                            >
                                Clear Filters
                            </button>
                        </div>
                    </div>
                </div>

                {showItemPopup && (
                    <div className='popup-wrap-container'>
                        <div
                            className='background-overlay'
                            onClick={handleClosePopup}
                        ></div>
                        <div className='popup-container'>
                            <div className='popup-content-header position-relative'>
                                <h3 className='text-center'>ORDER SUMMARY</h3>
                                <i
                                    className='fa fa-times close-popup-icon'
                                    onClick={handleClosePopup}
                                ></i>
                            </div>
                            <div
                                className='popup-content-body'
                                style={{ maxHeight: '400px', overflow: 'auto' }}
                            >
                                <div className='popup-content-nav'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='text-center'>
                                                <p>Order ID</p>
                                                <h4>{detailedViewItem.id}</h4>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <h3 className='text-center'>
                                                <div className='lastname_id_container'>
                                                    <div>
                                                        {
                                                            detailedViewItem
                                                                .guest.name
                                                        }
                                                    </div>{' '}
                                                    <div>
                                                        {
                                                            detailedViewItem
                                                                ?.primises
                                                                ?.premise_no
                                                        }
                                                    </div>
                                                </div>
                                            </h3>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='text-center'>
                                                <p>
                                                    {new Date(
                                                        detailedViewItem.order_detail.created_at
                                                    ).toDateString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    })}{' '}
                                                    {new Date(
                                                        detailedViewItem.order_detail.created_at
                                                    ).toLocaleTimeString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    })}
                                                </p>
                                                <h4>
                                                    {
                                                        detailedViewItem.no_of_guest
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='popup-content-card'>
                                    <h3 className='heading'>Service</h3>
                                    <div className='row'>
                                        {detailedViewItem.order_booking_services
                                            .length > 0 &&
                                            detailedViewItem.order_booking_services.map(
                                                (itemEl) => {
                                                    return (
                                                        <div
                                                            className='col-md-4'
                                                            style={{
                                                                marginBottom:
                                                                    '15px',
                                                            }}
                                                        >
                                                            <p>
                                                                {
                                                                    itemEl.booking_service_name
                                                                }{' '}
                                                                :{' '}
                                                                {
                                                                    itemEl.quantity
                                                                }
                                                                <br />
                                                                {/* <span>
                              {currencySymbol} {itemEl.price} * {itemEl.quantity}
                            </span> */}
                                                            </p>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>
                                </div>
                                {/*<div className='popup-content-card'>
                <h3 className='heading'>ORDER INSTRUCTIONS</h3>
                <p>
                  <span>{detailedViewItem.description}</span>
                </p>
                  </div>*/}
                                <div className='popup-content-card'>
                                    <h3 className='heading'>BOOKED SLOT</h3>
                                    <p>
                                        <span>
                                            {converTime(
                                                detailedViewItem?.order_detail
                                                    ?.booking_service_timing
                                                    ?.opening_time
                                            )}{' '}
                                            -{' '}
                                            {converTime(
                                                detailedViewItem?.order_detail
                                                    ?.booking_service_timing
                                                    ?.closing_time
                                            )}
                                            <p>
                                                {new Date(
                                                    detailedViewItem?.order_detail?.pickup_slot
                                                ).toDateString()}{' '}
                                            </p>
                                        </span>
                                    </p>
                                </div>
                                <div className='popup-content-card'>
                                    <h3 className='heading'>BOOKED AT</h3>
                                    <p>
                                        <span>
                                            {new Date(
                                                detailedViewItem?.created_at
                                            ).toDateString()}{' '}
                                            {new Date(
                                                detailedViewItem?.created_at
                                            ).toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
};

export default BookingOrderHistory;
