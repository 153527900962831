import AppConstant from "../../../constant/AppConstant.js";
import { getOrderSource } from "../../../services/apiService.js";

export const deleteAPI = async (url) => {
  let option = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("user"),
    },
  };

  let response = await fetch(
    AppConstant.config.appBaseUrl +  `/${getOrderSource()}/` + url,
    option
  );
  return response;
};
export const cloneAPI = async (url) => {
  let option = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("user"),
    },
  };

  let response = await fetch(
    AppConstant.config.appBaseUrl + getUrl() + "/" + url,
    option
  );
  return response;
};

export const getTaxes = async () => {
  let option = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("user"),
    },
  };
  let response = await fetch(
    AppConstant.config.appBaseUrl + "/common/order/hotel/taxes",
    option
  );
  let result = await response.json();
  return result?.data;
};
export const getUrl = () => {
  let user_type = localStorage.getItem("user_type");
  if (user_type === "restaurant_manager") {
    return "/restaurant";
  }
  return "/ird";
};
