import React from "react";
import { useSelector } from "react-redux";
import { commonSelector } from "../../store/slices/common/commonSlice";
import Complimentary from "../Ird/Complimentary/Complimentary";
import Timer from "../Timer/timer";

function OrderCard(props) {
    
    const { hotelDetails = {} } = useSelector(commonSelector);
  const { item, timerTime, ServiceName } = props;
  var date1 = new Date();
  return (
    <div className={"orderCard "}>
      {item.order_detail?.preorder_on ? (
        <div className="preOrder">Preorder</div>
      ) : null}
      <div className="orderCard-row">
        {item?.primises && (
          <div
            className={
              "text " +
              (date1 - new Date(item?.order_detail?.created_at) > 20000
                ? "blink"
                : "")
            }
          >
            # {item?.primises?.premise_no}
          </div>
        )}
        {item.table && (
          <div
            className={
              "text " +
              (date1 - new Date(item?.order_detail?.created_at) > 20000
                ? "blink"
                : "")
            }
          >
            # {item.table.table_no}
          </div>
        )}
        <div className="text">
          {timerTime && (
            <small>
              {new Date(item.order_detail.created_at).toDateString()}
            </small>
          )}
          {timerTime && (
            <small>
              {new Date(timerTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </small>
          )}
        </div>
      </div>
      <div className="line"></div>

      <div className="orderCard-row TA-c">
        <div className="text left">No of Guest: </div>
        <div className="text">{item?.no_of_guest}</div>
      </div>
      {item.order_detail?.preorder_on ? (
        <>
          <div className="line"></div>

          <div className="orderCard-row TA-c">
            <div className="text left">Preorder Time: </div>
            <div className="text">
              <small>
                {new Date(item.order_detail?.preorder_on).toDateString()}
              </small>
              <small>
                {new Date(item.order_detail?.preorder_on).toLocaleTimeString(
                  [],
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </small>
            </div>
          </div>
        </>
      ) : null}
      {item.type === "hotel_admin" && (
        <>
          <div className="line"></div>
          <div className="orderCard-row redbg">
            <div className="text left">Payment Status: </div>
            <div className="text">
              {item.payment_status === "offline"
                ? "Settle Later"
                : item.payment_status}
            </div>
          </div>
        </>
      )}
      <div className="line"></div>

      {/* {item?.order_payment_mode?.name && (
        <div className="orderCard-row redbg">
          <div className="text left">Payment Status: </div>
          <div className="text">
           {item?.order_payment_mode?.name}
          </div>
        </div>
      )}  */}
      {timerTime && (
        <div className="orderCard-row redbg">
          <div className="text left">{props.OrderType} </div>
          <div className="text">
            {ServiceName === "IRD" || ServiceName === "PRM" ? (
              <Timer countDown={timerTime} />
            ) : (
              <>
                <small>{new Date(timerTime).toDateString()}</small>
                <small>
                  {new Date(timerTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </small>
              </>
            )}
          </div>
        </div>
      )}
      {item?.order_menu_items?.length && (
        <div className="orderCard-row">
          <div className="text orderedItems">
            {item.order_menu_items.map((itemMenu, index) => {
              if (index < 3) {
                return (
                  <small key={itemMenu.id}>
                    {itemMenu.quantity} - {itemMenu.item?.name}
                  </small>
                );
              }
            })}
            {item.order_menu_items?.length > 3 ? (
              <small>
                <i>{item.order_menu_items.length - 3} more items....</i>
              </small>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      <div className="line"></div>

      <div className="orderCard-row ">
        <div className="text">
          <small>Order Instructions</small>
          <small>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {item.description || "--"}
            </pre>
          </small>
        </div>
      </div>
      <div className="line"></div>
      {props.complimentaryChange && (
        <>
          <div className="orderCard-row ">
            <div className="text">
              <Complimentary
                compChange={props.complimentaryChange}
                item={item}
              />
            </div>
          </div>

          <div className="line"></div>
        </>
      )}

      <div className="card_action">
        <button className="btn btn-link" onClick={props.viewDetails}>
          + View Details
        </button>
        <button className="btn" onClick={props.footerBtn}>
          {props.btnTitle}
        </button>
      </div>
    </div>
  );
}

export default OrderCard;
