import React, { useEffect, useState } from "react";
import AppConstant from "../../../constant/AppConstant.js";
import { Multiselect } from "multiselect-react-dropdown";
import { getUrl } from "./service.js";
import Modal from "react-modal";
import AddEditTranslations from "./AddEditTranslations.js";
import { convertJSONtoFormData } from "../../../helper/_commonHelper.js";
import { getAddonTaxes } from "../../../services/apiService.js";

const AddEditAddOn = ({
  title,
  data,
  ids,
  setShow,
  refresh,
  setSuccess,
  taxesList,
  open,
}) => {
  const [formData, setFormData] = useState({
    menu_item_id: ids?.itemId,
    menu_item_subaddon_id: ids?.subAddOnId,
    name: data?.name || "",
    description: data?.description || "",
    priority: data?.priority || 0,
    type: data?.type || "veg",
    thumbnail: undefined,
    price: data?.price || 0,
    max_per_order: data?.max_per_order || 1,
    min_per_order: data?.min_per_order || 0,
    taxes: data?.taxes,
    translations: data?.translations || [],
    // tags: JSON.parse(data?.tags || "[]")?.join(",")
  });
  const [selectedTaxes, setselectedTaxes] = useState([]);
  const [errors, setError] = useState(null);
  const [rows, setRows]= useState([]);

  const handleChange = (evt) => {
    let value = null;
    if (evt.target.type === "checkbox") {
      value = evt.target.checked;
    } else if (evt.target.type === "file") {
      value = evt.target.files[0];
    } else {
      value = evt.target.value;
    }
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  // const getAllTaxes = async ({ addOnId }) => {
  //   if (addOnId) {
  //     let option = {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json, text/plain, */*",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("user"),
  //       },
  //     };

  //     try {
  //       // to get item without subcategory
  //       let taxList = await fetch(
  //         AppConstant.config.appBaseUrl +
  //           getUrl() +
  //           `/menu/addon/taxes/${addOnId}`,
  //         option
  //       );

  //       // subcategories data
  //       taxList = await taxList.json();
  //       const alltaxList = taxList?.data?.map(({ tax }) => ({
  //         id: tax.id,
  //         name: tax.name,
  //       }));

  //       if (alltaxList?.length) {
  //         const selectedTaxList = taxesList?.filter(({ id: id1 }) =>
  //           alltaxList.some(({ id: id2 }) => id2 === id1)
  //         );
  //         setFormData({
  //           ...formData,
  //           taxes: selectedTaxList?.map((x) => x.id),
  //       });
  //         setselectedTaxes(selectedTaxList);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const getAllTaxes = async ({ addOnId }) => {
    if (addOnId) {
        try {
            const response = await getAddonTaxes(addOnId);
            if (response.status === 200) {
                // subcategories data
                const taxList = response.data.data || [];
                const alltaxList = taxList?.map(({ tax }) => ({
                    id: tax.id,
                    name: tax.name,
                }));

                if (alltaxList?.length) {
                    const selectedTaxList = taxesList?.filter(
                        ({ id: id1 }) =>
                            alltaxList.some(({ id: id2 }) => id2 === id1)
                    );
                    setFormData({
                        ...formData,
                        taxes: selectedTaxList?.map((x) => x.id),
                    });
                    setselectedTaxes(selectedTaxList);
                }
            }
            } catch (error) {
                console.log(error);
            }
        }
    };

  useEffect(() => {
    if (ids) {
      getAllTaxes(ids);
    }
  }, [ids]);

  const saveForm = async () => {
    let form = formData;
    if (data?.id) {
      delete form.menu_id;
    }
    if (form?.tags?.includes(",")) {
      form.tags = JSON.stringify(form?.tags?.split(","));
    }

    try {

      let updatedRows=rows.map((row)=>({
        hotel_language_id:row.hotel_language_id,
        name:row.name,
        description:row.description,
        desclaimer:row.desclaimer,

      }))

      formData['translations']=updatedRows;
      formData['taxes'] = selectedTaxes.map((tax) => tax.id);
      const datatoSend=convertJSONtoFormData(formData);

      let option = {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          //   'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: "Bearer " + localStorage.getItem("user"),
        },
        body: datatoSend,
      };
      
      let response = await fetch(
        AppConstant.config.appBaseUrl +
          getUrl() +
          (data?.id ? "/update/menu/category/item/addon" : "/menu/addon") +
          (data?.id ? "/" + data?.id : ""),
        option
      );
      let result = await response.json();

      if (result?.message && response.status === 400) {
        const keys = Object.keys(result?.message);
        let errs = keys?.map((key) => {
          return {
            field: key,
            error: result?.message?.[key]?.[0],
          };
        });
        setError(errs);
      } else {
        if (response.ok) {
          setSuccess(result.message);
          refresh();
          setShow(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async () => {
    await saveForm();
  };

  useEffect(() => {
    setselectedTaxes(taxesList?.filter((x) => data?.taxes?.includes(x)));
  }, [data]);

  const onSelect = (selectedList, selectedItem) => {
    setFormData({
      ...formData,
      taxes: selectedList?.map((x) => x.id),
    });
    setselectedTaxes(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setFormData({
      ...formData,
      taxes: selectedList
        ?.filter((e) => e.id !== removedItem?.id)
        ?.map((x) => x.id),
    });
    setselectedTaxes(selectedList);
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setShow(false)}
      className={"modal-dialog modal-lg modal-order-details"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Example Modal"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        <div className="modal-header">
          <div className="title">
            {data?.id ? "Edit " + title : "Add " + title}
          </div>

          <button className="btn btn-icon" onClick={() => setShow(false)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="modal-body">
          {/* FORM */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </div> */}
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Type</label>
              <select
                className="form-control"
                value={formData.type}
                onChange={(e) => handleChange(e)}
                name="type"
              >
                <option  value={'veg'}>
                    Vegeterian
                  </option>
                  <option value={'non_veg'}>
                  Non Vegeterian
                  </option>
                  <option value={'alcohol'}>
                  Alcohol
                  </option>
                  <option value={'non_alcohol_bevrages'}>
                  Non Alcohol
                  </option>
              </select>
            </div> */}
          {/* {
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Thumbnail</label>
                <input
                  type="file"
                  className="form-control"
                  placeholder="thumbnail"
                  name="thumbnail"
                  // value={formData.image}
                  onChange={handleChange}
                />
              </div>
            } */}
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Tags</label>
              <input
                type="text"
                className="form-control"
                placeholder="tags"
                name="tags"
                value={formData.tags}
                onChange={handleChange}
              />
            </div> */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Price - leave blank if not applicable. This price will be added
              with the price of item.
            </label>
            <input
              type="number"
              className="form-control"
              placeholder="Price"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Priority</label>
            <input
              type="number"
              className="form-control"
              placeholder="priority"
              name="priority"
              value={formData.priority}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Maximum Per Order</label>
            <input
              type="number"
              className="form-control"
              placeholder="max_per_order"
              name="max_per_order"
              value={formData.max_per_order}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Mininum Per Order</label>
            <input
              type="number"
              className="form-control"
              placeholder="min_per_order"
              name="min_per_order"
              value={formData.min_per_order}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Taxes</label>
            {/* <input
                type="text"
                className="form-control"
                placeholder="taxes"
                name="taxes"
                value={formData.taxes}
                onChange={handleChange}
              /> */}
            <Multiselect
              options={taxesList} // Options to display in the dropdown
              selectedValues={selectedTaxes} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className="row">
            <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="translations">Translations</label>
              <AddEditTranslations
              translations={formData.translations}
              rows={rows}
              setRows={setRows}
              showDescriptionField={false}
              />
            </div>
            </div>
          </div>

          <div className="text-danger">
            {errors &&
              errors.map((err) => (
                <>
                  <div>
                    {err?.field?.toUpperCase()} : {err?.error}
                  </div>
                </>
              ))}
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn" onClick={() => setShow(false)}>
            Close
          </button>
          <button className="btn btn-primary" onClick={() => submit()}>
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddEditAddOn;
