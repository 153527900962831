import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    compareAndSave,
    updateLocalOrderByType,
} from '../helper/_commonHelper';
import { fetchBookingOrderList } from '../store/slices/booking/bookingSlice';
import { fetchConnectOrderList } from '../store/slices/connect/connectSlice';
import {
    fetchIRDOrderList,
    irdSelector,
    updateActionTakenOnOrderID,
} from '../store/slices/ird/irdSlice';
import { fetchLaundryOrderList } from '../store/slices/laundry/laundrySlice';
import { fetchSpaOrderList } from '../store/slices/spa/spaSlice';
import Pusher from 'pusher-js';
import { useSelector } from 'react-redux';
import { commonSelector } from '../store/slices/common/commonSlice';
import { notification } from 'antd';
import { getLastOrderIds } from '../services/apiService';

const NotificationContext = createContext({});
export const useNotification = () => useContext(NotificationContext);
const NotificationContextProvider = (props) => {
    const [notificationData, setNotificationData] = useState({});
    const [lastOrderIDs, setLastOrderIDs] = useState({});
    const dispatch = useDispatch();
    const { hotelDetails } = useSelector(commonSelector);
    const { actionTakenOnOrderId } = useSelector(irdSelector);
    const audio = new Audio(
        'https://master-vserve.s3.ap-south-1.amazonaws.com/tone/desk_bell.mp3'
    );
    audio.muted = true;

    useEffect(() => {
        let myInterval = setInterval(fetchLastOrders, 30000);
        return () => {
            clearInterval(myInterval);
        };
    }, []);

    useEffect(() => {
        let pusher;
        if (hotelDetails.hotel && hotelDetails.hotel.id) {
            pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: 'ap2',
            });
            const channel = pusher.subscribe(`hotel_${hotelDetails.hotel.id}`);

            if (
                hotelDetails.type === 'hotel_admin' ||
                hotelDetails.type === 'ird_manager'
            ) {
                channel.bind('new_order_ird', (data) => {
                    let orderID = data.message.order_id || '';
                    const title = 'You have a New Order for IRD';
                    const msg = `A new order has been recieved for IRD with orderID #${orderID}`;
                    const servicesDataType = data.message.target || '';
                    triggerNotificationUpdate({
                        orderID,
                        title,
                        msg,
                        servicesDataType,
                    });
                });
                channel.bind('new_pre_order', (data) => {
                    let orderID = data.message.order_id || '';
                    const title = 'You have a New PreOrder request';
                    const msg = `A new pre order request has been recieved with orderID #${orderID}`;
                    const servicesDataType = data.message.target || '';
                    triggerNotificationUpdate({
                        orderID,
                        title,
                        msg,
                        servicesDataType,
                    });
                });
            }
            if (
                hotelDetails.type === 'hotel_admin' ||
                hotelDetails.type === 'restaurant_manager'
            ) {
                channel.bind('new_order_restaurant', (data) => {
                    let orderID = data.message.order_id || '';
                    const title = 'You have a New Order for Restaurant';
                    const msg = `A new order has been recieved for restaurant with orderID #${orderID}`;
                    const servicesDataType = data.message.target || '';
                    triggerNotificationUpdate({
                        orderID,
                        title,
                        msg,
                        servicesDataType,
                    });
                });
            }
            channel.bind('new_order_mini_bar', (data) => {
                let orderID = data.message.order_id || '';
                const title = 'You have a New Order for mini bar';
                const msg = `A new order has been recieved for mini bar with orderID #${orderID}`;
                const servicesDataType = data.message.target || '';
                triggerNotificationUpdate({
                    orderID,
                    title,
                    msg,
                    servicesDataType,
                });
            });

            if (
                hotelDetails.type === 'spa_manager' ||
                hotelDetails.type === 'hotel_admin'
            ) {
                channel.bind('new_order_spa', (data) => {
                    let orderID = data.message.order_id || '';
                    const title = 'You have a New order for Spa';
                    const msg = `A new order has been recieved for Spa with orderID #${orderID}`;
                    const servicesDataType = data.message.target || '';
                    triggerNotificationUpdate({
                        orderID,
                        title,
                        msg,
                        servicesDataType,
                    });
                });
            }
            if (
                hotelDetails.type === 'laundry_manager' ||
                hotelDetails.type === 'hotel_admin'
            ) {
                channel.bind('new_order_laundry', (data) => {
                    let orderID = data.message.order_id || '';
                    const title = 'You have a New order for Laundry';
                    const msg = `A new order has been recieved for Laundry with orderID #${orderID}`;
                    const servicesDataType = data.message.target || '';
                    triggerNotificationUpdate({
                        orderID,
                        title,
                        msg,
                        servicesDataType,
                    });
                });
            }
            if (
                hotelDetails.type === 'connect_manager' ||
                hotelDetails.type === 'hotel_admin'
            ) {
                channel.bind('new_order_connect', (data) => {
                    let orderID = data.message.order_id || '';
                    const title = 'You have a New Order for Connect';
                    const msg = `A new order has been recieved for connect with orderID #${orderID}`;
                    // const servicesDataType = data.result.type || ''; // old
                    const servicesDataType = data.message.target || '';
                    triggerNotificationUpdate({
                        orderID,
                        title,
                        msg,
                        servicesDataType,
                    });
                });
            }
            if (
                hotelDetails.type === 'booking_manager' ||
                hotelDetails.type === 'hotel_admin'
            ) {
                channel.bind('new_order_booking', (data) => {
                    let orderID = data.message.order_id || '';
                    const title = 'You have a New Booking Request';
                    const msg = `A new request has been recieved for Booking with orderID #${orderID}`;
                    // const servicesDataType = data.result.type || ''; // old
                    const servicesDataType = data.message.target || '';
                    triggerNotificationUpdate({
                        orderID,
                        title,
                        msg,
                        servicesDataType,
                    });
                });

                channel.bind('cancel_order_booking', (data) => {
                    let orderID = data.message.order_id || '';
                    const title = 'You have a New Cancel Request for Booking';
                    const msg = `A new Cancel Request has been recieved for Booking with orderID #${orderID}`;
                    const servicesDataType = data.message.target || '';
                    triggerNotificationUpdate({
                        orderID,
                        title,
                        msg,
                        servicesDataType,
                    });
                });
            }
        }
        return () => {
            if (hotelDetails.hotel && hotelDetails.hotel.id) {
                pusher.unsubscribe(`hotel_${hotelDetails.hotel.id}`);
            }
        };
    }, [hotelDetails, actionTakenOnOrderId]);

    const fetchLastOrders = async () => {
        try {
            let response = await getLastOrderIds();
            if (response.status === 200) {
                const data = response.data?.data || {};
                setLastOrderIDs(data);
                triggerBell(data);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const triggerBell = (data) => {
        let hasNewOrders = compareAndSave(data);
        setNotificationData(hasNewOrders);
        if (Object.keys(hasNewOrders).length) {
            playRing();
        }
    };
    const triggerNotificationUpdate = (payload, orderType) => {
        const { orderID, actionTakenOnOrderId, title, msg, servicesDataType } =
            payload;
        if (orderID !== actionTakenOnOrderId) {
            playRing();
        }
        switch (servicesDataType) {
            case 'ird':
            case 'ird_pre_order':
            case 'ird_mini_bar':
                dispatch(fetchIRDOrderList());
                setNotificationData((prevState) => ({
                    ...prevState,
                    ird: true,
                }));
                break;
            case 'restaurant':
            case 'restaurent':
                dispatch(fetchIRDOrderList());
                setNotificationData((prevState) => ({
                    ...prevState,
                    restaurent: true,
                }));
                break;
            case 'spa':
                dispatch(fetchSpaOrderList());
                setNotificationData((prevState) => ({
                    ...prevState,
                    spa: true,
                }));
                break;
            case 'laundry':
                dispatch(fetchLaundryOrderList());
                setNotificationData((prevState) => ({
                    ...prevState,
                    laundry: true,
                }));
                break;
            case 'connect':
                dispatch(fetchConnectOrderList());
                setNotificationData((prevState) => ({
                    ...prevState,
                    connect: true,
                }));
                break;
            case 'booking':
                dispatch(fetchBookingOrderList());
                setNotificationData((prevState) => ({
                    ...prevState,
                    booking: true,
                }));
                break;

            default:
                break;
        }
        if (orderType === 'new') {
            notification.success({
                message: title,
                description: msg,
            });
        } else if (orderType === 'cancel') {
            notification.error({
                message: title,
                description: msg,
            });
        } else {
            notification.success({
                message: title,
                description: msg,
            });
        }
        setLastOrderIDs({ ...lastOrderIDs, [servicesDataType]: orderID });
        dispatch(updateActionTakenOnOrderID(null));
    };
    const playRing = () => {
        try {
            if (!audio.paused) {
                audio.muted = true;
                audio.muted = false;
                audio?.pause();
                audio?.play();
            } else {
                audio.muted = true;
                audio.muted = false;
                audio?.play();
            }
        } catch (error) {
            console.log('Can not play the bell!!');
        }
    };

    const updateOrderByType = (type) => {
        if (type === 'ird') {
            dispatch(fetchIRDOrderList());
            setNotificationData((prevState) => ({
                ...prevState,
                ird: null,
            }));
        } else if (type === 'restaurant' || type === 'restaurent') {
            dispatch(fetchIRDOrderList());
            setNotificationData((prevState) => ({
                ...prevState,
                restaurent: null,
            }));
        } else if (type === 'spa') {
            dispatch(fetchSpaOrderList());
            setNotificationData((prevState) => ({
                ...prevState,
                spa: null,
            }));
        } else if (type === 'laundry') {
            dispatch(fetchLaundryOrderList());
            setNotificationData((prevState) => ({
                ...prevState,
                laundry: null,
            }));
        } else if (type === 'connect') {
            dispatch(fetchConnectOrderList());
            setNotificationData((prevState) => ({
                ...prevState,
                connect: null,
            }));
        } else if (type === 'booking') {
            dispatch(fetchBookingOrderList());
            setNotificationData((prevState) => ({
                ...prevState,
                booking: null,
            }));
        }
        updateLocalOrderByType(lastOrderIDs, type);
        triggerBell(lastOrderIDs);
    };

    const value = {
        notificationData,
        updateOrderByType,
        playRing,
    };
    return (
        <NotificationContext.Provider value={value}>
            {props.children}
        </NotificationContext.Provider>
    );
};

export default NotificationContextProvider;
