import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import axios from "axios";
const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    updateLoading(state, { payload }) {
      state.loading = payload;
    },
    updateOrderList(state, { payload }) {
      state.orderList = payload;
    },
    updateServiceList(state, { payload }) {
      state.serviceList = payload;
    },
    updateSelectedServiceID(state, { payload }) {
      state.selectedServiceID = payload;
    },
  },
});

export const bookingSelector = (state) => state.bookingSlice;
export const {
  updateLoading,
  updateOrderList,
  updateServiceList,
  updateSelectedServiceID,
} = bookingSlice.actions;

export default bookingSlice.reducer;

export function fetchBookingOrderList(filterType) {
  return async (dispatch, getState) => {
    const { selectedServiceID } = getState().bookingSlice;
    try {
      let queryParams = "";
      if (filterType) {
        queryParams = `&search=${filterType}`;
      }
      dispatch(updateLoading(true));
      const response = await axios.get(
        `/booking-service/order?booking_service_id=${selectedServiceID}${queryParams}`
      );
      if (response.status === 200) {
        const data = response.data.data || [];
        dispatch(updateOrderList(data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateLoading(false));
    }
  };
}
export function fetchBookingServiceList(hotelID) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/common/hotel/booking-services/${hotelID}`
      );
      if (response.status === 200) {
        const data = response.data.data || [];
        dispatch(updateServiceList(data));
        dispatch(updateSelectedServiceID(data[0].id));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateLoading(false));
    }
  };
}
