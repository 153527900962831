import React, { useEffect, useState } from "react";

const Complimentary = ({ compChange, item }) => {
  const [isChecked, setIsChecked] = useState(item?.is_complementary);
  const onComplimentaryChange = () => {
    // const value = event.target
    setIsChecked(!isChecked);
    const v = !isChecked ? "enable" : "disable";
    compChange(v, item);
  };
  return (
    <div>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => onComplimentaryChange()}
      />
      <label style={{ fontSize: "11px", margin: "0px 10px 0" }}>
        {"  Complimentary"}
      </label>
    </div>
  );
};

export default Complimentary;
