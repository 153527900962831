import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { commonSelector } from "../../store/slices/common/commonSlice";
import CreateDescription from "../../Components/Ird/MenuEdit/MasterTextEditor";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80vh', 
    maxWidth: '90vw', 
    overflow: 'auto', 
  }
};

const AddEditFeedTranslation = ({
  translations,
  rows,
  setRows,
  isOpen,
  onClose
}) => {
  const { hotelDetails } = useSelector(commonSelector);
  const hotelLanguages = hotelDetails.hotel?.languages || [];

  const [languageList, setLanguageList] = useState(hotelLanguages);

  useEffect(() => {
    updateLanguages();
  }, [rows]);

  useEffect(() => {
    if (translations) {
      setRows(translations.map(translation => ({
        ...translation,
        language_name: hotelLanguages.find(lang => lang.id === translation.hotel_language_id)?.lang_name || ''
      })));
    }
  }, [translations, hotelLanguages]);

  const updateLanguages = () => {
    if (rows) {
      const list = rows.map((item) => +item.hotel_language_id);
      const newList = hotelLanguages.filter(
        (item) => !list.includes(item.id)
      );
      setLanguageList(newList);
    }
  };

  const handleChange = (e, idx) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    let newObj = {
      ...updatedRows[idx],
      [name]: value,
    };
    if (value && name === 'hotel_language_id') {
      let data = languageList.find((d) => d.id == value);
      newObj['hotel_language_id'] = data.id;
      newObj['language_code'] = data.lang_code;
      newObj['language_name'] = data.lang_name;
    }
    updatedRows[idx] = newObj;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    if (languageList.length === 0) {
      alert('No available languages to add.');
      return;
    }

    const newItem = {
      hotel_language_id: '',
      description: '',
      language_name: '',
      language_code: '',
      interest_action_btn_text:'',
      interest_action_response:''
    };

    if (languageList.length === 1) {
      newItem['language_code'] = languageList[0].lang_code;
      newItem['language_name'] = languageList[0].lang_name;
      newItem['hotel_language_id'] = languageList[0].id;
    }

    setRows([...rows, newItem]);
  };

  const handleRemoveRow = (idx) => {
    if (!window.confirm('Are you sure you want to remove this translation?'))
      return;
    const updatedRows = rows.filter((item, index) => index !== idx);
    setRows(updatedRows);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add/Update Feed Translation"
      style={customStyles}
    >
      <h2>{rows.length===0?'Add':'Update'} Feed Translation</h2>
      <table className="table table-bordered table-hover" id="tab_logic">
        <thead>
          <tr>
            <th className="text-center">Select language</th>
            <th className="text-center">Description</th>
            <th className="text-center">Action Button Text</th>
            <th className="text-center">Thankyou Message</th>
          </tr>
        </thead>
        <tbody>
          {rows && rows.map((item, idx) => (
            <tr id={`addr${idx}`} key={idx}>
              <td>
                {console.log(item)}
                {item?.hotel_language_id ? (
                    item.language_name
                  ) : (
                    <select
                        className="form-control"
                        onChange={(e) => handleChange(e, idx)}
                        disabled={item?.language_code}
                        name='hotel_language_id'
                        value={item.hotel_language_id ? item.hotel_language_id.toString() : ''}
                    >
                      <option value="">
                        Select Language
                      </option>
                      {languageList.map((language) => (
                        <option
                          key={language.id}
                          value={language.id.toString()}
                          disabled={item?.id}
                        >
                          {language.lang_name || ""}
                        </option>
                      ))}
                    </select>

                  )}
              </td>
              <td>
                <CreateDescription
                  description={item.description}
                  onChange={(e) => handleChange(e, idx)}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="interest_action_btn_text"
                  value={item.interest_action_btn_text || ""}
                  onChange={(e) => handleChange(e, idx)}
                  className="form-control"
                />
              </td>
              <td>
                <input
                  type="text"
                  name="interest_action_response"
                  value={item.interest_action_response || ""}
                  onChange={(e) => handleChange(e, idx)}
                  className="form-control"
                />
              </td>
              <td>
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => handleRemoveRow(idx)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={handleAddRow}
        className="btn btn-primary"
        disabled={languageList.length <= 0}
      >
        Add Row
      </button>
      <button onClick={onClose} className="btn btn-secondary">Close</button>
    </Modal>
  );
};

export default AddEditFeedTranslation;
