import getSymbolFromCurrency from "currency-symbol-map";

export const getheader = () => {
  let option = {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("user"),
    },
  };
  return option;
};

export const printCurrencySymbol = () => {
  const hotelDetails = JSON.parse(localStorage.getItem("hotel"));
  if (hotelDetails) {
    let definedSymbol = hotelDetails?.hotel_config?.currency_symbol;
    if (definedSymbol) {
      return definedSymbol;
    }
    const currencyCode = hotelDetails?.country?.currency_code?.toUpperCase();
    if (
      currencyCode === "AED" ||
      currencyCode === "FJD" ||
      currencyCode === "PHL" ||
      currencyCode === "SAR" ||
      currencyCode === "OMR"
    ) {
      return currencyCode;
    }
    return getSymbolFromCurrency(currencyCode);
  }
  return "";
};

export const compareAndSave = (newOrders) => {
  let oldOrder = {};
  const getLocalOrder = localStorage.getItem("__lastOrders");
  if (!getLocalOrder) {
    localStorage.setItem("__lastOrders", JSON.stringify(newOrders));
  }
  if (getLocalOrder) {
    oldOrder = JSON.parse(getLocalOrder);
  }
  let result = {};
  for (let key in newOrders) {
    if (newOrders[key] && oldOrder[key] && newOrders[key] > oldOrder[key]) {
      result[key] = true;
    }
  }
  return result;
};

export const updateLocalOrderByType = (data, type) => {
  let oldOrder = {};
  const getLocalOrder = localStorage.getItem("__lastOrders");
  if (getLocalOrder) {
    oldOrder = JSON.parse(getLocalOrder);
  }
  let newOrder = { ...oldOrder, [type]: data[type] };
  localStorage.setItem("__lastOrders", JSON.stringify(newOrder));
};

export const groupByOrdersByStatus = (orderList = []) => {
  let result = {};
  result = orderList.reduce((acc, item) => {
    if (!acc[item.status]) {
      acc[item.status] = [];
    }
    acc[item.status].push(item);
    return acc;
  }, {});

  return result;
};



export const convertImageToBase64 =(imgUrl,callback) =>{
    const image = new Image();
    image.crossOrigin='anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      callback && callback(dataUrl)
    }
    image.src = imgUrl;
}

export const removeUserDataFromBrowserStorage = ()=>{
    let keysToRemove = [
        'user',
        'hotel',
        'user_type',
        '__lastOrders',
        'isMenu',
        'isMenuToggle',
        'enc_hotel_id',
    ];

    for (let key of keysToRemove) {
        localStorage.removeItem(key);
    }
}

export const convertJSONtoFormData = (data) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};
const buildFormData = (formData, data, parentKey) => {
  if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File) &&
      !(data instanceof Blob)
  ) {
      Object.keys(data).forEach((key) => {
          buildFormData(
              formData,
              data[key],
              parentKey ? `${parentKey}[${key}]` : key
          );
      });
  } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
  }
};