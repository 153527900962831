import { Empty } from 'antd';
import React from 'react';

const NoDataFound = ({ msg = 'No Data Found' }) => {
    return (
        <Empty
            image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
            imageStyle={{
                height: 60,
            }}
            description={<span>{msg}</span>}
        ></Empty>
    );
};

export default NoDataFound;
