import { Breadcrumb } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import MenuEditOld from '../../Components/Ird/MenuEdit';
import { commonSelector } from '../../store/slices/common/commonSlice';
const MenuEdit = () => {
    const { hotelDetails = {} } = useSelector(commonSelector);

    const ActionHeader = ({ searchValue, inputHandleChange ,type}) => {
        return (
            <div className='action-header'>
               <div className='action-left'>
                <div className='page-title'>{type === 'restaurant_manager' ? 'Restaurant' :'IN ROOM DINING'}</div>
                <Breadcrumb
                    items={[
                        {
                            title: <a href='/ird'>{type === 'restaurant_manager' ? 'Restaurant ':'IRD'}</a>,
                        },
                            {
                                title: 'Edit Menu ',
                            },
                        ]}
                    />
                </div>
            </div>
        );
    };
    return (
        <>
            <ActionHeader
                type={hotelDetails.type}
            
            />
            <div
                className='menu-edit-container'
                style={{ position: 'relative' }}
            >
                <MenuEditOld />
            </div>
        </>
    );
};

export default MenuEdit;
