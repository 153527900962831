import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import Loading from '../../Components/common/Loading';
import NoDataFound from '../../Components/common/NoDataFound';
import AppConstant from '../../constant/AppConstant';
const ActionHeader = ({ searchValue, inputHandleChange }) => {
    return (
        <div className='action-header'>
            <div className='action-left'>
                <div className='page-title'>BOOKING</div>
                <Breadcrumb
                    items={[
                        {
                            title: <a href='/booking'>Booking</a>,
                        },
                        {
                            title: 'Cancelled Orders',
                        },
                    ]}
                />
            </div>
            <div className='action-right'>
                <Input
                    value={searchValue}
                    placeholder='Search Name/Order Id/Room/Villa No'
                    style={{ width: '15rem' }}
                    prefix={<SearchOutlined />}
                    onChange={(e) => inputHandleChange(e)}
                />{" "}
                <ReactHtmlTableToExcel
                    id='test-table-xls-button'
                    className='download-table-xls-button btn btn-back'
                    table='table-booking'
                    filename='tablexls'
                    sheet='allOrders'
                    buttonText='Download as XLS'
                />
            </div>
        </div>
    );
};
function CancelOrder({ props }) {
    const [loading, setLoading] = useState(false);
    const [cancelOrderLIst, setCancelOrderLIst] = useState([]);
    const [searchValue, setSearchvalue] = useState('');
    useEffect(() => {
        getData();
    }, []);
    const converTime = (time) => {
        if (time) {
            let covertedTime = time;
            let H = +covertedTime.substr(0, 2);
            let h = H % 12 || 12;
            h = h < 10 ? '0' + h : h;
            let ampm = H < 12 ? ' AM' : ' PM';
            covertedTime = h + covertedTime.substr(2, 3) + ampm;
            return covertedTime;
        } else {
            return time;
        }
    };
    const getApiOptionHeader = () => {
        let option = {
            method: 'GET',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('user'),
            },
        };
        return option;
    };
    const getData = async () => {
        try {
            let res = await fetch(
                AppConstant.config.appBaseUrl + `/booking-service/order`,
                getApiOptionHeader()
            );
            let { data } = await res.json();
            if (data.length) {
                data = data.filter((item) => item.status === 'canceled');
                setCancelOrderLIst(data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
    };

    const printCancelledOrder = () => {
        return (
            <table
                className='table table-striped table-hover table-history'
                id='table-booking'
            >
                <thead>
                    <tr>
                        <th className='text-center'>Order Id</th>
                        <th className='text-center'>Booked at</th>
                        <th className='text-center'>Slot</th>
                        <th className='text-center'>Venue</th>
                        <th className='text-center'>Room/Villa No</th>
                        <th className='text-center'>Last Name</th>
                        <th className='text-center'>Number of Guests</th>
                        <th className='text-center'>Remark</th>
                    </tr>
                </thead>
                {loading ? (
                    <tr
                        style={{
                            backgroundColor: 'transparent',
                        }}
                    >
                        <td colspan='9'>
                            <Loading />
                        </td>
                    </tr>
                ) : cancelOrderLIst.length === 0 ? (
                    <tr
                        style={{
                            backgroundColor: 'transparent',
                        }}
                    >
                        <td colspan='9'>
                            <NoDataFound />
                        </td>
                    </tr>
                ) : (
                    cancelOrderLIst
                        ?.filter((data) => {
                            if (data.guest.name == '') {
                                return data;
                            } else if (
                                data.guest.name
                                    .toLowerCase()
                                    .startsWith(searchValue.toLowerCase()) ||
                                data.primises?.premise_no
                                    .toLowerCase()
                                    .startsWith(searchValue.toLowerCase()) ||
                                data?.id?.toString()?.startsWith(searchValue)
                            ) {
                                return data;
                            }
                        })
                        .map((booking, index) => {
                            return (
                                <tbody key={index}>
                                    <tr key={index}>
                                        <td className='text-center'>
                                            {booking?.id}
                                        </td>
                                        <td
                                            className='text-center'
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {moment(
                                                booking?.order_detail
                                                    ?.created_at
                                            ).format('D MMM')}
                                            <br />
                                            {moment(
                                                booking?.order_detail
                                                    ?.created_at
                                            ).format('LT')}
                                        </td>
                                        <td className='text-center'>
                                            {moment(
                                                booking?.order_detail
                                                    ?.pickup_slot
                                            ).format('D MMM YYYY')}
                                            <br />
                                            {converTime(
                                                booking?.order_detail
                                                    ?.booking_service_timing
                                                    ?.opening_time
                                            )}{' '}
                                            -
                                            {converTime(
                                                booking?.order_detail
                                                    ?.booking_service_timing
                                                    ?.closing_time
                                            )}
                                        </td>
                                        <td className='text-center'>
                                            {
                                                booking
                                                    ?.order_booking_services[0]
                                                    ?.booking_service_name
                                            }
                                        </td>
                                        <td className='text-center'>
                                            {booking?.primises?.premise_no}
                                        </td>
                                        <td className='text-center'>
                                            {booking?.guest?.name}
                                        </td>
                                        <td className='text-center'>
                                            {booking?.no_of_guest}
                                        </td>
                                        <td className='text-center'>
                                            {booking?.order_remark?.remark}
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })
                )}
            </table>
        );
    };
    const inputHandleChange = (e) => {
        setSearchvalue(e.target.value);
    };
    return (
        <>
            <ActionHeader
                inputHandleChange={inputHandleChange}
                searchValue={searchValue}
            />
            <section className='order-history-wrapper'>
                <div
                    className={
                        'home-order-screen ' +
                        (cancelOrderLIst?.length === 0 ? 'no-data' : '')
                    }
                    style={{ width: '100%' }}
                >
                    {printCancelledOrder()}
                </div>
            </section>
        </>
    );
}

export default CancelOrder;
