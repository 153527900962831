import React, { useEffect, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Loading from '../../Components/common/Loading';
import { printCurrencySymbol } from '../../helper/_commonHelper';
import { fetchRevenueData, irdSelector } from '../../store/slices/ird/irdSlice';
import Time from '../../Components/Times/timesOne.js';
import { Breadcrumb, Button, Input } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import NoDataFound from '../../Components/common/NoDataFound';
import { commonSelector } from '../../store/slices/common/commonSlice';

function getNewDate() {
    let tDate = new Date().toLocaleDateString().split('/');
    let newDateToday = `${tDate[2]}-${tDate[1]}-${tDate[0]}`;
    return newDateToday;
}

const Revenue = (props) => {
    let formatedDate = getNewDate();
    const [revenueStartDate, setRevenueStartDate] = useState(formatedDate);
    const [revenueEndDate, setrevenueEndDate] = useState(formatedDate);
    const [pageNumber, setpageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(10);
    const [showFilters, setShowFilters] = useState(false);
    const dispatch = useDispatch();

    const currencySymbol = printCurrencySymbol();
    const { loading, revenueData, revenueOrderList } = useSelector(irdSelector);
    useEffect(() => {
        dispatch(
            fetchRevenueData(
                revenueStartDate,
                revenueEndDate,
                pageNumber,
                pageCount
            )
        );
    }, [pageCount, pageNumber]);

    const printOrderItems = (data) => {
        return data.order_menu_items.map((item) => {
            return (
                <table className='inner_table' key={item.id}>
                    <tbody>
                        <tr>
                            <td className='booking-header '>
                                {item?.item_name} - {item.quantity} -{' '}
                                {item.price
                                    ? printCurrencySymbol() + ' ' + item.price
                                    : ''}
                            </td>
                        </tr>
                        {item.order_addons.length ? (
                            <tr>
                                <td style={{ padding: '0px' }}>
                                    <ol>
                                        {item.order_addons.map((addon) => (
                                            <li key={addon.id}>
                                                {addon.item_name}{' '}
                                                {addon?.price
                                                    ? '- ' +
                                                      printCurrencySymbol() +
                                                      ' ' +
                                                      addon?.price
                                                    : ''}
                                            </li>
                                        ))}
                                    </ol>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            );
        });
    };

    const handleClearDate = () => {
        let currentdate = getNewDate();
        setRevenueStartDate(currentdate);
        setrevenueEndDate(currentdate);
        dispatch(
            fetchRevenueData(currentdate, currentdate, pageNumber, pageCount)
        );
    };
    const handleGenerateRevenue = () => {
        dispatch(
            fetchRevenueData(
                revenueStartDate,
                revenueEndDate,
                pageNumber,
                pageCount
            )
        );
    };
    const ActionHeader = () => {
        return (
            <div className='action-header'>
                <div className='action-left'>
                    <div className='page-title'>IN ROOM DINING</div>
                    <Breadcrumb
                        items={[
                            {
                                title: <a href='/ird'>IRD</a>,
                            },
                            {
                                title: 'Revenue',
                            },
                        ]}
                    />
                </div>
                <div className='action-right'>
                    <Button
                        type='primary'
                        onClick={() =>
                            setShowFilters((prevState) => !prevState)
                        }
                    >Filter</Button>{' '}
                    <ReactHtmlTableToExcel
                        id='test-table-xls-button'
                        className='btn download-excel'
                        table='table-ird'
                        filename='tablexls'
                        sheet='tablexls'
                        buttonText='Download as XLS'
                    />
                </div>
            </div>
        );
    };
    return (
        <>
            <ActionHeader />
            <section className='order-history-wrapper  revenue_wrapper'>
                <div className='aside-right'>
                    <div className='home-order-screen'>
                        <table
                            id='table-ird'
                            className='table table-striped table-hover table-history'
                        >
                            <thead>
                                <tr>
                                    <th className='text-center'>ORDER ID</th>
                                    <th className='text-center'>GUEST</th>
                                    <th className='text-center'>GUEST NAME</th>
                                    <th className='text-center'>
                                        Order Placed Date
                                    </th>
                                    <th className='text-center'>
                                        Total Order Value
                                    </th>
                                    <th className='text-center'>Order Items</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr
                                        style={{
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        <td colspan='6'>
                                            <Loading />
                                        </td>
                                    </tr>
                                ) : revenueOrderList?.data?.length === 0 ? (
                                    <tr
                                        style={{
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        <td colspan='6'>
                                            <NoDataFound />
                                        </td>
                                    </tr>
                                ) : (
                                    revenueOrderList?.data?.map(
                                        (item, index) => {
                                            return (
                                                <tr
                                                    key={item.id}
                                                    className='cursor-point'
                                                >
                                                    <td className='text-center '>
                                                        {item.id}
                                                    </td>
                                                    <td className='text-center '>
                                                        {item.no_of_guest}
                                                    </td>
                                                    <td className='text-center '>
                                                        {item?.guest?.name ==
                                                        'adesh kkkk'
                                                            ? 'Guest'
                                                            : item?.guest?.name}
                                                    </td>
                                                    <td className='text-center '>
                                                        <Time
                                                            newDate={
                                                                item
                                                                    .order_detail
                                                                    .created_at
                                                            }
                                                            timesString={
                                                                item
                                                                    .order_detail
                                                                    .created_at
                                                            }
                                                        />
                                                    </td>
                                                    <td className='text-center '>
                                                        {' '}
                                                        {currencySymbol}{' '}
                                                        {item.revenue}
                                                    </td>
                                                    <td className='text-center '>
                                                        {printOrderItems(item)}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='pagination-container'>
                        <div className='form-group'>
                            <label>Total Orders: </label>{' '}
                            <label>
                                <b>{revenueOrderList?.total || 0}</b>
                            </label>
                        </div>
                        <div className='form-group'>
                            <label>Row per Page: </label>
                            <select
                                name='count'
                                id='count'
                                value={pageCount}
                                onChange={(e) => setPageCount(e.target.value)}
                            >
                                <option defaultValue='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='75'>75</option>
                                <option value='100'>100</option>
                                <option value='150'>150</option>
                                <option value='200'>200</option>
                                <option value='250'>250</option>
                            </select>
                        </div>
                        <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={revenueOrderList?.last_page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={(e) => {
                                setpageNumber(e.selected + 1);
                            }}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
                {showFilters && (
                    <div className='aside-left'>
                        <div className='aside_main_wrapper'>
                            <div className='dates_wrapper'>
                                <div className='form_group'>
                                    <label for='start_date'>Start Date:</label>
                                    <input
                                        type='date'
                                        id='start_date'
                                        name='start_date'
                                        value={revenueStartDate}
                                        onChange={(e) =>
                                            setRevenueStartDate(e.target.value)
                                        }
                                        defaultValue={revenueStartDate}
                                    />
                                </div>
                                <div className='form_group'>
                                    <label for='end_date'>End Date:</label>
                                    <input
                                        type='date'
                                        id='end_date'
                                        name='end_date'
                                        value={revenueEndDate}
                                        onChange={(e) =>
                                            setrevenueEndDate(e.target.value)
                                        }
                                        defaultValue={revenueEndDate}
                                    />
                                </div>
                                <div className='form_group'>
                                    <div className='btns-wrapper'>
                                        <button
                                            className='btn btn-primary'
                                            onClick={handleGenerateRevenue}
                                        >
                                            Get Revenue
                                        </button>
                                        <button
                                            className='btn btn-submit'
                                            onClick={handleClearDate}
                                        >
                                            Clear Date
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='result_revenue'>
                                <div className='result_title'>
                                    {loading ? (
                                        'loading...'
                                    ) : (
                                        <div>
                                            Your Total Orders:{' '}
                                            <span>
                                                {revenueOrderList?.total || 0}
                                            </span>
                                            Your Total Revenue:{' '}
                                            <span>
                                                {currencySymbol}{' '}
                                                {revenueData?.total_revenue?.toLocaleString() ||
                                                    0}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
};

export default Revenue;
