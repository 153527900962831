import { Breadcrumb, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Loading from '../../Components/common/Loading';
import OrderDetailsPopup from '../../Components/common/OrderDetailsPopup';
import Time from '../../Components/Times/timesOne.js';
import { printCurrencySymbol } from '../../helper/_commonHelper';
import {
    fetchOrderHistory,
    irdSelector,
} from '../../store/slices/ird/irdSlice';
import NoDataFound from '../../Components/common/NoDataFound';
import { commonSelector } from '../../store/slices/common/commonSlice';

const getMinMaxDate = (date) => {
    let d, day, month, year;
    d = new Date(date);
    day = '' + d.getDate();
    month = '' + (d.getMonth() + 1);
    year = d.getFullYear();
    if (month.length < 2) month = 0 + month;
    if (day.length < 2) day = 0 + day;
    return `${year}-${month}-${day}`;
};
function getNewDate() {
    let tDate = new Date().toLocaleDateString().split('/');
    let newDateToday = `${tDate[2]}-${tDate[1]}-${tDate[0]}`;
    return newDateToday;
}
const OrderHistory = (props) => {
    const [isModalPreview, setisModalPreview] = useState(false);
    const [previewSelectedItem, setpreviewSelectedItem] = useState(null);
    const [pageNumber, setpageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(10);
    const [showFilters, setShowFilters] = useState(false);
    const dispatch = useDispatch();
    let formatedDate = getNewDate();
    const roomRef = React.useRef();
    const nameRef = React.useRef();
    const filterStartDateRef = React.useRef();
    const filterEndDateRef = React.useRef();
    const { hotelDetails = {} } = useSelector(commonSelector);

    const { loading, orderhistory, maxPageLimit, totalOrderCount } =
        useSelector(irdSelector);

    console.log(orderhistory);
    useEffect(() => {
        // const itemListRef = React.useRef();
        let room = roomRef.current?.value;
        // let itemID = itemListRef.current?.value == "undefined" ? undefined: itemListRef.current?.value;
        let name = nameRef.current?.value;
        let filterStartDate = filterStartDateRef.current?.value;
        let filterEndDate = filterEndDateRef.current?.value;
        dispatch(
            fetchOrderHistory(
                room,
                name,
                filterStartDate,
                filterEndDate,
                pageNumber,
                pageCount
            )
        );
    }, [pageNumber, pageCount]);

    const toggleModalPreview = () => {
        setisModalPreview(!isModalPreview);
    };

    const clearFilters = () => {
        nameRef.current.value = '';
        roomRef.current.value = '';
        filterStartDateRef.current.value = '';
        filterEndDateRef.current.value = '';
        // itemListRef.current.value = undefined;
        window.location.reload();
    };
    const applyFilters = () => {
        // const itemListRef = React.useRef();
        let room = roomRef.current?.value;
        // let itemID = itemListRef.current?.value == "undefined" ? undefined: itemListRef.current?.value;
        let name = nameRef.current?.value;
        let filterStartDate = filterStartDateRef.current?.value;
        let filterEndDate = filterEndDateRef.current?.value;

        dispatch(
            fetchOrderHistory(
                room,
                name,
                filterStartDate,
                filterEndDate,
                pageNumber,
                pageCount
            )
        );
    };
    const handlePageChange = (page) => {
        console.log(page);
        setpageNumber(page);
    };
    const printOrderItems = (item) => {
        return item.order_menu_items.map((item) => {
            return (
                <table className='inner_table' key={item.id}>
                    <tbody>
                        <tr>
                            <td className='booking-header '>
                                {item?.item_name} - {item.quantity} -{' '}
                                {item.price
                                    ? printCurrencySymbol() + ' ' + item.price
                                    : ''}
                            </td>
                        </tr>
                        {item.order_addons.length ? (
                            <tr>
                                <td style={{ padding: '0px' }}>
                                    <ol>
                                        {item.order_addons.map((addon) => (
                                            <li key={addon.id}>
                                                {addon.item?.name}{' '}
                                                {addon.item?.price
                                                    ? '- ' +
                                                      printCurrencySymbol() +
                                                      ' ' +
                                                      addon.item?.price
                                                    : ''}
                                            </li>
                                        ))}
                                    </ol>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            );
        });
    };

    const ActionHeader = ({ searchValue, inputHandleChange,type }) => {
        return (
            <div className='action-header'>
               <div className='action-left'>
                <div className='page-title'>{type === 'restaurant_manager' ? 'Restaurant' :'IN ROOM DINING'}</div>
                <Breadcrumb
                    items={[
                        {
                            title: <a href='/ird'>{type === 'restaurant_manager' ? 'Restaurant ':'IRD'}</a>,
                        },
                            {
                                title: 'Order History',
                            },
                        ]}
                    />
                </div>
                <div className='action-right'>
                    {filterStartDateRef.current?.value &&
                    filterEndDateRef.current?.value ? (
                        <span className='filter-text'>
                            Showing result{' '}
                            <b>"{filterStartDateRef.current?.value}"</b> to{' '}
                            <b>"{filterEndDateRef.current?.value}"</b>
                        </span>
                    ) : null}{' '}
                    {''}
                    <Button
                        type='primary'
                        onClick={() =>
                            setShowFilters((prevState) => !prevState)
                        }
                    >
                        Filter
                    </Button>{' '}
                    <ReactHtmlTableToExcel
                        id='test-table-xls-button'
                        className='btn download-excel'
                        table='table-ird'
                        filename='tablexls'
                        sheet='tablexls'
                        buttonText='Download as XLS'
                    />
                </div>
            </div>
        );
    };
    return (
        <>
            <ActionHeader 
                type={hotelDetails.type}
            />
            <section className='order-history-wrapper'>
                <div className='aside-right'>
                    <div className='home-order-screen'>
                        {orderhistory?.length > 0 && (
                            <table
                                id='table-ird'
                                className='table table-striped table-hover table-history'
                            >
                                <thead>
                                    <tr>
                                        <th className='text-center'>
                                            {hotelDetails.type ===
                                            'restaurant_manager'
                                                ? 'TABLE NO.'
                                                : 'ROOM/VILLA NO.'}
                                        </th>
                                        <th className='text-center'>
                                            ORDER ID
                                        </th>
                                        <th className='text-center'>GUEST</th>
                                        <th className='text-center'>
                                            GUEST NAME
                                        </th>
                                        <th className='text-center'>
                                            ITEMS & QUANTITY
                                        </th>
                                        <th className='text-center'>
                                            ORDER RECEIVED TIME
                                        </th>
                                        <th className='text-center'>
                                            ORDER ACCEPTED TIME
                                        </th>
                                        <th className='text-center'>
                                            ORDER DISPATCHED TIME
                                        </th>
                                        <th className='text-center'>
                                            ORDER CLEARED TIME
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr
                                            style={{
                                                backgroundColor: 'transparent',
                                            }}
                                        >
                                            <td colspan='9'>
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : orderhistory.length === 0 ? (
                                        <tr
                                            style={{
                                                backgroundColor: 'transparent',
                                            }}
                                        >
                                            <td colspan='9'>
                                                <NoDataFound />
                                            </td>
                                        </tr>
                                    ) : (
                                        orderhistory.map((item, index) => {
                                            return (
                                                <tr
                                                    key={item.id}
                                                    className='cursor-point'
                                                    onClick={() => {
                                                        setpreviewSelectedItem(
                                                            item
                                                        );
                                                        setisModalPreview(true);
                                                    }}
                                                >
                                                    {item?.primises && (
                                                        <td className='text-center '>
                                                            <span className='type-tile'>
                                                                {item?.is_complementary ? (
                                                                    <i className='fa fa-gift'></i>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {'\u00A0'}
                                                                {
                                                                    item
                                                                        ?.primises
                                                                        ?.premise_no
                                                                }
                                                            </span>

                                                            {item.order_detail
                                                                ?.preorder_on ? (
                                                                <span className='type-tile preOrder'>
                                                                    PreOrder
                                                                </span>
                                                            ) : null}
                                                        </td>
                                                    )}
                                                    {item.table && (
                                                        <td className='text-center '>
                                                            {
                                                                item.table
                                                                    .table_no
                                                            }
                                                        </td>
                                                    )}
                                                    <td className='text-center '>
                                                        {item.id}
                                                    </td>
                                                    <td className='text-center '>
                                                        {item.no_of_guest}
                                                    </td>
                                                    <td className='text-center '>
                                                        {item?.guest?.name ==
                                                        'adesh kkkk'
                                                            ? 'Guest'
                                                            : item?.guest?.name}
                                                    </td>

                                                    <td className='text-center '>
                                                        {printOrderItems(item)}
                                                    </td>
                                                    <td className='text-center'>
                                                        <Time
                                                            newDate={
                                                                item
                                                                    .order_detail
                                                                    .created_at
                                                            }
                                                            timesString={
                                                                item
                                                                    .order_detail
                                                                    .created_at
                                                            }
                                                        />
                                                    </td>
                                                    <td className='text-center'>
                                                        <Time
                                                            newDate={
                                                                item
                                                                    .order_detail
                                                                    .accepted_at
                                                            }
                                                            timesString={
                                                                item
                                                                    .order_detail
                                                                    .accepted_at
                                                            }
                                                        />
                                                    </td>
                                                    <td className='text-center'>
                                                        <Time
                                                            newDate={
                                                                item
                                                                    .order_detail
                                                                    .dispatched_at
                                                            }
                                                            timesString={
                                                                item
                                                                    .order_detail
                                                                    .dispatched_at
                                                            }
                                                        />
                                                    </td>
                                                    <td className='text-center'>
                                                        <Time
                                                            newDate={
                                                                item
                                                                    .order_detail
                                                                    .cleared_at
                                                            }
                                                            timesString={
                                                                item
                                                                    .order_detail
                                                                    .cleared_at
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>

                    <div className='pagination-container'>
                        <div className='form-group'>
                            <label>Total Orders: </label>{' '}
                            <label>
                                <b>{totalOrderCount}</b>
                            </label>
                        </div>
                        <div className='form-group'>
                            <label>Row per Page: </label>
                            <select
                                name='count'
                                id='count'
                                value={pageCount}
                                onChange={(e) => setPageCount(e.target.value)}
                            >
                                <option defaultValue='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='75'>75</option>
                                <option value='100'>100</option>
                                <option value='150'>150</option>
                                <option value='200'>200</option>
                                <option value='250'>250</option>
                            </select>
                        </div>
                        <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={maxPageLimit}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={(e) => {
                                handlePageChange(e.selected + 1);
                            }}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>

                {showFilters && (
                    <div className='aside-left'>
                        {/* <div className="aside_title">Data Filters</div> */}
                        <div className='aside_main_wrapper'>
                            <div className='filter-type-option'>
                                <label htmlFor='start_date'>Start Date:</label>
                                <input
                                    type='date'
                                    id='start_date'
                                    name='start_date'
                                    placeholder='Enter start Date'
                                    ref={filterStartDateRef}
                                    defaultValue={formatedDate}
                                />
                            </div>
                            <div className='filter-type-option'>
                                <label htmlFor='end_date'>End Date:</label>
                                <input
                                    type='date'
                                    id='end_date'
                                    name='end_date'
                                    placeholder='Enter end Date'
                                    ref={filterEndDateRef}
                                    defaultValue={formatedDate}
                                />
                            </div>

                            <div className='filter-type-option'>
                                <label className='filtertype-label'>
                                    Search By Room/Villa/Table No
                                </label>
                                <input
                                    type='text'
                                    className='form-control search__input'
                                    placeholder='Enter room/villa no.'
                                    id='roomNo'
                                    name='roomNo'
                                    ref={roomRef}
                                />
                            </div>
                            <div className='filter-type-option'>
                                <label className='filtertype-label'>
                                    Search By Name
                                </label>
                                <input
                                    type='text'
                                    ref={nameRef}
                                    className='form-control search__input'
                                    placeholder='Enter name'
                                />
                            </div>

                            {/* <div className="filter-type-option">
            <label className="filtertype-label">Filter by Item Type</label>

            <select
              name="itemSearch"
              className="form-control search__input"
              id="itemSearch"
              form="carform"
              ref={itemListRef}
              onChange={(e) => {
                setIsFilterApplied(true);
              }}
            >
              <option value="undefined" disabled selected>
                --Select item--
              </option>
              {itemList.map((item) => (
                <option key={item.itemId} value={item.itemId}>
                  {item.itemName}
                </option>
              ))}
            </select>
          </div> */}
                            <div className='btns-wrapper'>
                                <Button
                                    type='primary'
                                    onClick={() => applyFilters()}
                                >
                                    Apply filters
                                </Button>
                                <Button
                                    type='default'
                                    onClick={() => clearFilters()}
                                >
                                    Clear filters
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {isModalPreview && (
                    <OrderDetailsPopup
                        open={isModalPreview}
                        close={toggleModalPreview}
                        item={previewSelectedItem}
                    />
                )}
            </section>
        </>
    );
};

export default OrderHistory;
