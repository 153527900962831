import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import axios from 'axios';
const connectSlice = createSlice({
    name: 'connect',
    initialState,
    reducers: {
        updateLoading(state, { payload }) {
            state.loading = payload;
        },
        updateOrderList(state, { payload }) {
            state.orderList = payload;
        },
        updateOrderHistory(state, { payload }) {
            state.orderhistory = payload;
        },
    },
});

export const connectSelector = (state) => state.connectSlice;
export const { updateLoading, updateOrderList, updateOrderHistory } =
    connectSlice.actions;

export default connectSlice.reducer;

export function fetchConnectOrderList() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.get(`connect/order`);
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateOrderList(data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

export function fetchConnectOrderHistory(url, fromDate, toDate) {
    return async (dispatch) => {
        let sQuery = '';
        if (fromDate) {
            sQuery += `date_start=${fromDate}&`;
        }
        if (toDate) {
            sQuery += `date_end=${toDate}`;
        }
        try {
            dispatch(updateLoading(true));
            const response = await axios.get(`${url}?${sQuery}`);
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateOrderHistory(data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
