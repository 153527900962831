import React, { useState } from 'react';
import { createNewFeed, updateFeed } from '../../services/apiService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { commonSelector } from '../../store/slices/common/commonSlice';
import { useSelector } from 'react-redux';
import AddEditFeedTranslation from './AddEditFeedTranslation';

import { convertJSONtoFormData } from '../../helper/_commonHelper';

const CreateFeed = (props) => {
    const {
        onnewFeedSuccess,
        closeFeed,
        description,
        is_pinned,
        interest_action_btn_enabled,
        interest_action_btn_text,
        interest_action_response,
        isEditMode = false,
        feedUpdateSucess,
        isEdit,
        translations
    } = props;
    const { hotelDetails } = useSelector(commonSelector);
    const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [feedData, setFeedData] = useState({
        hotel_id: hotelDetails.hotel_id,
        description: description ?? '',
        is_pinned: is_pinned ?? false,
        interest_action_btn_enabled: interest_action_btn_enabled ?? false,
        interest_action_btn_text: interest_action_btn_text ?? 'Get Offer',
        interest_action_response: interest_action_response ?? '',
        translations: translations ?? []
    });

    const [rows ,setRows]=useState([]);

    const handleChange = (e) => {
        const name = e.target.name;
        let value = null;
        if (e.target.type === 'checkbox') {
            value = e.target.checked ===true ? 1 : 0 ;
        } else {
            value = e.target.value;
        }
        setFeedData({ ...feedData, [name]: value });
    };

    const handleAddTranslationClick = () => {
        setIsTranslationModalOpen(true);
    };

    const closeTranslationModal = () => {
        setIsTranslationModalOpen(false);
    };

    const createFeed = async () => {
        try {
            setLoading(true);
            let updatedRows=rows.map((row)=>({
                hotel_language_id:row.hotel_language_id,
                description:row.description,
                interest_action_btn_text:row.interest_action_btn_text,
                interest_action_response:row.interest_action_response,           
              }))

            const dataToSend = convertJSONtoFormData({
                ...feedData,
                translations: updatedRows,
                is_pinned: feedData.is_pinned ? 1 : 0,
                interest_action_btn_enabled: feedData.interest_action_btn_enabled ? 1 : 0
            });
            const response = await createNewFeed(dataToSend);
            if (response.status === 201) {
                window.alert('Feed created successfully');
                onnewFeedSuccess();
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };
    const updateFeedHandler = async () => {
        try {
            setLoading(true);
            let updatedRows=rows.map((row)=>({
                hotel_language_id:row.hotel_language_id,
                description:row.description,
                interest_action_btn_text:row.interest_action_btn_text,
                interest_action_response:row.interest_action_response,           
              }))

            const dataToSend = convertJSONtoFormData({
                ...feedData,
                translations: updatedRows,
                is_pinned: feedData.is_pinned ? 1 : 0,
                interest_action_btn_enabled: feedData.interest_action_btn_enabled ? 1 : 0
            });
            const response = await updateFeed(isEdit, dataToSend);
            if (response.status === 200) {
                window.alert('Feed cupdated successfully');
                feedUpdateSucess();
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className='createfeed-container'>
            <div className='form-group'>
                <label className='control-label'>Feed description</label>
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: {
                            items: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                '|',
                                'link',
                                '|',
                                'undo',
                                'redo',
                            ],
                        },
                    }}
                    data={feedData.description}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setFeedData({ ...feedData, description: data });
                    }}
                />
            </div>

            <div className='form-group'>
                <input
                    type='checkbox'
                    name='is_pinned'
                    checked={feedData.is_pinned}
                    onChange={handleChange}
                />{' '}
                <label className='control-label'>Pin This Feed</label>
            </div>
            <div className='form-group'>
                <input
                    type='checkbox'
                    name='interest_action_btn_enabled'
                    checked={feedData.interest_action_btn_enabled}
                    onChange={handleChange}
                />{' '}
                <label className='control-label'>
                    Enable Get Offer Action Button
                </label>
            </div>
            <div className='form-group'>
            <button className='btn btn-primary' onClick={handleAddTranslationClick}>
                {loading ? (
                    <i className='fa fa-spinner fa-spin'></i>
                ) : (
                    ''
                )}{' '}
                Add New Translation
                </button>
            </div>

            <AddEditFeedTranslation 
                translations={feedData.translations} 
                rows={rows} 
                setRows={setRows}
                isOpen={isTranslationModalOpen}
                onClose={closeTranslationModal}
            />
            {Boolean(feedData.interest_action_btn_enabled) && (
                <>
                    <div className='form-group'>
                        <label className='control-label'>
                            Enter the action button text
                        </label>
                        <input
                            className='form-control'
                            type='text'
                            name='interest_action_btn_text'
                            value={feedData.interest_action_btn_text}
                            onChange={handleChange}
                            placeholder='Enter button text'
                        />{' '}
                    </div>
                    <div className='form-group'>
                        <label className='control-label'>
                            Enter thank you message after submission from user.
                        </label>
                        <input
                            className='form-control'
                            type='text'
                            name='interest_action_response'
                            value={feedData.interest_action_response}
                            onChange={handleChange}
                            placeholder='Enter thank you message after submission from user.'
                        />{' '}
                    </div>
                </>
            )}
            {/* <div className='form-group'>
                <label className='control-label'>Feed Published Date</label>
                <input
                    type='datetime-local'
                    name='published_on'
                    value={feedData.published_on}
                    className='form-control'
                    onChange={handleChange}
                    step='1'
                />
            </div>
            <div className='form-group'>
                <label className='control-label'>Feed Expired Date </label>
                <input
                    type='datetime-local'
                    name='expired_on'
                    value={feedData.expired_on}
                    className='form-control'
                    onChange={handleChange}
                    step='1'
                />
            </div> */}
            {isEditMode ? (
                <>
                    <button
                        className='btn btn-primary'
                        onClick={() => updateFeedHandler()}
                    >
                        {loading ? (
                            <i className='fa fa-spinner fa-spin'></i>
                        ) : (
                            ''
                        )}
                        Update Feed
                    </button>
                    <button className='btn btn-close' onClick={closeFeed}>
                        Close
                    </button>
                </>
            ) : (
                <>
                    <button className='btn btn-primary' onClick={createFeed}>
                        {loading ? (
                            <i className='fa fa-spinner fa-spin'></i>
                        ) : (
                            ''
                        )}{' '}
                        Create Feed
                    </button>
                    <button className='btn btn-close' onClick={closeFeed}>
                        Close
                    </button>
                </>
            )}
        </div>
    );
};

export default CreateFeed;
