import React, { useState } from "react";
import Loading from "../../../Components/common/Loading";
import OrderCard from "../../../Components/common/OrderCard";
import OrderDetailsPopup from "../../../Components/common/OrderDetailsPopup";
import TransitionLoader from "../../../Components/common/TransitionLoader";
import AppConstant from "../../../constant/AppConstant";
import NotificationBadge from "../../../Shared/NotificationBadge";
const NewOrder = (props) => {
  const [showTransitionLoader, setShowTransitionLoader] = useState(false);
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [detailedViewItem, setDetailedViewItem] = useState({});
  const { data, loading, hasNotification, updateOrderByType } = props;

  const handleClosePopup = () => {
    setShowItemPopup(false);
    setDetailedViewItem({});
  };
  const handleShowPopup = (item) => {
    setShowItemPopup(true);
    setDetailedViewItem(item);
  };

  const handleAcceptSave = async (item) => {
    if (window.confirm("Are you sure want to accept this order?")) {
      let option = {
        method: "PUT",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("user"),
        },
      };

      try {
        setShowTransitionLoader(true);

        let spaOrderAcceptData = await fetch(
          AppConstant.config.appBaseUrl +
            "/connect/order/accept/" +
            item.order_detail.order_id,
          option
        );
        spaOrderAcceptData = await spaOrderAcceptData.json();
        console.log(spaOrderAcceptData)
        setTimeout(() => {
          setShowTransitionLoader(false);
        }, 2000);
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <>
      <section className="order-list-container">
        {hasNotification && (
          <NotificationBadge
            type={"connect"}
            updateOrderByType={updateOrderByType}
          />
        )}

        {loading ? (
          <Loading />
        ) : data.length === 0 ? (
          <div className="col-md-12">
            <div
              className=""
              style={{
                margin: "25vh auto",
                textAlign: "center",
                fontSize: "30px",
                color: "#8e8a8a",
              }}
            >
              <h4>No New Order</h4>
            </div>
          </div>
        ) : (
            <div className="home-screen">
              {data.length > 0 &&
                data.map((item) => {
                  return (
                    <OrderCard
                      tabType={"NewOrder"}
                      ServiceName={"PRM"}
                      OrderType={"New Order"}
                      key={item.id}
                      item={item}
                      viewDetails={() => handleShowPopup(item)}
                      footerBtn={() => handleAcceptSave(item)}
                      btnTitle={"Accept"}
                      timerTime={item?.order_detail?.created_at}
                    />
                  );
                })}
            </div>
        )}
        {showItemPopup && (
          <OrderDetailsPopup
            open={showItemPopup}
            close={handleClosePopup}
            item={detailedViewItem}
          />
        )}
        {showTransitionLoader && (
          <TransitionLoader previousStage={"New Order"} newStage={"Accepted"} />
        )}
      </section>
    </>
  );
};


export default NewOrder