import { configureStore } from '@reduxjs/toolkit';
import irdSlice from './slices/ird/irdSlice';
import spaSlice from './slices/spa/spaSlice';
import laundrySlice from './slices/laundry/laundrySlice';
import bookingSlice from './slices/booking/bookingSlice';
import connectSlice from './slices/connect/connectSlice';
import commonSlice from './slices/common/commonSlice';
import thunk from 'redux-thunk';

const store = configureStore({
    reducer: {
        irdSlice,
        spaSlice,
        laundrySlice,
        bookingSlice,
        connectSlice,
        commonSlice,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: [thunk],
    },
});

export default store;
