import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import axios from 'axios';
const laundrySlice = createSlice({
    name: 'laundry',
    initialState,
    reducers: {
        updateLoading(state, { payload }) {
            state.loading = payload;
        },
        updateOrderList(state, { payload }) {
            state.orderList = payload;
        },
        updateMenuList(state, { payload }) {
            state.menuList = payload;
        },
        updateMessage(state, { payload }) {
            state.message = payload;
        },
        updateOrderHistory(state, { payload }) {
            state.orderhistory = payload;
        },
    },
});

export const laundrySelector = (state) => state.laundrySlice;
export const { updateLoading, updateOrderList, updateMenuList, updateMessage,updateOrderHistory } =
    laundrySlice.actions;

export default laundrySlice.reducer;

export function fetchLaundryOrderList() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.get('wash/order');
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateOrderList(data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

export function fetchLaundryMenuList() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.get('wash/laundry');
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateMenuList([data]));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
export function enableDisableLaundryMenuList(url, id) {
    return async (dispatch) => {
        try {
            console.log('adesh');
            dispatch(updateLoading(true));
            const response = await axios.put(`${url}/${id}`);
            if (response.status === 202) {
                const data = response.data.data || [];

                dispatch(fetchLaundryMenuList());
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}


export function fetchLaundryOrderHistory(url, fromDate, toDate) {
    return async (dispatch) => {
        try {
            let sQuery = '';
            if (fromDate) {
                sQuery += `date_start=${fromDate}&`;
            }
            if (toDate) {
                sQuery += `date_end=${toDate}`;
            }
            dispatch(updateLoading(true));
            const response = await axios.get(`${url}?${sQuery}`);
            if (response.status === 200) {
                const data = response.data.data || [];
                dispatch(updateOrderHistory(data));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
