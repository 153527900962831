import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../helper/_cookieHelper";

const RequireAuth = (props) => {
  const { children } = props;
  let location = useLocation();
  let authDataSerialized = getCookie("token");
//   let authDataSerialized = localStorage.getItem('user');

  if (!authDataSerialized) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default RequireAuth;
