import { Button } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCookie } from '../helper/_cookieHelper';
import { getLastOrderIds, loginUser } from '../services/apiService';
import { irdSelector } from '../store/slices/ird/irdSlice';
import { notification } from 'antd';

const Login = (props) => {
    const { deviceToken } = useSelector(irdSelector);
    const [loginData, setLoginData] = useState({
        username: '',
        password: '',
        device_token: deviceToken,
        device_type: 'web',
    });
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    let host = window.location.host || '';
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, title = '', msg = '') => {
        api[type]({
            message: title,
            description: msg,
        });
    };

    const removeAndRedirectToLogin = () => {
        let keysToRemove = [
            'user',
            'user_type',
            '__lastOrders',
            'enc_hotel_id',
        ];
        for (let key of keysToRemove) {
            localStorage.removeItem(key);
        }
        navigate('/login', { replace: true });
        return;
    };
    const handleLoginClick = async () => {
        if (!loginData.username || !loginData.username.trim()) {
            openNotificationWithIcon(
                'error',
                'Username is required!',
                'Please enter a valid username!'
            );
            return;
        }
        if (!loginData.password || !loginData.password.trim()) {
            openNotificationWithIcon(
                'error',
                'Password is required!',
                'Please enter a valid password!'
            );

            return;
        }
        try {
            setLoading(true);
            if (deviceToken) {
                loginData['device_token'] = deviceToken;
            }
            const response = await loginUser(JSON.stringify(loginData));
            if (response.status === 200) {
                const data = response.data || {};
                localStorage.setItem('user', data?.access_token);
                setCookie('token', `${data?.access_token}`, 1);
                localStorage.setItem('enc_hotel_id', data?.enc_hotel_id);
                localStorage.setItem('user_type', data?.user_type);
                localStorage.setItem(
                    'hotel',
                    JSON.stringify(data?.hotel, undefined, 2)
                );
                const lastOrderResponse = await getLastOrderIds();
                if (lastOrderResponse.status === 200) {
                    const orderData = lastOrderResponse.data.data || {};
                    localStorage.setItem(
                        '__lastOrders',
                        JSON.stringify(orderData)
                    );
                }
                // handleRedirect(data?.user_type);
                if (
                    data?.user_type === 'hotel_admin' ||
                    data?.user_type === 'ird_manager' ||
                    data?.user_type === 'restaurant_manager'
                ) {
                    navigate('/ird', { replace: true });
                    return;
                } else if (data?.user_type === 'spa_manager') {
                    navigate('/spa', { replace: true });
                    return;
                } else if (data?.user_type === 'connect_manager') {
                    navigate('/connect', { replace: true });
                    return;
                } else if (data?.user_type === 'laundry_manager') {
                    navigate('/laundry', { replace: true });
                    return;
                } else if (data?.user_type === 'feed_manager') {
                    navigate('/feed', { replace: true });
                    return;
                } else if (data?.user_type === 'booking_manager') {
                    navigate('/booking', { replace: true });
                    return;
                } else {
                    removeAndRedirectToLogin();
                }
            }
        } catch (error) {
            console.log(error);
            // showToast("error", "Something went wrong!! please try again.");
            openNotificationWithIcon(
                'error',
                '',
                'Something went wrong!! Please try again.'
            );
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLoginData({ ...loginData, [name]: value });
    };
    return (
        <div className='login-page'>
            {contextHolder}
            <div className='login-wrapper'>
                <div className='app-logo ' />
                <div className='login-box'>
                    <div className='login-title text-center'>
                        <div className='title'>Welcome back</div>
                        <div className='subtitle'>
                            Please sign in to access your orders
                        </div>
                    </div>
                    <input
                        type='text'
                        name='username'
                        className='form-control'
                        placeholder='Enter username'
                        value={loginData.username}
                        onChange={(e) => handleInputChange(e)}
                    />
                    <input
                        type='password'
                        name='password'
                        className='form-control'
                        placeholder='Enter Password'
                        value={loginData.password}
                        onChange={(e) => handleInputChange(e)}
                    />
                    <Button
                        type='primary'
                        onClick={handleLoginClick}
                        disabled={loading}
                    >
                        {loading && (
                            <i className='fa fa-circle-o-notch fa-spin'></i>
                        )}{' '}
                        Login
                    </Button>
                    {window.location.origin ===
                    'https://mi-console.vserve-protecht.com' ? (
                        <>
                            <div className='or'>OR</div>
                            <Button
                                href='https://vserve-api.valet2you.in/saml2/mpf/login'
                                type='link'
                            >
                                Continue with SSO
                            </Button>
                        </>
                    ) : (
                        ''
                    )}
                </div>
                <div
                    className='text-center text-white opacity-8 mt-3'
                    style={{ textAlign: 'center' }}
                >
                    Copyright &copy; ViralOps 2022
                </div>
            </div>
        </div>
    );
};

export default Login;

// <div className="login-page">
//   <div className="login-wrapper">
//     <div className="logo-header">
//       <img
//         src="https://master-vserve.s3.ap-south-1.amazonaws.com/property/applogo.png"
//         alt="viralopslogo"
//       />
//     </div>
//     <div className="login-wrap">
//       <div className="login-title">
//         Login into <span>Hotel Dashboard</span>
//       </div>
//       <p className="login-subtitle">Manage your Orders Smartly.</p>
//       <div className="form-group">
//         <input
//           type="text"
//           id="username"
//           className={classnames(
//             state.errors.username !== ""
//               ? "form-control is-invalid"
//               : "form-control "
//           )}
//           name="username"
//           placeholder="Username"
//           onChange={handleChange}
//           value={state.username}
//         />
//         {state.errors.username !== "" && (
//           <div className="invalid-feedback">{state.errors.username}</div>
//         )}
//       </div>
//       <div className="form-group">
//         <input
//           type="password"
//           id="password"
//           className={classnames(
//             state.errors.password !== ""
//               ? "form-control is-invalid"
//               : "form-control"
//           )}
//           name="password"
//           onChange={handleChange}
//           placeholder="Password"
//           value={state.password}
//         />
//         {state.errors.password !== "" && (
//           <div className="invalid-feedback">{state.errors.password}</div>
//         )}
//       </div>
//       {state.authenticationError !== "" && (
//         <div className="error-msg">
//           <i className="fa fa-exclamation-triangle"></i>
//           {"  "}
//           {state.authenticationError != "Unauthorized"
//             ? state.authenticationError
//             : "Invalid Username and Password"}
//         </div>
//       )}
//       <button
//         className="btn btn-login btn-primary"
//         onClick={handleLogin}
//         disabled={state.loading}
//       >
//         {state.loading ? (
//           <>
//             <i className="fa fa-refresh fa-spin"></i> Loading
//           </>
//         ) : (
//           "Login"
//         )}
//       </button>
//       {!authState ? (
//         <div>Loading authentication...</div>
//       ) : !authState.isAuthenticated ? (
//         <div>
//           <button onClick={login}>Login</button>
//         </div>
//       ) : (
//         "You authenticated bitch!"
//       )}
//     </div>
//   </div>
//   <div className="lottie-wrapper">
//     <div className="player-box">
//       <Player
//         autoplay
//         loop
//         src="https://assets8.lottiefiles.com/packages/lf20_jmd7aruv.json"
//         style={{ height: "500px", width: "500px" }}
//       >
//         <Controls
//           visible={false}
//           buttons={["play", "repeat", "frame", "debug"]}
//         />
//       </Player>
//     </div>
//   </div>
// </div>
