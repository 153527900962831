import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import moment from 'moment';

const Timer = ({ countDown, key }) => {
    const [cTime, setTime] = useState(null);
    const Tim = () => {
        // var orderTime = moment.utc(countDown).local(); // another date
        // var nowTime = moment.utc(new Date()).local(); //todays date
        // const diff = nowTime.diff(orderTime)
        // const duration = moment.duration(diff);
        // const diffDays = duration.days();

        // console.log({countDown,diff,diffDays});
        // console.log('----------------------------------------------------------------');
        // let h = duration.hours(),
        //     m = duration.minutes(),
        //     s = duration.seconds();

        // h = h > 9 ? h : `0${h}`;
        // m = m > 9 ? m : `0${m}`;
        // s = s > 9 ? s : `0${s}`;
        // if (diffDays > 1) {
        //     const d = `${diffDays} days ago ${h}:${m}:${s}`;
        //     setTime(d);
        // } else {
        //     const d = `${h}:${m}:${s}`;
        //     setTime(d);
        // }
        let now = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
        let then = moment(new Date(countDown)).format("DD/MM/YYYY HH:mm:ss");

        let duration = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"));
        // var da = moment.utc(duration).format("M");
        // var hrs = moment.utc(duration).format("HH");
        // var min = moment.utc(duration).format("mm");
        // var sec = moment.utc(duration).format("ss");
        // setTime([da,hrs, min, sec].join(':'));
        var d = moment.duration(duration);
        var s = Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
        setTime(s);
    };

    useEffect(() => {
        let myInterval = setInterval(Tim, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [countDown]);

    return <Fragment>{cTime}</Fragment>;
};

export default memo(Timer);
