const appBaseUrl =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? 'https://vserve-api.valet2you.in/'
        : 'https://test-vserve-api.valet2you.in/';

const apiUrlV5_0_1 = `${appBaseUrl}api/v5.0.1`;
const apiUrlV5_0_3 = `${appBaseUrl}api/v5.0.3`;

const imgUrl =
    'https://master-vserve.s3.ap-south-1.amazonaws.com/guest-signature';

export default {
    config: {
        appBaseUrl: apiUrlV5_0_1,
        appBaseUrlV5_0_3: apiUrlV5_0_3,
        imgUrl,
    },
};
