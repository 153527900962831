import React, { useState } from 'react';
import { createNewFeedContent } from '../../services/apiService';
import { serialize } from 'object-to-formdata';
const CreateFeedContent = ({
    feedId,
    onFeedContentSuccess,
    addFeedClick,
    closeClickHandler,
}) => {
    const [loading, setLoading] = useState(false);
    const [feedContentData, setFeedContentData] = useState({
        feed_id: feedId,
        content_type: 'image',
        content_src: undefined,
    });
    const checkFileIsImage = (file) => {
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        return acceptedImageTypes.includes(file['type']);
    };
    const handleChange = (e) => {
        const name = e.target.name;
        let value = null;
        if (e.target.type === 'file') {
            if (
                feedContentData.content_type === 'image' &&
                checkFileIsImage(e.target.files[0])
            ) {
                value = e.target.files[0];
            } else if (
                feedContentData.content_type === 'video' &&
                !checkFileIsImage(e.target.files[0])
            ) {
                value = e.target.files[0];
            }else{
                window.alert(`Selected content type is ${feedContentData.content_type}, Please upload a valid ${feedContentData.content_type}.`)
                return 
            }
        } else {
            value = e.target.value;
        }
        setFeedContentData({ ...feedContentData, [name]: value });
    };
    const createFeedContent = async () => {
        if (!feedContentData.content_type || !feedContentData.content_src)
            return;
        try {
            setLoading(true);
            const response = await createNewFeedContent(
                serialize(feedContentData)
            );
            if (response.status === 201) {
                window.alert('Feed content created successfully');
                addFeedClick(feedId);
                onFeedContentSuccess();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    console.log(feedContentData)
    return (
        <div className='createfeed-container'>
            <div className='form-group'>
                <label>Content Type: </label>
                <select
                    name='content_type'
                    id='content_type'
                    value={feedContentData.content_type}
                    onChange={(e) => handleChange(e)}
                >
                    <option defaultValue='image' value='image'>Image</option>
                    <option value='video'>Video</option>
                </select>
            </div>
            <div className='form-group'>
                <label className='control-label'>
                    Content file{' '}
                    <small>
                        {feedContentData.content_type === 'image'
                            ? 'Image size should be less then 1MB'
                            : 'Video size should be less then 20MB'}
                    </small>
                </label>
                <input
                    type='file'
                    className='form-control'
                    placeholder='content_src'
                    name='content_src'
                    onChange={handleChange}
                />
            </div>
            <button className='btn btn-primary' onClick={createFeedContent}>
                {loading ? <i className='fa fa-spinner fa-spin'></i> : ''}{' '}
                Upload
            </button>
            <button className='btn btn-close' onClick={closeClickHandler}>
                Close
            </button>
        </div>
    );
};

export default CreateFeedContent;
