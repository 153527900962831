import React, { useEffect, useState } from 'react';
import AppConstant from '../../../constant/AppConstant.js';
import { Select } from 'antd';

import { getUrl } from './service';
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { commonSelector } from '../../../store/slices/common/commonSlice.js';
// import RecommendedItems from './RecommendedItems.js';
import AddEditTranslations from './AddEditTranslations.js';
import { convertJSONtoFormData } from '../../../helper/_commonHelper.js';
import { getMenuTaxes } from '../../../services/apiService.js';
import Multiselect from 'multiselect-react-dropdown';

const fomatedDropdownOptions = (arr = []) => {
    const options = [];
    for (let i = 0; i < arr.length; i++) {
        options.push({
            value: arr[i],
            label: arr[i],
        });
    }
    return options;
};
const AddEditItem = ({
    title,
    data,
    ids,
    setShow,
    refresh,
    setSuccess,
    taxesList,
    open,
    close,
}) => {
    let tagsD = data?.tags ? JSON.parse(data?.tags) : [];
    const tagsData = fomatedDropdownOptions(tagsD || []);
    const [errors, setError] = useState(null);
    const [selectedTag, setSelectedTag] = useState(tagsData || []);

    const [selectedTaxes, setSelectedTaxes] = useState([]);

    const [subAddonList, setSubAddonList] = useState([]);
    const [selectedSubaddonIdList, setSelectedSubaddonIdList] = useState([]);
    const [subAddonSearch, setSubAddonSearch] = useState('');
    const { hotelDetails = {} } = useSelector(commonSelector);
    const [rows, setRows] = useState([]);
    // let oldID = data?.recommended_item_ids ? JSON.parse(data?.recommended_item_ids) :[];
    // console.log(selectedTag);

    const [formData, setFormData] = useState({
        menu_id: ids?.menuId,
        menu_category_id: ids?.categoryId,
        menu_sub_category_id: ids?.subCategoryId,
        name: data?.name || '',
        description: data?.description,
        type: data?.type || 'veg',
        thumbnail: data?.thumbnail || undefined,
        priority: data?.priority || 0,
        price: data?.price || 0,
        max_per_order: data?.max_per_order || 10,
        min_per_order: data?.min_per_order || 0,
        taxes: data?.taxes,
        display_type: data?.display_type || 'no_thumbnail',
        tags: [],
        subaddons: selectedSubaddonIdList || [],
        is_healthy: Number(data?.is_healthy) ?? 0,
        translations: data?.translations || [],
        // recommended_item_ids: data?.recommended_items || [],
    });

    console.log(data);
    useEffect(() => {
        if (
            ids?.menuId &&
            !data?.id &&
            hotelDetails.type !== 'restaurant_manager'
        ) {
            getAllSubAddonList(ids?.menuId);
        }
    }, [ids?.menuId, data?.id]);

    useEffect(() => {
        if (ids) {
            getAllTaxes(ids);
        }
    }, [ids]);

    const getAllTaxes = async ({ itemId }) => {
        if (itemId) {
            try {
                const response = await getMenuTaxes(itemId);
                if (response.status === 200) {
                    // subcategories data
                    const taxList = response.data.data || [];
                    const alltaxList = taxList?.map(({ tax }) => ({
                        id: tax.id,
                        name: tax.name,
                    }));

                    if (alltaxList?.length) {
                        const selectedTaxList = taxesList?.filter(
                            ({ id: id1 }) =>
                                alltaxList.some(({ id: id2 }) => id2 === id1)
                        );
                        setFormData({
                            ...formData,
                            taxes: selectedTaxList?.map((x) => x.id),
                        });
                        setSelectedTaxes(selectedTaxList);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleChange = (evt) => {
        let value = null;
        if (evt.target.type === 'checkbox') {
            if (evt.target.name === 'is_healthy') {
                value = Number(evt.target.checked);
            } else {
                value = evt.target.checked;
            }
        } else if (evt.target.type === 'file') {
            value = evt.target.files[0];
        } else {
            value = evt.target.value;
        }
        setFormData({
            ...formData,
            [evt.target.name]: value,
        });
    };

    const saveForm = async () => {
        let form = formData;

        if (data?.id) {
            if (formData.thumbnail && typeof formData.thumbnail !== 'object') {
                delete form?.thumbnail;
            }
        }
        // if (!form?.menu_sub_category_id) {
        //   delete form?.menu_sub_category_id;
        // }
        // form = {
        //     ...form,
        //     recommended_item_ids: form.recommended_item_ids.map((i) => i.id),
        // };

        try {
            let updatedRows = rows.map((row) => ({
                hotel_language_id: row.hotel_language_id,
                name: row.name,
                description: row.description,
                desclaimer: row.desclaimer,
                tags: row.tags,
            }));
            formData['translations'] = updatedRows;
            formData['tags'] = JSON.stringify(
                selectedTag.map((tag) => tag.value)
            );
            formData['taxes'] = selectedTaxes.map((tax) => tax.id);
            const datatoSend = convertJSONtoFormData(formData);
            let option = {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    //   'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: 'Bearer ' + localStorage.getItem('user'),
                },
                body: datatoSend,
            };

            let response = await fetch(
                AppConstant.config.appBaseUrl +
                    getUrl() +
                    (data?.id ? '/update/menu/category/item' : '/menu/item') +
                    (data?.id ? '/' + data?.id : ''),
                option
            );
            let result = await response.json();

            if (result?.message && response.status === 400) {
                const keys = Object.keys(result?.message);
                let errs = keys?.map((key) => {
                    return {
                        field: key,
                        error: result?.message?.[key]?.[0],
                    };
                });
                setError(errs);
            } else {
                if (response.ok) {
                    setSuccess(result.message);
                    refresh();
                    setShow(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submit = async () => {
        await saveForm();
    };

    const getAllSubAddonList = async (id) => {
        let option = {
            method: 'GET',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('user'),
            },
        };
        try {
            const subaddonPromise = await fetch(
                AppConstant.config.appBaseUrl +
                    getUrl() +
                    `/menu/all/subaddon/${id}`,
                option
            );
            const { data } = await subaddonPromise.json();
            if (data?.length) {
                setSubAddonList(data);
            }
        } catch (error) {}
    };
    const subAddonSelection = (e, subAddonID) => {
        const { checked } = e.target;
        let subaddonslist = [];
        if (checked) {
            subaddonslist = [...selectedSubaddonIdList, subAddonID];
        } else if (!checked) {
            subaddonslist = selectedSubaddonIdList.filter(
                (id) => id !== subAddonID
            );
        }
        setSelectedSubaddonIdList(subaddonslist);
        setFormData({
            ...formData,
            subaddons: subaddonslist,
        });
    };

    const deleteImage = async () => {
        if (window.confirm('Do you want to delete this image?') == false) {
            return;
        }
        try {
            const payload = {
                entity_type: 'menu_item',
                entity_id: data?.id,
            };
            const response = await axios.delete('/image/thumbnail', {
                data: payload,
            });
            if (response.status === 200) {
                setFormData({
                    ...formData,
                    thumbnail: undefined,
                });
            }
        } catch (error) {}
    };
    const handleTagChange = (value) => {
        const tagsData = fomatedDropdownOptions(value || []) || [];
        setSelectedTag(tagsData);
    };
    console.log(taxesList);
    const options = [];
    for (let i = 0; i < 100000; i++) {
        const value = `${i.toString(36)}${i}`;
        options.push({
            label: value,
            value,
            disabled: i === 10,
        });
    }

    const onSelect = (selectedList, selectedItem) => {
        setFormData({
            ...formData,
            taxes: selectedList?.map((x) => x.id),
        });
        setSelectedTaxes(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setFormData({
            ...formData,
            taxes: selectedList
                ?.filter((e) => e.id !== removedItem?.id)
                ?.map((x) => x.id),
        });
        setSelectedTaxes(selectedList);
    };
    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setShow(false)}
            className={'modal-dialog modal-lg modal-addEdit-item'}
            overlayClassName={'modal-backdrop overflow-auto '}
            contentLabel='Example Modal'
        >
            <div
                className='modal-content'
                aria-modal='true'
                id='modal-content-block'
            >
                <div className='modal-header'>
                    <div className='title'>
                        {data?.id ? 'Update ' + title : 'Add ' + title}
                    </div>

                    <button
                        className='btn btn-icon'
                        onClick={() => setShow(false)}
                    >
                        <i className='fa fa-times'></i>
                    </button>
                </div>

                <div className='modal-body'>
                    {/* FORM */}
                    <div className='row'>
                        <div className='col-md-9 form-group'>
                            <label htmlFor='exampleInputEmail1'>Name</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Name'
                                name='name'
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-3 form-group'>
                            <label htmlFor='exampleInputEmail1'>Type</label>
                            <select
                                className='form-control'
                                value={formData.type}
                                onChange={(e) => handleChange(e)}
                                name='type'
                            >
                                <option value={'veg'}>Vegeterian</option>
                                <option value={'non_veg'}>
                                    Non Vegeterian
                                </option>
                                <option value={'alcohol'}>Alcohol</option>
                                <option value={'non_alcohol_bevrages'}>
                                    Non Alcohol
                                </option>
                            </select>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-9 form-group'>
                            <label htmlFor='exampleInputEmail1'>
                                Description
                            </label>
                            <textarea
                                type='text'
                                className='form-control'
                                rows='5'
                                cols='10'
                                placeholder='Description'
                                name='description'
                                value={formData.description}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <div className='col-md-3 form-group'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label htmlFor='exampleInputEmail1'>
                                        Image
                                    </label>
                                    <input
                                        type='file'
                                        className='form-control input-file'
                                        placeholder='thumbnail'
                                        name='thumbnail'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-12'>
                                    {formData?.thumbnail &&
                                        typeof formData?.thumbnail !=
                                            'object' && (
                                            <div className='image-wrapper'>
                                                <img
                                                    src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/${encodeURIComponent(data.thumbnail)}`}
                                                    alt='category'
                                                    className='image'
                                                    style={{
                                                        width: '210px',
                                                        height: ' 90px',
                                                        borderRadius: '8px',
                                                        objectFit: 'cover',
                                                        marginTop: '0.5rem',
                                                    }}
                                                />
                                                <button
                                                    className='btn btn-icon btn-danger'
                                                    onClick={deleteImage}
                                                >
                                                    <i className='fa fa-trash'></i>
                                                </button>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-3 form-group'>
                            <label htmlFor='exampleInputEmail1'>
                                Image display type
                            </label>
                            <select
                                className='form-control'
                                value={formData.display_type}
                                onChange={(e) => handleChange(e)}
                                name='display_type'
                            >
                                <option value={'big_thumbnail'}>
                                    Big Image
                                </option>
                                <option value={'small_thumbnail'}>
                                    Small image
                                </option>
                                <option value={'no_thumbnail'}>No Image</option>
                            </select>
                        </div>
                        <div className='col-md-2 form-group'>
                            <label htmlFor='exampleInputEmail1'>Price</label>
                            <input
                                type='number'
                                className='form-control'
                                placeholder='Price'
                                name='price'
                                value={formData.price}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-1 form-group'>
                            <label htmlFor='exampleInputEmail1'>Priority</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Priority'
                                name='priority'
                                value={formData.priority}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-3 form-group'>
                            <label htmlFor='exampleInputEmail1'>
                                Mininum Per Order
                            </label>
                            <input
                                type='number'
                                className='form-control'
                                placeholder='min_per_order'
                                name='min_per_order'
                                value={formData.min_per_order}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-3 form-group'>
                            <label htmlFor='exampleInputEmail1'>
                                Maximun quantity{' '}
                            </label>
                            <input
                                type='number'
                                className='form-control'
                                placeholder='Choose a number greater than 0'
                                name='max_per_order'
                                value={formData.max_per_order}
                                onChange={handleChange}
                            />
                            <label htmlFor='exampleInputEmail1'>
                                <small style={{ fontSize: '9px' }}>
                                    Max qty of this item that a guest can order.
                                </small>
                            </label>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-4 form-group'>
                            <label htmlFor='exampleInputEmail1'>
                                Tags{' '}
                                <small style={{ fontSize: '10px' }}>
                                    (Add tag and press Enter)
                                </small>
                            </label>
                            {/* <Select
                                multi
                                create
                                // options={tagList}
                                values={tagList}
                                placeholder='Type and press Enter...'
                                addPlaceholder='Type and press Enter...'
                                dropdownHandle={false}
                                onChange={(value) => {
                                    setmultitags(value);
                                }}
                            /> */}
                            <Select
                                mode='tags'
                                style={{
                                    width: '100%',
                                    color: 'red',
                                }}
                                placeholder='Tags Mode'
                                onChange={handleTagChange}
                                options={selectedTag}
                                value={selectedTag}
                            />
                        </div>

                        <div className='col-md-8 form-group'>
                            <label htmlFor='exampleInputEmail1'>Taxes</label>
                            <Multiselect
                                options={taxesList} // Options to display in the dropdown
                                selectedValues={selectedTaxes} // Preselected value to persist in dropdown
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                displayValue='name' // Property name to display in the dropdown options
                            />
                            {/* <Select
                                mode='multiple'
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder='Please select'
                                onChange={handleTaxChange}
                                options={taxesList}
                            /> */}
                        </div>
                        <div className='col-md-4 form-group'>
                            <label htmlFor='exampleInputEmail1'>
                                Healthy Item
                            </label>
                            <Form>
                                <Form.Check
                                    type='switch'
                                    onChange={handleChange}
                                    checked={formData.is_healthy}
                                    label=''
                                    name='is_healthy'
                                    id={1011}
                                />
                            </Form>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='translations'>
                                    Translations
                                </label>
                                <AddEditTranslations
                                    translations={formData.translations}
                                    rows={rows}
                                    setRows={setRows}
                                    showDescriptionField={true}
                                    tagsExists={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        {/* <div className='col-md-8 form-group'>
                            <label style={{fontSize:'14px'}}>Select Recommended Items: <br/><small><small>Choose the items below that you would like to recommend when customers add the primary item to their cart</small></small></label>
                            <RecommendedItems
                                updateRecommendedList={(data) =>
                                    setFormData({
                                        ...formData,
                                        recommended_item_ids: data,
                                    })
                                }
                                recommended_item_ids={
                                    formData.recommended_item_ids
                                }
                            />
                        </div> */}
                        {hotelDetails.type !== 'restaurant_manager' &&
                        !data?.id ? (
                            <div className='col-md-4 form-group'>
                                <div
                                    className='wrapper_seach'
                                    style={{ marginBottom: '10px' }}
                                >
                                    <label htmlFor='exampleInputEmail1'>
                                        SubAddons{' '}
                                        <small>
                                            (these addons are existing is some
                                            different items)
                                        </small>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Search subaddons by name'
                                        className='form-control'
                                        onChange={(e) =>
                                            setSubAddonSearch(e.target.value)
                                        }
                                    />
                                </div>
                                <div
                                    id='accordion'
                                    style={{
                                        height: '215px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {subAddonList
                                        ?.filter((data) => {
                                            if (subAddonSearch) {
                                                return data?.name
                                                    ?.toLowerCase()
                                                    ?.includes(
                                                        subAddonSearch.toLowerCase()
                                                    );
                                            } else {
                                                return data;
                                            }
                                        })
                                        .map((subAddon) => {
                                            return (
                                                <div
                                                    className='accordian-card'
                                                    key={subAddon.id}
                                                >
                                                    <div className='accordian-subaddons'>
                                                        <input
                                                            type='checkbox'
                                                            onChange={(e) =>
                                                                subAddonSelection(
                                                                    e,
                                                                    subAddon.id
                                                                )
                                                            }
                                                        />
                                                        <span
                                                            data-toggle='collapse'
                                                            data-target={
                                                                '#collapse' +
                                                                subAddon.id
                                                            }
                                                            aria-expanded='true'
                                                            aria-controls={
                                                                'collapse' +
                                                                subAddon.id
                                                            }
                                                        >
                                                            {subAddon.name}
                                                        </span>
                                                    </div>
                                                    <div
                                                        id={
                                                            'collapse' +
                                                            subAddon.id
                                                        }
                                                        className='collapse'
                                                        aria-labelledby={
                                                            'heading' +
                                                            subAddon.id
                                                        }
                                                        data-parent='#accordion'
                                                    >
                                                        {subAddon.addons.map(
                                                            (addon) => (
                                                                <li
                                                                    key={
                                                                        addon.id
                                                                    }
                                                                    className='accordian-addons'
                                                                >
                                                                    {addon.name}
                                                                </li>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className='text-danger'>
                        {errors &&
                            errors.map((err) => (
                                <>
                                    <div>
                                        {err?.field?.toUpperCase()} :{' '}
                                        {err?.error}
                                    </div>
                                </>
                            ))}
                    </div>
                </div>
                <div className='modal-footer'>
                    <button className='btn' onClick={() => setShow(false)}>
                        Close
                    </button>
                    <button
                        className='btn btn-primary'
                        onClick={() => submit()}
                    >
                        Save changes
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditItem;
