import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import { getFeedInterest } from '../../services/apiService';

Modal.setAppElement('body');

function FeedIntererestPopup({ feedId, open, close }) {
    const [loading, setLoading] = useState(false);
    const [interestList, setInterestList] = useState([]);
    const [pageNumber, setpageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(10);
    const [lastPage,setLastPage]  =useState(null)

    useEffect(() => {
        fetchFeedInterestData(feedId,pageNumber, pageCount);
    }, [feedId,pageNumber, pageCount]);

    const fetchFeedInterestData = async (feed_id,pageNumber, pageCount) => {
        setLoading(true);
        try {
            const response = await getFeedInterest(feed_id, pageNumber, pageCount);
            if (response.status === 200) {
                const data = response?.data|| {};
                console.log(data)
                setLastPage(data?.last_page)
                setInterestList(data?.data ||[]);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };
    const handlePageChange = (page) => {
        setpageNumber(page);
      };
    return (
        <div className='orderDetails'>
            <Modal
                isOpen={open}
                onRequestClose={close}
                className={'modal-dialog modal-lg modal-order-details'}
                overlayClassName={'modal-backdrop '}
                contentLabel='Example Modal'
            >
                <div
                    className='modal-content'
                    aria-modal='true'
                    id='modal-content-block'
                >
                    <div className='modal-header'>
                        <div className='title'>Feed interest data of users</div>

                        <button className='btn btn-icon' onClick={close}>
                            <i className='fa fa-times'></i>
                        </button>
                    </div>

                    <div className='modal-body' style={{ minHeight: '65vh' }}>
                        <div className='feed-interest'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Room No.</th>
                                        <th>Mobile</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colspan="4"  style={{textAlign:'center'}}>Loading...</td>
                                        </tr>
                                    ) : interestList.length > 0 ? (
                                        interestList.map((item) => (
                                            <tr>
                                                <td>
                                                    {item.interested_by_name}
                                                </td>
                                                <td>{item.room_no}</td>
                                                <td>
                                                    {item.interested_by_mobile}
                                                </td>
                                                <td>
                                                    {item.interested_by_email}
                                                </td>
                                            </tr>
                                        ))
                                    ) : interestList.length === 0 ? (
                                        <tr>
                                             <td colspan="4" style={{textAlign:'center'}}>No Data found!</td>
                                        </tr>
                                    ) : (
                                        ''
                                    )}
                                </tbody>
                            </table>
                            {interestList.length >  0 ?<ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={lastPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={(e) => {
                                handlePageChange(e.selected + 1);
                                }}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />:""}      
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default FeedIntererestPopup;
