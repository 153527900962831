import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Time from "../Times/timesOne";
import { convertImageToBase64, printCurrencySymbol } from "../../helper/_commonHelper";
import * as htmlToImage from 'html-to-image';
import { useSelector } from "react-redux";
import { commonSelector } from "../../store/slices/common/commonSlice";
Modal.setAppElement("body");

function OrderDetailsPopup({ item, open, close }) {
  const [signImage,setSignImage] = useState("")
  const { hotelDetails = {} } = useSelector(commonSelector);
  const currencySymbol = printCurrencySymbol();


  useEffect(()=>{
    if(item.guest_signature){
        convertImageToBase64(`https://master-vserve.s3.ap-south-1.amazonaws.com/guest-signature/${item.guest_signature}`, setSignImage)
    }
  },[item.guest_signature]);

  const downloadDocument = ()=>{
    const input = document.getElementById("print-details-popup");
    const downloadableImgtag = document.getElementById("downloadableImg");
    htmlToImage
      .toJpeg(input)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        downloadableImgtag.setAttribute("href", dataUrl);
        downloadableImgtag.click();
      })
      .catch(function (error) {
        console.log(error)
        console.error("oops, something went wrong!", error);
      });
  }
  const printDocument = () => {
    window.print()
  };

  return (
    <div className="orderDetails">
      <Modal
        isOpen={open}
        onRequestClose={close}
        className={"modal-dialog modal-lg modal-order-details"}
        overlayClassName={"modal-backdrop "}
        contentLabel="Example Modal"
      >
        <div
          className="modal-content"
          aria-modal="true"
          id="modal-content-block"
        >
          <div className="modal-header">
            <div className="title">Order Summary</div>
            <div className="flex hidden-print">
                {/* <button className="btn btn-icon btn-primary" onClick={() => printDocument()}>
                    Print Order
                </button> */}
                <button className="btn btn-icon" onClick={() => downloadDocument()}>
                    Download Order
                </button>
            </div>
            <button className="btn btn-icon hidden-print" onClick={close}>
              <i className="fa fa-times"></i>
            </button>
          </div>

          <div className="modal-body" id="print-details-popup" >
            <a id="downloadableImg" download style={{ display: "none" }}></a>
            <div className="details-div f-d-r" >
              <div className="modal-details-sec">
                <label className="details-label">
                  {hotelDetails.type === "restaurant_manager" ? "" : item?.guest?.name || 'GUESTUSER'}
                </label>
                <div className="details-value" >
                  {item?.primises
                    ? item?.primises?.premise_no
                    : item.table
                    ? item.table.table_no
                    : ""}
                </div>
              </div>
              {item?.guest?.mobile && <div className="modal-details-sec f-c">
                <label className="details-label">Mobile no.</label>
                <div className="details-value">{item?.guest?.mobile_country_code ? `+${item?.guest?.mobile_country_code} ` : ""}{item?.guest?.mobile || ""}</div>
              </div>}
              <div className="modal-details-sec f-c">
                <label className="details-label">Order ID</label>
                <div className="details-value">{item?.id || ""}</div>
              </div>
              <div className="modal-details-sec f-c">
                <label className="details-label">Order Time</label>
                <div className="details-value">
                  <Time
                    newDate={item.order_detail.created_at}
                    timesString={item.order_detail.created_at}
                  />
                </div>
              </div>
              {item.order_detail?.preorder_on ? (
                <div className="modal-details-sec f-c">
                  <label className="details-label">Pre Order Time</label>
                  <div className="details-value">
                    <Time
                      newDate={item.order_detail?.preorder_on}
                      timesString={item.order_detail?.preorder_on}
                    />
                  </div>
                </div>
              ) : ""}
              <div className="modal-details-sec f-c">
                <label className="details-label">No of Guest</label>
                <div className="details-value">{item.no_of_guest}</div>
              </div>
            </div>
            <div className="details-div f-d-r" >
              {item.order_detail?.created_at && (
                <div className="modal-details-sec">
                  <label className="details-label">ORDER RECEIVED AT</label>
                  <div className="details-value">
                    <Time
                      newDate={item.order_detail?.created_at}
                      timesString={item.order_detail?.created_at}
                    />
                  </div>
                </div>
              )}
              {item.order_detail.accepted_at && (
                <div className="modal-details-sec f-c">
                  <label className="details-label">ORDER ACCEPTED AT</label>
                  <div className="details-value">
                    <Time
                      newDate={item.order_detail.accepted_at}
                      timesString={item.order_detail.accepted_at}
                    />
                  </div>
                </div>
              )}
              {item.order_detail?.dispatched_at && (
                <div className="modal-details-sec f-c">
                  <label className="details-label">ORDER DISPATCHED AT</label>
                  <div className="details-value">
                    <Time
                      newDate={item.order_detail?.dispatched_at}
                      timesString={item.order_detail?.dispatched_at}
                    />
                  </div>
                </div>
              )}
              {item.order_detail.cleared_at && (
                <div className="modal-details-sec f-c">
                  <label className="details-label">ORDER CLEARED AT</label>
                  <div className="details-value">
                    <Time
                      newDate={item.order_detail.cleared_at}
                      timesString={item.order_detail.cleared_at}
                    />
                  </div>
                </div>
              )}

              {item.order_detail?.request_schedule_at && (
                <div className="modal-details-sec f-c">
                  <label className="details-label">Service Scheduled AT</label>
                  <div className="details-value">
                    <Time
                      newDate={item.order_detail?.request_schedule_at}
                      timesString={item.order_detail?.request_schedule_at}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="details-div f-d-r">
              <div className="modal-details-sec order-item">
                {item.order_delivery_type?.name ? (
                  <p>Service - {item.order_delivery_type?.name}</p>
                ) : ""}

                <label className="details-label">ORDER ITEMS</label>
                <div className="details-value">
                  <ul className="main-column">
                    {item.order_menu_items?.length > 0 &&
                      item.order_menu_items.map((itemEl) => {
                        return (
                          <li className="main-item" key={itemEl.id}>
                            <div>
                              {itemEl?.quantity} {" - "}
                              {itemEl?.item_name} - {currencySymbol}{" "}
                              {itemEl.price * itemEl.quantity}
                            </div>
                            <ul>
                              {itemEl.order_addons.length > 0 &&
                                itemEl.order_addons.map((itemAdd) => {
                                  return (
                                    <li key={itemAdd.id}>
                                      <b>{itemAdd.item.item_subaddon.name} :</b>
                                      {itemAdd?.item?.name} - {itemAdd.quantity > 1 ? itemAdd.quantity: itemEl?.quantity}{" "}
                                      {itemAdd.price
                                        ? " x " +
                                          printCurrencySymbol() +
                                          itemAdd.price
                                        : ""}
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                        );
                      })}
                    {item.order_spa_items?.length > 0 &&
                      item.order_spa_items.map((itemEl) => {
                        return (
                          <li className="main-item">
                            <div>
                              {itemEl?.item?.name} - {currencySymbol}{" "}
                              {itemEl.item.price} * {itemEl.quantity}
                            </div>
                          </li>
                        );
                      })}
                    {item.order_laundry_items?.length > 0 &&
                      item.order_laundry_items.map((itemEl) => {
                        return (
                          <li className="main-item">
                            <div>{itemEl?.item?.laundry_category?.name}</div>
                            <small>
                              {itemEl?.item?.name} - {currencySymbol}{" "}
                              {itemEl.item.price} * {itemEl.quantity}
                            </small>
                            <ul>
                              {itemEl.order_addons &&
                                itemEl.order_addons.length > 0 &&
                                itemEl.order_addons.map((itemAdd) => {
                                  return (
                                    <li key={itemAdd.id}>
                                      <b>{itemAdd?.sub_addon?.name} :</b>{" "}
                                      {itemAdd?.item_name} - {itemAdd.quantity}
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                        );
                      })}
                    {item.order_connect_items?.length > 0 &&
                      item.order_connect_items?.map((itemEl) => {
                        return (
                          <li className="main-item">
                            <div>
                              {itemEl?.item?.name}{" "}
                              {itemEl.description
                                ? " - " + itemEl.description
                                : ""}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            {item.order_stage?.stage && (
              <div className="details-div f-d-r">
                <div className="modal-details-sec">
                  <label className="details-label">REASON</label>
                  <div className="details-value">
                    {item.order_stage?.stage_text || "---"}
                  </div>
                </div>
              </div>
            )}
            <div className="details-div f-d-r">
              <div className="modal-details-sec">
                <label className="details-label">ORDER INSTRUCTIONS</label>
                <div className="details-value">
                  <pre style={{ whiteSpace: "break-spaces" }}>
                    {item.description || "---"}
                  </pre>
                </div>
              </div>
              {item?.order_payment_modes && item?.order_payment_modes.length>0? (
                <div className="modal-details-sec">
                  <label className="details-label">{hotelDetails?.hotel?.hotel_payment_config?.heading || 'ORDER PAYMENT MODE'}</label>
                  <div className="details-value">
                    <pre>{item?.order_payment_modes.map((pay) => pay.name).join(', ')}</pre>
                  </div>
                </div>
              ) : ""}
            </div> 
            <div className="details-div f-d-r">
              <div className="modal-details-sec">
                <label className="details-label">SIGNATURE</label>
                <div className="details-value">
                    {
                        (hotelDetails.type === 'hotel_admin' || hotelDetails.type === 'ird_manager') &&
                        item.guest_signature && signImage ? (
                            <img src={signImage} alt='signature_img' />
                        ) : (
                            <div>No Signature</div>
                        )
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default OrderDetailsPopup;
