import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Loading from '../Components/common/Loading';
import Sidebar from '../Components/Sidebar';
import NotificationContextProvider from '../contexts/NotificationContextProvider';
import {
    commonSelector,
    fetchUserProfile,
} from '../store/slices/common/commonSlice';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../helper/_cookieHelper';

const Layout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useDispatch();
    const { loading, error } =
        useSelector(commonSelector);
    const navigate = useNavigate();

    useEffect(() => {
        const token = getCookie('token');
        if (!token) {
            navigate('/login', { replace: true });
        }
    }, []);


    useEffect(() => {
        dispatch(fetchUserProfile());
    }, []);

    const collapsedClickHandler = () => {
        setCollapsed((prevstate) => !prevstate);
    };
    if (loading) return <Loading />;
    if (error) return <p>Something went wrong! Please talk to admin.</p>;

    return (
        <NotificationContextProvider>
            <div className='app-wrapper'>
                <Sidebar
                    collapsed={collapsed}
                    collapsedClickHandler={collapsedClickHandler}
                />
                <div
                    className={
                        'main-layout ' + (collapsed ? 'collapsed-layout' : '')
                    }
                >
                    <Outlet />
                </div>
            </div>
        </NotificationContextProvider>
    );
};

export default Layout;
