import React from 'react';
import { Outlet } from 'react-router-dom';

const MenuScheduler = () => {
    return (
        <div className='scheduler-wrapper'>
            <Outlet />
        </div>
    );
};

export default MenuScheduler;
