import React from "react";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import img from "../common/to-do-list.png";
function TransitionLoader(props) {
  return (
    <Modal
      isOpen={true}
      className={"modal-dialog modal-sm modal-transition"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Example Modal"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        <div className="modal-body">
          <div className="transtion-loader">
            <div className="state ">
              <div className="state-circle">
                <img src={img} alt="skelton_order" className="placeholder-img" />
                <img src={img} alt="skelton_order" className="transition-img" />
              </div>
              <div className="label">{props.previousStage}</div>
            </div>
            <div className="state">
              <div className="state-circle">
                <img src={img} alt="skelton_order"  className="placeholder-img" />
              </div>

              <div className="label">{props.newStage}</div>
            </div>
            {/* <Loader type="ThreeDots" color="#665551" height={40} width={40} /> */}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TransitionLoader;
