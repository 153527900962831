import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../Components/common/Loading';
import { deleteFeed, getAllFeeds } from '../../services/apiService';
import { commonSelector } from '../../store/slices/common/commonSlice';
import CreateFeed from './CreateFeed';
import FeedCard from './FeedCard';
import FeedIntererestPopup from './FeedIntererestPopup';

const DashboardFeed = (props) => {
    const [loading, setLoading] = useState(false);
    const [feedList, setFeedList] = useState([]);
    const [isCreateNewFeed, setIsCreateNewFeed] = useState(false);
    const [feedId, setFeedId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [encID, setEncID] = useState('');
    const {hotelDetails} = useSelector(commonSelector);

    const enc_hotel_id = localStorage.getItem('enc_hotel_id');
    const audio = new Audio(
        'https://master-vserve.s3.ap-south-1.amazonaws.com/tone/desk_bell.mp3'
    );
    audio.muted = true;
    let baseURL =
        process.env.REACT_APP_NODE_ENV === 'production'
            ? 'https://mi.vserve-protecht.com'
            : 'https://dev.d3jhca2vwfysp4.amplifyapp.com';

    useEffect(() => {
        fetchAllFeeds();
    }, []);
    useEffect(() => {
        let feedIntervalTime;

        if (hotelDetails.hotel_id && hotelDetails.type === 'feed_manager') {
            feedIntervalTime = setInterval(fetchAllFeeds, 600000);
        }
        return () => {
            if (hotelDetails.hotel_id && hotelDetails.type === 'feed_manager')
                clearInterval(feedIntervalTime);
        };
    }, []);

    const addFeedClick = (id) => {
        setIsCreateNewFeed(false);
        if (id === feedId) {
            setFeedId(null);
        } else {
            setFeedId(id);
        }
    };
    const fetchAllFeeds = async () => {
        try {
            setLoading(true);
            const response = await getAllFeeds(hotelDetails.hotel_id);
            if (response.status === 200) {
                const feed = response.data ?? [];
                setFeedList(feed);
                const hasNewRequest = feed.find(
                    (item) => item.unreadFeedInterestCount > 0
                );
                if (hasNewRequest) {
                    playRing();
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const refreshFeed = () => {
        fetchAllFeeds();
    };
    const onnewFeedSuccess = () => {
        setIsCreateNewFeed(false);
        fetchAllFeeds();
    };
    const onFeedContentSuccess = () => {
        fetchAllFeeds();
    };
    const handleUrlInput = (e) => {
        let { value } = e.target;
        setEncID(value);
    };
    const playRing = () => {
        try {
            if (!audio.paused) {
                audio.muted = true;
                audio.muted = false;
                audio?.pause();
                audio?.play();
            } else {
                audio.muted = true;
                audio.muted = false;
                audio?.play();
            }
        } catch (error) {
            console.log('Can not play the bell!!');
        }
    };
    const deletFeedHandler = async (id) => {
        if (window.confirm('Are you sure you want to delete')) {
            try {
                const response = await deleteFeed(id);
                console.log(response);
                if (response.status === 200) {
                    fetchAllFeeds();
                }
            } catch (error) {
            } finally {
            }
        }
    };

    return (
        <div className='feeds-container'>
            <div className='feedlist-container'>
                <div className='container-fluid'>
                    <button
                        className='btn'
                        onClick={() =>
                            setIsCreateNewFeed((prevState) => !prevState)
                        }
                    >
                        Add New Feed
                    </button>
                    <button
                        className='btn btn-primary'
                        onClick={() => refreshFeed()}
                    >
                        Refresh Feed
                    </button>
                    {isCreateNewFeed && (
                        <CreateFeed
                            onnewFeedSuccess={onnewFeedSuccess}
                            closeFeed={() => setIsCreateNewFeed(false)}
                        />
                    )}
                    <div className='feedlist'>
                        {loading ? (
                            <Loading />
                        ) : feedList.length > 0 ? (
                            feedList.map((feed) => (
                                <FeedCard
                                    key={feed.id}
                                    addFeedClick={addFeedClick}
                                    feedId={feedId}
                                    onFeedContentSuccess={onFeedContentSuccess}
                                    deletFeedHandler={deletFeedHandler}
                                    fetchAllFeeds={fetchAllFeeds}
                                    setOpenPopup={() => setOpenPopup(true)}
                                    {...feed}
                                />
                            ))
                        ) : null}
                    </div>
                </div>
            </div>
            <div className='feed-preview '>
                <input
                    type='text'
                    placeholder='Enter your encrypted hotel ID'
                    className='url-hotel-input'
                    onChange={handleUrlInput}
                    value={enc_hotel_id}
                    disabled={enc_hotel_id}
                />
                <div className='mobile-view'>
                    {enc_hotel_id && (
                        <iframe
                            src={`${baseURL}/welcome/${enc_hotel_id}`}
                            height='554'
                            width='262'
                            title='mobile-view'
                        ></iframe>
                    )}
                </div>
            </div>
            {feedId && openPopup ? (
                <FeedIntererestPopup
                    open={openPopup}
                    close={() => setOpenPopup(false)}
                    feedId={feedId}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default DashboardFeed;
