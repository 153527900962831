import React from "react";

function NotificationBadge(props) {
  const { type, updateOrderByType } = props;
  return (
    <div className="notifiction-wrapper">
      <div className="noti-title">
        <span>
          <i className="fa fa-bell"></i>
          You have a new order!!
        </span>
      </div>
      <button
        className="btn btn-success"
        onClick={() => updateOrderByType(type)}
      >
        Click here to Continue
      </button>
    </div>
  );
}

export default NotificationBadge;
