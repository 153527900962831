import { notification } from 'antd';
import axios from 'axios';
import React, { useReducer, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../../../Components/common/Loading';
import NoDataFound from '../../../Components/common/NoDataFound';
import OrderCard from '../../../Components/common/OrderCard';
import OrderDetailsPopup from '../../../Components/common/OrderDetailsPopup';
import TransitionLoader from '../../../Components/common/TransitionLoader';
import AppConstant from '../../../constant/AppConstant';
import { getOrderSource } from '../../../services/apiService';
import { fetchIRDOrderList } from '../../../store/slices/ird/irdSlice';

const Cleared = (props) => {
    const [showItemPopup, setShowItemPopup] = useState(false);
    const [detailedViewItem, setDetailedViewItem] = useState({});
    const [showTransitionLoader, setShowTransitionLoader] = useState(false);
    const { data, loading, hasNotification, updateOrderByType } = props;
    const dispatch = useDispatch();
    const authtype = getOrderSource();

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title = '', msg = '') => {
        api[type]({
            message: (title = ''),
            description: msg,
        });
    };

    const handleClosePopup = () => {
        setShowItemPopup(false);
        setDetailedViewItem({});
    };
    const handleShowPopup = (item) => {
        setShowItemPopup(true);
        setDetailedViewItem(item);
    };

    const handleClearSave = async (item) => {
        try {
            setShowTransitionLoader(true);
            let response = await axios.put(
                AppConstant.config.appBaseUrl +
                    `/${authtype}/order/clear/` +
                    item.order_detail.order_id
            );
            if (response.status === 202) {
                const msg = response.data.message || '';
                openNotificationWithIcon('success', '', msg);

                setTimeout(() => {
                    dispatch(fetchIRDOrderList());
                }, 1000);
            }
        } catch (error) {
        } finally {
            setTimeout(() => {
                setShowTransitionLoader(false);
            }, 1000);
        }
    };

    const compChange = async (value, item) => {
        try {
            let response = await axios.put(
                AppConstant.config.appBaseUrl +
                    `/common/order/complementary/${value}/` +
                    item.order_detail.order_id
            );
            if (response.status === 200) {
                let msg =
                    value === 'enable'
                        ? 'Meal marked as complimentary!'
                        : 'Meal unmarked as complimentary!';
                openNotificationWithIcon('success', '', msg);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <section className='order-list-container'>
            {contextHolder}
            { data.length === 0 ? (
                <div className='home-order-screen no-data'>
                    <NoDataFound />
                </div>
            ) : (
                <div className='home-screen'>
                    {data.length > 0 &&
                        data.map((item, index) => {
                            return (
                                <OrderCard
                                    tabType={'ClearOrder'}
                                    ServiceName={'IRD'}
                                    OrderType={'DISPATCHED'}
                                    key={item.id}
                                    item={item}
                                    viewDetails={() => handleShowPopup(item)}
                                    footerBtn={() => handleClearSave(item)}
                                    btnTitle={'Clear'}
                                    timerTime={
                                        item?.order_detail?.dispatched_at
                                    }
                                    complimentaryChange={compChange}
                                />
                            );
                        })}
                </div>
            )}
            {showItemPopup && (
                <OrderDetailsPopup
                    open={showItemPopup}
                    close={handleClosePopup}
                    item={detailedViewItem}
                />
            )}
            {showTransitionLoader && (
                <TransitionLoader
                    previousStage={'Dispatched'}
                    newStage={'Order History'}
                />
            )}
        </section>
    );
};

export default Cleared;
