import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loading from '../../Components/common/Loading';
import { printCurrencySymbol } from '../../helper/_commonHelper';
import {
    enableDisableSpaMenuList,
    fetchSpaMenuList,
    spaSelector,
} from '../../store/slices/spa/spaSlice';

import { Badge, Breadcrumb, Collapse, Switch } from 'antd';
import NoDataFound from '../../Components/common/NoDataFound';

const { Panel } = Collapse;

const Menu = () => {
    const [lastClickedToggleID, setLastClickedToggleID] = useState('');
    const [activeAccordion, setActiveAccordion] = useState({
        menu: null,
        category: null,
        subCat: null,
        addon: null,
        subAddon: null,
    });

    const dispatch = useDispatch();
    const { menuList, loading } = useSelector(spaSelector);
    // scroll after clicked

    useEffect(() => {
        setTimeout(() => {
            if (lastClickedToggleID) {
                const findNode = document.getElementById(
                    `node-${lastClickedToggleID}`
                );
                if (findNode) {
                    findNode.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, 100);
    }, [menuList]);

    useEffect(() => {
        dispatch(fetchSpaMenuList());
    }, []);

    const handleChecked = (checked, url, id) => {
        setLastClickedToggleID(id);
        if (checked) {
            dispatch(enableDisableSpaMenuList(`${url}/enable`, id));
        } else if (!checked) {
            dispatch(enableDisableSpaMenuList(`${url}/disable`, id));
        }
    };

    const currencySymbol = printCurrencySymbol();
    const onChange = (key, type) => {
        let activeKey = key[0] || null;
        setActiveAccordion({ ...activeAccordion, [type]: activeKey });
    };
    const genExtra = (checked, url, id) => {
        return (
            <Switch
                checked={checked}
                onChange={(check) => handleChecked(check, url, id)}
                size='small'
                id={`node-${id}`}
            />
        );
    };

    const ActionHeader = ({ searchValue, inputHandleChange }) => {
        return (
            <div className='action-header'>
                <div className='action-left'>
                    <div className='page-title'>SPA</div>
                    <Breadcrumb
                        items={[
                            {
                                title: <a href='/spa'>Spa</a>,
                            },
                            {
                                title: 'Menu',
                            },
                        ]}
                    />
                </div>
            </div>
        );
    };
    const RenderSubAddons = ({
        addons = [],
        menuEnabled,
        categoryEnabled,
        subCategoryEnabled,
        itemEnabled,
        subAddonEnabled,
    }) => {
        return addons.map((addon) => {
            return (
                <div className='m-content-addon' key={addon.id}>
                    <span title='Addon'>
                        <div>{addon.name}</div>

                        {addon.price ? (
                            <div className='m-price'>
                                {currencySymbol}
                                {addon.price}
                                .00
                            </div>
                        ) : null}
                    </span>
                    {genExtra(
                        Boolean(menuEnabled) &&
                            Boolean(categoryEnabled) &&
                            Boolean(subCategoryEnabled) &&
                            Boolean(itemEnabled) &&
                            Boolean(subAddonEnabled) &&
                            Boolean(addon.enabled),
                        'sanitarium/spa/item/sub-addon',
                        addon.id
                    )}
                </div>
            );
        });
    };
    const RenderAddons = ({
        subAddons = [],
        menuEnabled,
        categoryEnabled,
        subCategoryEnabled,
        itemEnabled,
    }) => {
        return (
            <Collapse
                onChange={(key) => onChange(key, 'subAddon')}
                accordion
                defaultActiveKey={[activeAccordion.subAddon]}
            >
                {subAddons?.map((subAddon) => (
                    <Panel
                        header={
                            <>
                                <Badge
                                    count={'Customization'}
                                    color='#6196a6'
                                />{' '}
                                {subAddon.name}{' '}
                                <Badge
                                    count={subAddon?.addons?.length}
                                    style={{
                                        backgroundColor: '#52c41a',
                                    }}
                                />
                            </>
                        }
                        key={subAddon.id}
                        extra={genExtra(
                            Boolean(menuEnabled) &&
                                Boolean(categoryEnabled) &&
                                Boolean(subCategoryEnabled) &&
                                Boolean(itemEnabled) &&
                                Boolean(subAddon.enabled),
                            'menu/item/sub-addon',
                            subAddon.id
                        )}
                    >
                        {subAddon.addons?.length > 0 && (
                            <RenderSubAddons
                                addons={subAddon.addons || []}
                                menuEnabled={menuEnabled}
                                categoryEnabled={categoryEnabled}
                                subCategoryEnabled={subCategoryEnabled}
                                itemEnabled={itemEnabled}
                                subAddonEnabled={subAddon.enabled}
                            />
                        )}
                    </Panel>
                ))}
            </Collapse>
        );
    };
    const RenderItems = ({
        menuEnabled,
        categoryEnabled,
        subCategoryEnabled,
        items = [],
    }) => {
        return items.map((item) => {
            return (
                <div key={item.id} className='m-item-card'>
                    <Badge count={'Item'} color='#d2bc9b' />{' '}
                    <span className='itemname-wrapper'>
                        <span title='Item'>{item.name}</span>

                        {genExtra(
                            Boolean(menuEnabled) &&
                                Boolean(categoryEnabled) &&
                                Boolean(subCategoryEnabled) &&
                                Boolean(item.enabled),
                            'sanitarium/spa/item',
                            item.id
                        )}
                    </span>
                    {item.price ? (
                        <h5 className='m-price'>
                            {currencySymbol}
                            {item.price}
                            .00
                        </h5>
                    ) : null}
                    <div
                        className='m-desp'
                        dangerouslySetInnerHTML={{
                            __html: item.description,
                        }}
                    ></div>
                    {item.sub_addons?.length > 0 && (
                        <RenderAddons
                            subAddons={item.sub_addons || []}
                            menuEnabled={menuEnabled}
                            categoryEnabled={categoryEnabled}
                            subCategoryEnabled={subCategoryEnabled}
                            itemEnabled={item.enabled}
                        />
                    )}
                </div>
            );
        });
    };

    const RenderSubCategories = ({
        menuEnabled,
        categoryEnabled,
        subCategories = [],
    }) => {
        return (
            <Collapse
                onChange={(key) => onChange(key, 'subCat')}
                accordion
                defaultActiveKey={[activeAccordion.subCat]}
            >
                {subCategories?.map((subCategory) => (
                    <Panel
                        header={
                            <>
                                <Badge count={'SubCategory'} color='#324550' />{' '}
                                {subCategory.name}{' '}
                                <Badge
                                    count={subCategory?.items?.length}
                                    style={{
                                        backgroundColor: '#52c41a',
                                    }}
                                />
                            </>
                        }
                        key={subCategory.id}
                        extra={genExtra(
                            Boolean(menuEnabled) &&
                                Boolean(categoryEnabled) &&
                                Boolean(subCategory.enabled),
                            'sanitarium/spa/sub-category',
                            subCategory.id
                        )}
                    >
                        {subCategory.items.length > 0 && (
                            <RenderItems
                                items={subCategory.items || []}
                                menuEnabled={menuEnabled}
                                categoryEnabled={categoryEnabled}
                                subCategoryEnabled={subCategory.enabled}
                            />
                        )}
                    </Panel>
                ))}
            </Collapse>
        );
    };
    const RenderCategories = ({ menuEnabled, categories = [] }) => {
        return (
            <Collapse
                onChange={(key) => onChange(key, 'category')}
                accordion
                defaultActiveKey={[activeAccordion.category]}
            >
                {categories.map((category) => (
                    <Panel
                        header={
                            <>
                                <Badge count={'Category'} color='#436f00' />{' '}
                                {category.name}{' '}
                                <Badge
                                    count={category?.sub_categories?.length}
                                    style={{
                                        backgroundColor: '#52c41a',
                                    }}
                                />
                            </>
                        }
                        key={category.id}
                        extra={genExtra(
                            Boolean(menuEnabled) && Boolean(category.enabled),
                            'sanitarium/spa/category',
                            category.id
                        )}
                    >
                        {category.description && (
                            <div
                                className='m-desp'
                                dangerouslySetInnerHTML={{
                                    __html: category.description,
                                }}
                            ></div>
                        )}

                        {category.sub_categories?.length > 0 && (
                            <RenderSubCategories
                                subCategories={category.sub_categories || []}
                                categoryEnabled={category.enabled}
                                menuEnabled={menuEnabled}
                            />
                        )}
                        {category.without_sub_category_items.length > 0 && (
                            <RenderItems
                                items={category.without_sub_category_items}
                                categoryEnabled={category.enabled}
                                menuEnabled={menuEnabled}
                                subCategoryEnabled={1}
                            />
                        )}
                    </Panel>
                ))}
            </Collapse>
        );
    };

    return (
        <>
            <ActionHeader />

            <div className='m-enable-wrapper'>
                {loading ? (
                    <Loading />
                ) : menuList.length === 0 ? (
                    <NoDataFound />
                ) : menuList.length > 0 ? (
                    <Collapse
                        defaultActiveKey={[activeAccordion.menu]}
                        onChange={(key) => onChange(key, 'menu')}
                        accordion
                    >
                        {menuList?.map((menu, index) => {
                            return (
                                <Panel
                                    header={
                                        <>
                                            <Badge
                                                count={'Menu'}
                                                color='#0163b8'
                                            />{' '}
                                            {menu.name}{' '}
                                            <Badge
                                                count={menu?.categories?.length}
                                                style={{
                                                    backgroundColor: '#52c41a',
                                                }}
                                            />
                                        </>
                                    }
                                    key={menu.id}
                                    extra={genExtra(
                                        Boolean(menu.enabled),
                                        'sanitarium/spa',
                                        menu.id
                                    )}
                                >
                                    {menu.description && (
                                        <div
                                            className='m-desp'
                                            dangerouslySetInnerHTML={{
                                                __html: menu.description,
                                            }}
                                        ></div>
                                    )}
                                    {menu.categories?.length > 0 && (
                                        <RenderCategories
                                            categories={menu.categories || []}
                                            menuEnabled={menu.enabled}
                                        />
                                    )}
                                </Panel>
                            );
                        })}
                    </Collapse>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default Menu;
