import { React, useState } from 'react';
import { Button, Modal, Table, TimePicker, message } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { getIRDMenus } from '../services/apiService';

const AddSlot = ({
    menu,
    setMenus,
    schedules,
    setSchedules,
    isModalVisible,
    setIsModalVisible,
    handleSaveSchedules,
    convertToLocal,
}) => {
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const handleCancel = () => {
        setIsModalVisible(false);
        if (menu.schedules && menu.schedules.length > 0) {
            setSchedules(menu.schedules.map(convertToLocal));
        } else {
            setSchedules([]);
        }
    };

    const handleTimeChange = (time, field, index) => {
        const updatedSchedules = [...schedules];
        const updatedSlot = { ...updatedSchedules[index] };
        updatedSlot[field] = time ? time.format('HH:mm') : null;
        updatedSchedules[index] = updatedSlot;
        setSchedules(updatedSchedules);
    };

    const handleDelete = (index) => {
        setDeleteIndex(index);
        setIsDeleteModalVisible(true);
    };

    const confirmDelete = () => {
        const updatedSchedules = schedules.filter((_, i) => i !== deleteIndex);
        setSchedules(updatedSchedules);
        setIsDeleteModalVisible(false);
    };

    const handleAdd = () => {
        setSchedules([...schedules, { start_time: null, end_time: null }]);
    };

    const handleSubmit = async () => {
        if (schedules.length > 0) {
            const isValid = schedules.every(
                (schedule) => schedule.start_time && schedule.end_time
            );
            if (!isValid) {
                message.error(
                    'Please fill in all start and end times or remove incomplete schedules'
                );
                return;
            }
        }

        try {
            const saveResponse = await handleSaveSchedules(menu.id, schedules);
            if (saveResponse.status === 201 || saveResponse.status === 200) {
                const menuResponse = await getIRDMenus('ird');
                setMenus(menuResponse.data);
            }
            setIsModalVisible(false);
        } catch (error) {
            message.error('Failed to save schedules. Please try again.');
        }
    };

    const columns = [
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
            render: (text, record, index) => (
                <TimePicker
                    value={text ? moment(text, 'HH:mm') : null}
                    onChange={(time) =>
                        handleTimeChange(time, 'start_time', index)
                    }
                    format='HH:mm'
                />
            ),
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',
            render: (text, record, index) => (
                <TimePicker
                    value={text ? moment(text, 'HH:mm') : null}
                    onChange={(time) =>
                        handleTimeChange(time, 'end_time', index)
                    }
                    format='HH:mm'
                />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record, index) => (
                <Button
                    onClick={() => handleDelete(index)}
                    type='primary'
                    danger
                >
                    <DeleteOutlined />
                </Button>
            ),
        },
    ];

    return (
        <>
            <Modal
                title='Scheduler'
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key='cancel' onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key='submit' type='primary' onClick={handleSubmit}>
                        Submit
                    </Button>,
                ]}
                width={400}
            >
                <Table
                    dataSource={schedules}
                    columns={columns}
                    rowKey={(record, index) => index}
                    pagination={false}
                    locale={{
                        emptyText: (
                            <div
                                style={{ textAlign: 'center', padding: '20px' }}
                            >
                                <p>No Slots Found</p>
                            </div>
                        ),
                    }}
                />
                <Button
                    onClick={handleAdd}
                    style={{ marginTop: '10px', marginLeft: '15px' }}
                    type='primary'
                >
                    Add New Slots
                </Button>
            </Modal>

            <Modal
                title='Confirm Delete'
                open={isDeleteModalVisible}
                onOk={confirmDelete}
                onCancel={() => setIsDeleteModalVisible(false)}
                okText='Delete'
                cancelText='Cancel'
                okButtonProps={{ danger: true }}
            >
                <div style={{ marginBottom: '15px' }}>
                    <p>Are you sure you want to delete this slot?</p>
                </div>

                <div className='delete-message-container'>
                    <ExclamationCircleOutlined className='exclamation-symbol' />
                    <p className='note'>
                        Note: The menu is currently{' '}
                        <strong>{menu.enabled ? 'enabled' : 'disabled'}</strong>
                        . After deleting this slot, please{' '}
                        <strong>{menu.enabled ? 'disable' : 'enable'}</strong>{' '}
                        it manually if needed.
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default AddSlot;
