import React from 'react';
import { Outlet } from 'react-router-dom';

const Connect = () => {
    return (
        <div className=''>
            <Outlet />
        </div>
    );
};




export default Connect;
