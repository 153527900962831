import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
    bookingSelector,
    fetchBookingOrderList,
    fetchBookingServiceList,
    updateSelectedServiceID,
} from '../../store/slices/booking/bookingSlice';
import Loading from '../../Components/common/Loading';
import BookingTable from './BookingTable';
import { chain } from 'lodash';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useNotification } from '../../contexts/NotificationContextProvider';
import NotificationBadge from '../../Shared/NotificationBadge';
import { commonSelector } from '../../store/slices/common/commonSlice';
const splitDate = (date) => {
    let dateArray = new Date(date).toDateString().split(' ');
    dateArray.splice(3, 1);
    if (dateArray.length) {
        let commaSepratedDate = dateArray.map((item, index) => {
            if (index == 0) {
                return item + ',';
            } else {
                return item;
            }
        });
        let finalDate = commaSepratedDate.join(' ');
        return finalDate;
    } else {
        return '';
    }
};

const getday = (day) => {
    const weekdays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    return weekdays[day];
};

const createDayList = (hotelDetails) => {
    let list = [];
    let dayLength = 7;
    if (hotelDetails.hotel_id == 162) {
        dayLength = 3;
    }
    for (let i = 0; i < dayLength; i++) {
        let newDate;
        newDate = new Date();
        const tomorrowDate = newDate.getDate() + i;
        newDate.setDate(tomorrowDate);
        const dayIndex = newDate.getDay();
        const dayObj = {
            id: i,
            day: getday(dayIndex),
            splitedViewDate: splitDate(newDate),
            dayDate: newDate,
            orderCount: 0,
        };
        list.push(dayObj);
    }
    return list;
};
const ActionHeader = ({ searchValue, inputHandleChange }) => {
    return (
        <div className='action-header'>
            <div className='action-left'>
                <div className='page-title'>BOOKING</div>
                <Breadcrumb
                    items={[
                        {
                            title: <a href='/booking'>Booking</a>,
                        },
                        {
                            title: 'Dashboard',
                        },
                    ]}
                />
            </div>
            <div className='action-right'>
                <Input
                    placeholder='Search Name/Order Id/Room/Villa No'
                    style={{ width: '15rem' }}
                    prefix={<SearchOutlined />}
                    value={searchValue}
                    onChange={(e) => inputHandleChange(e)}
                />{' '}
                <ReactHtmlTableToExcel
                    id='test-table-xls-button'
                    className='download-table-xls-button btn btn-back'
                    table='table-allbooking'
                    filename='tablexls'
                    sheet='allOrders'
                    buttonText='Download as XLS'
                />
            </div>
        </div>
    );
};
const DashboardBooking = (props) => {
    const { hotelDetails } = useSelector(commonSelector);

    const [currentDay, setCurrentDay] = useState(getday(new Date().getDay()));
    const [searchValue, setSearchvalue] = useState('');
    const [dayList, setdayList] = useState(createDayList(hotelDetails));
    const dispatch = useDispatch();
    const { orderList, loading, serviceList, selectedServiceID } =
        useSelector(bookingSelector);
    const { notificationData, updateOrderByType } = useNotification();

    useEffect(() => {
        if (hotelDetails.hotel_id) {
            dispatch(fetchBookingServiceList(hotelDetails.hotel_id));
        }
    }, [hotelDetails.hotel_id]);

    useEffect(() => {
        if (notificationData['booking']) {
            updateOrderByType('booking');
        }
        if (selectedServiceID) {
            dispatch(fetchBookingOrderList(searchValue));
        }
    }, [currentDay, selectedServiceID, searchValue]);

    const filterData = (orderList) => {
        let bookingData = orderList?.filter(
            (item) => item.status === 'new_order'
        );
        const filterdata = bookingData?.filter((item) => {
            const bookingtype =
                item?.order_booking_services[0]?.booking_service_id;
            const day =
                item?.order_detail?.booking_service_timing
                    ?.booking_service_opening_day?.day;
            return bookingtype == selectedServiceID && currentDay == day;
        });
        console.log(filterdata);
        // lodash to group data
        const finalData = chain(filterdata)
            // Group the elements of Array based on `booking slot` property
            .groupBy((x) => x.order_detail?.booking_slot)
            // `key` is group's name (booking slot), `datalist` is the array of objects
            .map((value, key) => ({ booking_slot: key, dataList: value }))
            .value();
        // setBookingsList(finalData);
        return finalData;
    };
    const bookingData = filterData(orderList);
    console.log(bookingData);
    const inputHandleChange = (e) => {
        setSearchvalue(e.target.value);
    };
    return (
        <>
            {notificationData['booking'] && (
                <NotificationBadge
                    type={'booking'}
                    updateOrderByType={updateOrderByType}
                />
            )}
            <ActionHeader
                inputHandleChange={inputHandleChange}
                searchValue={searchValue}
            />

            <div className='filter-container bookings'>
                <div className='left-box'>
                    {dayList?.map(
                        ({ day, id, splitedViewDate, orderCount }) => {
                            return (
                                <div
                                    className={
                                        'booking-header ' +
                                        (day == currentDay ? 'active' : '')
                                    }
                                    onClick={() => setCurrentDay(day)}
                                    key={id}
                                >
                                    <span className='orderCount'>
                                        {orderCount}
                                    </span>
                                    {day}
                                    <div>
                                        <small>{splitedViewDate}</small>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
                <div className='right-box'>
                    <select
                        value={selectedServiceID}
                        onChange={(e) =>
                            dispatch(updateSelectedServiceID(e.target.value))
                        }
                        className='form-control'
                        style={{ display: 'inline-block' }}
                    >
                        {serviceList?.map((service) => (
                            <option key={service.id} value={service.id}>
                                {service.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div
                className={'home-order-screen '}
                style={{ position: 'relative', marginTop: '1rem' }}
            >
                {loading ? (
                    <Loading />
                ) : (
                    <BookingTable
                        bookingsList={bookingData}
                        loading={loading}
                    />
                )}
            </div>
        </>
    );
};
export default DashboardBooking;
