import { Spin } from "antd";
import React from "react";

function Loading() {
  return (
    <div className="loading-wrapper">
      <Spin tip="Loading..." >
        {/* <div className="content" ></div> */}
      </Spin>
    </div>
  );
}

export default Loading;