import React, { useState } from "react";
import AppConstant from "../../../constant/AppConstant.js";
import { serialize } from "object-to-formdata";
import { getUrl } from "./service.js";
import Modal from "react-modal";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { commonSelector } from "../../../store/slices/common/commonSlice.js";
import AddEditTranslations from "./AddEditTranslations.js";
import { convertJSONtoFormData } from "../../../helper/_commonHelper.js";

const AddEditMenu = ({ title, data, setShow, refresh, open, setSuccess }) => {
  console.log(data);
  const { hotelDetails = {} } = useSelector(commonSelector);
  
  const [formData, setFormData] = useState({
    name: data?.name || "",
    description: data?.description || "",
    type:
      data?.type || hotelDetails.type === "restaurant_manager"
        ? "restaurant"
        : "default",
    image: data?.image || undefined,
    disclaimer: data?.disclaimer || "",
    priority: data?.priority || 0,
    start_time: data?.start_time || "",
    end_time: data?.end_time || "",
    view_only: data?.view_only || 0,
    translations: data?.translations || [],
  });
  const [errors, setError] = useState(null);
  const [rows ,setRows]=useState([]);

  const handleChange = (evt) => {
    let value = null;
    if (evt.target.type === "checkbox") {
      value = evt.target.checked;
    } else if (evt.target.type === "file") {
      value = evt.target.files[0];
    } else {
      value = evt.target.value;
    }
    console.log(value);
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
    console.log(formData);
  };

  const handleViewChange = (e) => {
    setFormData({
      ...formData,
      view_only: Number(e.target.checked),
    });
  };

  const saveForm = async () => {
    if (data?.id) {
      delete formData.type;
      if (formData.image && typeof formData.image !== "object") {
        delete formData?.image;
      }
    }

    try {
      let updatedRows=rows.map((row)=>({
        hotel_language_id:row.hotel_language_id,
        name:row.name,
        description:row.description,
        desclaimer:row.desclaimer,

      }))
      formData['translations']=updatedRows;
      const datatoSend = convertJSONtoFormData(formData);


      let option = {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          //   'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: "Bearer " + localStorage.getItem("user"),
        },
        body: datatoSend,
      };

      let response = await fetch(
        AppConstant.config.appBaseUrl +
          getUrl() +
          (data?.id ? "/update/menu" : "/menu") +
          (data?.id ? "/" + data?.id : ""),
        option
      );

      let result = await response.json();

      if (result?.message && response.status === 400) {
        const keys = Object.keys(result?.message);
        let errs = keys?.map((key) => {
          return {
            field: key,
            error: result?.message?.[key]?.[0],
          };
        });
        setError(errs);
      } else {
        if (response.ok) {
          setSuccess(result.message);
          refresh();
          setShow(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async () => {
    await saveForm();
  };

  const deleteImage = async () => {
    if (window.confirm("Do you want to delete this image?") == false) {
      return;
    }
    try {
      const payload = {
        entity_type: "menu",
        entity_id: data?.id,
      };
      const response = await axios.delete("/image/image", { data: payload });
      console.log(response);
      if (response.status === 200) {
        setFormData({
          ...formData,
          image: undefined,
        });
      }
    } catch (error) {}
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setShow(false)}
      className={"modal-dialog modal-lg modal-customzaition"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Menu Modal"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        <div className="modal-header">
          <div className="title">
            {data?.id ? "Update " + title : "Add " + title}
          </div>
          <button className="btn btn-icon" onClick={() => setShow(false)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="modal-body">
          {/* FORM */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Disclaimer (this text will appear on the 'i' icon on top right of
              the menu)
            </label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Disclaimer"
              name="description"
              rows="5"
              cols="10"
              value={formData.description}
              onChange={handleChange}
            ></textarea>
          </div>
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">type</label>
              <input
                type="text"
                className="form-control"
                placeholder="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
              />
            </div> */}
          {
            <div className="form-group row">
              <div className="col-sm-6">
                <label htmlFor="exampleInputEmail1">Image</label>
                <input
                  type="file"
                  className="form-control"
                  placeholder="image"
                  name="image"
                  // value={formData.image}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-6">
                {formData?.image && typeof formData?.image != "object" && (
                  <div className="image-wrapper">
                    <img
                      src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menus/${encodeURIComponent(formData.image)}`}
                      alt="category"
                      className="image"
                    />
                    <button
                      className="btn btn-icon btn-danger"
                      onClick={deleteImage}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Disclaimer</label>
              <input
                type="text"
                className="form-control"
                placeholder="Disclaimer"
                name="disclaimer"
                value={formData.disclaimer}
                onChange={handleChange}
              />
            </div> */}
          
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Priority</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="priority"
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="">Menu Type</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    htmlFor="menuType"
                    style={{ fontSize: "12px", margin: "0 3px" }}
                  >
                    Orderable
                  </label>
                  <Form>
                    <Form.Check
                      type="switch"
                      onChange={handleViewChange}
                      checked={formData.view_only}
                      label=""
                      name="view_only"
                      id={data.id || 1}
                    />
                  </Form>
                  <label
                    htmlFor="menuType"
                    style={{ fontSize: "12px", margin: 0 }}
                  >
                    View Only
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="translations">Translations</label>
              <AddEditTranslations
              translations={formData.translations}
              rows={rows}
              setRows={setRows}
              showDescriptionField={true}
              />
            </div>
            </div>
          </div>

          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">start_time</label>
              <input
                type="time"
                className="form-control"
                placeholder="start_time"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">end_time</label>
              <input
                type="time"
                className="form-control"
                placeholder="end_time"
                name="end_time"
                value={formData.end_time}
                onChange={handleChange}
              />
            </div> */}

          <div className="text-danger">
            {errors &&
              errors.map((err) => (
                <>
                  <div>
                    {err?.field?.toUpperCase()} : {err?.error}
                  </div>
                </>
              ))}
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn" onClick={() => setShow(false)}>
            Close without saving
          </button>
          <button className="btn btn-primary" onClick={() => submit()}>
            Save and close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddEditMenu;
