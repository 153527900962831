import React, { useState, useEffect } from 'react';
import { Breadcrumb, Row, Col, message } from 'antd';
import SchedulerCard from '../card';
import { getIRDMenus } from '../../services/apiService';
import { useSelector } from 'react-redux';
import { commonSelector } from '../../store/slices/common/commonSlice';

const SERVICE_TYPES = {
    RESTAURANT: 'ird_restaurant',
    IRD: 'ird'
};

const IRDScheduler = () => {
    const [menus, setMenus] = useState([]);
    const { hotelDetails } = useSelector(commonSelector);

    const service= hotelDetails?.type === 'restaurant_manager' ? SERVICE_TYPES.RESTAURANT : SERVICE_TYPES.IRD;

    useEffect(() => {
        fetchMenus();
    }, []);

    const fetchMenus = async () => {
        try {
            const response = await getIRDMenus(service);
            if (response.status === 200) {
                const result = service ==='ird' ? response.data?.data?.ird || [] : response.data?.data?.ird_restaurant || [];
                setMenus(result);
            }
        } catch (error) {
            console.error('Error fetching menus:', error);
            message.error('Failed to fetch menus');
        }
    };

    return (
        <>
            <div className='scheduler-header'>
                <div className='page-title'>
                    {service === SERVICE_TYPES.RESTAURANT
                        ? 'Restaurant'
                        : 'IN ROOM DINING'}
                </div>
                <Breadcrumb
                    items={[
                        {
                            title: (
                                <a href='/ird'>
                                    {service === SERVICE_TYPES.RESTAURANT
                                        ? 'Restaurant '
                                        : 'IRD'}
                                </a>
                            ),
                        },
                        { title: 'Scheduler' },
                    ]}
                />
            </div>
            <Row gutter={[16, 16]}>
                {menus.map((menu) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={menu.id}>
                        <SchedulerCard setMenus={setMenus} menu={menu} fetchMenus={fetchMenus} service={service}/>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default IRDScheduler;
