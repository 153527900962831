import firebase from "firebase/app";
import "firebase/messaging";

// Project Settings => Add Firebase to your web app
const firebaseConfig = {
  apiKey: "AIzaSyDMenPPfFPkGgycPc_abHnhGGv7AgBo3LY",
  authDomain: "touchless-food-ordering.firebaseapp.com",
  databaseURL: "https://touchless-food-ordering.firebaseio.com",
  projectId: "touchless-food-ordering",
  storageBucket: "touchless-food-ordering.appspot.com",
  messagingSenderId: "879994877948",
  appId: "1:879994877948:web:c9f06ae453123b0256a479",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : {};

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
