import React, { useEffect, useState } from 'react';
import { groupByOrdersByStatus } from '../../helper/_commonHelper';
import {
    fetchIRDOrderList,
    irdSelector,
    updatePagination,
} from '../../store/slices/ird/irdSlice';
import { useDispatch, useSelector } from 'react-redux';
import Accepted from './orderstatus/Accepted';
import Cleared from './orderstatus/Cleared';
import NewOrder from './orderstatus/NewOrder';
import { Badge, Breadcrumb, Input, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import NotificationBadge from '../../Shared/NotificationBadge';
import { useNotification } from '../../contexts/NotificationContextProvider';
import { commonSelector } from '../../store/slices/common/commonSlice';
import Loading from '../../Components/common/Loading';
import InfiniteScroll from 'react-infinite-scroller';

const ActionHeader = ({ searchValue, inputHandleChange, type }) => {
    return (
        <div className='action-header'>
            <div className='action-left'>
                <div className='page-title'>
                    {type === 'restaurant_manager'
                        ? 'Restaurant'
                        : 'IN ROOM DINING'}
                </div>
                <Breadcrumb
                    items={[
                        {
                            title: (
                                <a href='/ird'>
                                    {type === 'restaurant_manager'
                                        ? 'Restaurant '
                                        : 'IRD'}
                                </a>
                            ),
                        },
                        {
                            title: 'Dashboard',
                        },
                    ]}
                />
            </div>
            <div className='action-right'>
                <Input
                    type='text'
                    value={searchValue}
                    placeholder='Search'
                    style={{ width: '15rem' }}
                    prefix={<SearchOutlined />}
                    onChange={(e) => inputHandleChange(e)}
                />
            </div>
        </div>
    );
};
const Dashboard = (props) => {
    const [searchValue, setSearchvalue] = useState('');
    const { orderList, loading, pagination } = useSelector(irdSelector);

    const dispatch = useDispatch();
    const { hotelDetails } = useSelector(commonSelector);
    const { notificationData, updateOrderByType } = useNotification();

    useEffect(() => {
        if (notificationData['ird']) {
            updateOrderByType('ird');
        }
        if (notificationData['restaurent']) {
            updateOrderByType('restaurent');
        }
    }, []);

    // useEffect(() => {
    //     if (page && pagination.next_page_url) {
    //         dispatch(updatePagination({ current_page: page }));
    //         dispatch(fetchIRDOrderList());
    //     }
    // }, [page]);

    useEffect(() => {
        dispatch(fetchIRDOrderList(searchValue));
    }, [searchValue]);

    // let updatedOrderList = searchValue
    //     ? orderList.filter(
    //           (item) =>
    //               String(item.id).includes(searchValue) ||
    //               String(item?.primises?.premise_no).includes(searchValue) ||
    //               String(item?.table?.table_no).includes(searchValue)
    //       )
    //     : orderList;

    const orderData = groupByOrdersByStatus(orderList);

    const inputHandleChange = (e) => {
        setSearchvalue(e.target.value);
    };
    // const onPageChange = (pageNumber) => {
    //     dispatch(updatePagination({ current_page: pageNumber }));
    //     dispatch(fetchIRDOrderList());
    // };
    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: (
                <>
                    New Orders{' '}
                    {orderData?.new_order?.length > 0 ? (
                        <Badge
                            count={orderData?.new_order?.length}
                            style={{ backgroundColor: '#52c41a' }}
                        ></Badge>
                    ) : (
                        ''
                    )}
                </>
            ),

            children: (
                <NewOrder data={orderData.new_order || []} loading={loading} />

                // <InfiniteScroll
                //     loadMore={() => dispatch(fetchIRDOrderList())}
                //     hasMore={!!pagination.next_page_url}
                //     loader={<Loading />}
                // >
                //     <NewOrder
                //         data={orderData.new_order || []}
                //         loading={loading}
                //     />
                // </InfiniteScroll>
            ),
        },
        {
            key: '2',
            label: (
                <>
                    Accepted Orders{' '}
                    {orderData?.accepted?.length > 0 ? (
                        <Badge
                            count={orderData?.accepted?.length}
                            style={{ backgroundColor: '#52c41a' }}
                        ></Badge>
                    ) : (
                        ''
                    )}
                </>
            ),
            children: (
                <Accepted data={orderData.accepted || []} loading={loading} />

                // <InfiniteScroll
                //     loadMore={() => dispatch(fetchIRDOrderList())}
                //     hasMore={!!pagination.next_page_url}
                //     loader={<Loading />}
                // >
                //     <Accepted
                //         data={orderData.accepted || []}
                //         loading={loading}
                //     />
                // </InfiniteScroll>
            ),
        },
        {
            key: '3',
            label: (
                <>
                    Dispatched Orders{' '}
                    {orderData?.dispatched?.length > 0 ? (
                        <Badge
                            count={orderData?.dispatched?.length}
                            style={{ backgroundColor: '#52c41a' }}
                        ></Badge>
                    ) : (
                        ''
                    )}
                </>
            ),
            children: (
                <Cleared data={orderData.dispatched || []} loading={loading} />

                // <InfiniteScroll
                //     loadMore={() => dispatch(fetchIRDOrderList())}
                //     hasMore={!!pagination.next_page_url}
                //     loader={<Loading />}
                // >
                //     <Cleared
                //         data={orderData.dispatched || []}
                //         loading={loading}
                //     />
                // </InfiniteScroll>
            ),
        },
    ];

    return (
        <>
            <main
                className='status-tabs'
                style={{ paddingBottom: '1rem', minHeight: '100vh' }}
            >
                {(notificationData['ird'] ||
                    notificationData['restaurent']) && (
                    <NotificationBadge
                        type={
                            hotelDetails.type === 'restaurant_manager'
                                ? 'restaurent'
                                : 'ird'
                        }
                        updateOrderByType={updateOrderByType}
                    />
                )}
                <ActionHeader
                    inputHandleChange={inputHandleChange}
                    searchValue={searchValue}
                    type={hotelDetails.type}
                />
                <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
            </main>
            {/* <div className='pagination-wrapper'>
                <Pagination onChange={onPageChange} total={pagination.total} />
            </div> */}
        </>
    );
};
export default React.memo(Dashboard);
