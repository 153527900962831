import React, { useEffect, useState } from 'react';
import { groupByOrdersByStatus } from '../../helper/_commonHelper';
import { useDispatch, useSelector } from 'react-redux';
import Accepted from './orderstatus/Accepted';
import NewOrder from './orderstatus/NewOrder';
import { Badge, Breadcrumb, Button, Input, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
    fetchSpaOrderList,
    spaSelector,
} from '../../store/slices/spa/spaSlice';
import NotificationBadge from '../../Shared/NotificationBadge';
import { useNotification } from '../../contexts/NotificationContextProvider';
const ActionHeader = ({ searchValue, inputHandleChange }) => {
    return (
        <div className='action-header'>
            <div className='action-left'>
                <div className='page-title'>SPA</div>
                <Breadcrumb
                    items={[
                        {
                            title: <a href='/spa'>Spa</a>,
                        },
                        {
                            title: 'Dashboard',
                        },
                    ]}
                />
            </div>
            <div className='action-right'>
                <Input
                    type='text'
                    placeholder='Search'
                    style={{ width: '15rem' }}
                    prefix={<SearchOutlined />}
                    value={searchValue}
                    onChange={(e) => inputHandleChange(e)}
                />
            </div>
        </div>
    );
};
const DashboardSPA = (props) => {
    const [searchValue, setSearchvalue] = useState('');

    const { notificationData = {}, updateOrderByType } = useNotification();
    const dispatch = useDispatch();
    const { orderList, loading } = useSelector(spaSelector);

    useEffect(() => {
        // remove notification when manager land on dashboard becuase we'll fetch the new orders automatically
        if (notificationData['spa']) {
            updateOrderByType('spa');
        }
        dispatch(fetchSpaOrderList());
    }, []);

    let updatedOrderList = searchValue
        ? orderList.filter((item) => String(item.id).includes(searchValue) ||  String(item?.primises?.premise_no).includes(searchValue) || String(item?.table?.table_no).includes(searchValue) )
        : orderList;

    const orderData = groupByOrdersByStatus(updatedOrderList);
    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: (
                <>
                    New SPA Orders{' '}
                    {orderData?.new_order?.length > 0 ? (
                        <Badge
                            count={orderData?.new_order?.length}
                            style={{ backgroundColor: '#52c41a' }}
                        ></Badge>
                    ) : (
                        ''
                    )}
                </>
            ),

            children: (
                <NewOrder data={orderData.new_order || []} loading={loading} />
            ),
        },
        {
            key: '2',
            label: (
                <>
                    Accepted SPA Orders{' '}
                    {orderData?.accepted?.length > 0 ? (
                        <Badge
                            count={orderData?.accepted?.length}
                            style={{ backgroundColor: '#52c41a' }}
                        ></Badge>
                    ) : (
                        ''
                    )}
                </>
            ),
            children: (
                <Accepted data={orderData.accepted || []} loading={loading} />
            ),
        },
    ];

    const inputHandleChange = (e) => {
        setSearchvalue(e.target.value);
    };
    return (
        <main className='status-tabs'>
            {notificationData['spa'] && (
                <NotificationBadge
                    type={'spa'}
                    updateOrderByType={updateOrderByType}
                />
            )}
            <ActionHeader
                inputHandleChange={inputHandleChange}
                searchValue={searchValue}
            />
            <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
        </main>
    );
};
export default DashboardSPA;
