import React, { useReducer, useEffect, useState } from "react";
import Loading from "../../../Components/common/Loading";
import OrderCard from "../../../Components/common/OrderCard";
import OrderDetailsPopup from "../../../Components/common/OrderDetailsPopup";
import TransitionLoader from "../../../Components/common/TransitionLoader";
import AppConstant from "../../../constant/AppConstant";
const initialState = {
  loading: false,
  newOrderList: [],
  orderItem: {},
  showItemPopup: false,
};
function reducer(state, action) {
  switch (action.type) {
    case "loading": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "NEW_ORDER_LIST": {
      return {
        ...state,
        newOrderList: action.payload,
      };
    }
    case "ORDER_ITEM": {
      return {
        ...state,
        orderItem: action.payload,
      };
    }
    case "SHOW_ORDER_ITEM_POPUP": {
      return {
        ...state,
        showItemPopup: action.payload,
      };
    }
    default:
      throw new Error();
  }
}

const Accepted = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showTransitionLoader, setShowTransitionLoader] = useState(false);
  const { data, loading } = props;

  useEffect(() => {
    dispatch({ type: "NEW_ORDER_LIST", payload: props.data });
  }, [props.data]);

  const handleClosePopup = () =>
    dispatch({ type: "SHOW_ORDER_ITEM_POPUP", payload: false });
  const openOrderPopup = (item) => {
    dispatch({ type: "SHOW_ORDER_ITEM_POPUP", payload: true });
    dispatch({ type: "ORDER_ITEM", payload: item });
  };

  const handleClearSave = async (item) => {
    let option = {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("user"),
      },
    };

    try {
      setShowTransitionLoader(true);

      let spaOrderAcceptData = await fetch(
        AppConstant.config.appBaseUrl +
          "/connect/order/dispatch/" +
          item.order_detail.order_id,
        option
      );
      spaOrderAcceptData = await spaOrderAcceptData.json();
      dispatch({ type: "loading", payload: true });
      setTimeout(() => {
        setShowTransitionLoader(false);
      }, 2000);
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (error) {
      console.log("error", error);
    }
    handleHistory(item);
  };

  const handleHistory = async (item) => {
    let option = {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("user"),
      },
    };

    try {
      let spaOrderAcceptData = await fetch(
        AppConstant.config.appBaseUrl +
          "/connect/order/clear/" +
          item.order_detail.order_id,
        option
      );
      spaOrderAcceptData = await spaOrderAcceptData.json();
      dispatch({ type: "loading", payload: true });
      // console.log('amirNew', spaOrderAcceptData);
      // props.handleSave();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <section className="order-list-container">
      {loading ? (
        <Loading />
      ) : data.length === 0 ? (
        <div className="col-md-12">
          <div
            className=""
            style={{
              margin: "25vh auto",
              textAlign: "center",
              fontSize: "30px",
              color: "#8e8a8a",
            }}
          >
            <h3>No Order</h3>
          </div>
        </div>
      ) : (
          <div className="home-screen">
            {data.length > 0
              ? data.map((item, index) => {
                  return (
                    <OrderCard
                      tabType={"ClearOrder"}
                      ServiceName={"PRM"}
                      OrderType={"DISPATCHED"}
                      key={item.id}
                      item={item}
                      viewDetails={() => openOrderPopup(item)}
                      footerBtn={() => handleClearSave(item)}
                      btnTitle={"Clear"}
                      timerTime={item?.order_detail?.accepted_at}
                    />
                  );
                })
              : null}
          </div>
      )}
      {state.showItemPopup && (
        <OrderDetailsPopup
          open={state.showItemPopup}
          close={handleClosePopup}
          item={state.orderItem}
        />
      )}
      {showTransitionLoader && (
        <TransitionLoader
          previousStage={"Accepted"}
          newStage={"Order History"}
        />
      )}
    </section>
  );
};

export default Accepted