import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import axios from 'axios';
const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        updateLoading(state, { payload }) {
            state.loading = payload;
        },
        updateHotelDetails(state, { payload }) {
            state.hotelDetails = payload;
        },
        updateError(state, { payload }) {
            state.error = payload;
        },
        updateDeviceToken(state,{payload}){
          state.deviceToken=payload;
        },
    },
});

export const commonSelector = (state) => state.commonSlice;
export const { updateLoading, updateHotelDetails, updateError } =
    commonSlice.actions;

export default commonSlice.reducer;

export function fetchUserProfile() {
    return async (dispatch) => {
      try {
        dispatch(updateLoading(true));
        const response = await axios.get("/user/profile");
        if (response.status === 200) {
          const data = response.data.data || [];
          let type = data.user?.type || '';
          localStorage.setItem('user_type', type);
          dispatch(updateHotelDetails(data.user || {}));
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(updateLoading(false));
      }
    };
  }
  