import React, { useState, useEffect } from 'react';
import { Card, Button, message } from 'antd';
import AddSlot from './addSlot';
import {
    addEntitySchedule,
    updateEntitySchedule,
} from '../services/apiService';
import moment from 'moment-timezone';
import { ClockCircleOutlined } from '@ant-design/icons';
import IMAGE_NOT_FOUND from '../assets/images/NoImage.png';

const { Meta } = Card;

const SchedulerCard = ({ menu, setMenus, fetchMenus, service }) => {
    const [schedules, setSchedules] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const convertToLocal = (schedule) => ({
        start_time: schedule.start_time
            ? moment.utc(schedule.start_time, 'HH:mm').local().format('HH:mm')
            : null,
        end_time: schedule.end_time
            ? moment.utc(schedule.end_time, 'HH:mm').local().format('HH:mm')
            : null,
    });

    useEffect(() => {
        if (menu.schedules && menu.schedules.length > 0) {
            setSchedules(menu.schedules.map(convertToLocal));
        } else {
            setSchedules([]);
        }
    }, [menu]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleSaveSchedules = async (menuId, newSchedules) => {
        try {
            const payload = new FormData();
            payload.append('service', service);
            payload.append('entity_id', menuId);

            newSchedules.forEach((schedule, index) => {
                const utcStartTime = moment(schedule.start_time, 'HH:mm')
                    .utc()
                    .format('HH:mm');
                const utcEndTime = moment(schedule.end_time, 'HH:mm')
                    .utc()
                    .format('HH:mm');
                payload.append(`schedules[${index}][start_time]`, utcStartTime);
                payload.append(`schedules[${index}][end_time]`, utcEndTime);
            });

            let response;
            if (menu.schedules && menu.schedules.length > 0) {
                response = await updateEntitySchedule(payload, service, menuId);
            } else {
                response = await addEntitySchedule(payload);
            }

            if (response.status === 201 || response.status === 200) {
                message.success(
                    menu.schedules && menu.schedules.length > 0
                        ? 'Slots updated successfully'
                        : 'Slots added successfully'
                );
                fetchMenus();
            }
            return response.status;
        } catch (error) {
            console.error('Error saving slots:', error);
            message.error('An error occurred while saving slots.');
        }
    };

    return (
        <Card
            hoverable
            cover={
                <img
                    alt={'no imgae found'}
                    src={
                        menu?.image
                            ? `https://master-vserve.s3.ap-south-1.amazonaws.com/menus/${encodeURIComponent(
                                  menu?.image
                              )}`
                            : `${IMAGE_NOT_FOUND}`
                    }
                    className='menu-image'
                />
            }
        >
            <Meta title={menu.name} />
            {schedules.length > 0 ? (
                <div className='schedules-list-wrapper'>
                    {schedules.map((schedule, index) => (
                        <div key={index} className='schedule-item'>
                            <ClockCircleOutlined />
                            <div>
                                <span className='slot-label'>
                                    Slot {index + 1}:
                                </span>
                                <span className='slot-time'>
                                    {schedule.start_time} - {schedule.end_time}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p style={{ marginTop: 16 }}>No slots available</p>
            )}
            <Button onClick={showModal} className='action-btn' type='primary'>
                {schedules.length > 0 ? 'Edit Slots' : 'Add Slots'}
            </Button>
            <AddSlot
                setMenus={setMenus}
                menu={menu}
                schedules={schedules}
                setSchedules={setSchedules}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                handleSaveSchedules={handleSaveSchedules}
                convertToLocal={convertToLocal}
            />
        </Card>
    );
};

export default SchedulerCard;
