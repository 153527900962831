import React from 'react';
import moment from 'moment';
import NoDataFound from '../../Components/common/NoDataFound';

function BookingTable({ bookingsList }) {
    const timeCompare = (time) => {
        let timarr = time.split(':');
        return +timarr[0] * 60 + +timarr[1];
    };
    bookingsList = bookingsList?.sort((a, b) => {
        return timeCompare(a.booking_slot) - timeCompare(b.booking_slot);
    });

    const converTime = (time) => {
        if (time) {
            let covertedTime = time;
            let H = +covertedTime.substr(0, 2);
            let h = H % 12 || 12;
            h = h < 10 ? '0' + h : h;
            let ampm = H < 12 ? ' AM' : ' PM';
            covertedTime = h + covertedTime.substr(2, 3) + ampm;
            return covertedTime;
        } else {
            return time;
        }
    };

    const printNewOrder = () => {
        return (
            <table
                className='table table-striped table-hover table-history'
                id='table-allbooking'
            >
                <thead>
                    <tr>
                        <th className='text-center'>Time slots</th>
                        <th className='text-center'>Total Bookings</th>
                        <th className='text-center'></th>
                    </tr>
                </thead>

                {bookingsList.length === 0 ? (
                    <tbody>
                        <tr
                           style={{
                            backgroundColor: 'transparent',
                        }}  
                        >
                            <td colspan='3'>
                                <NoDataFound />
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    bookingsList?.map((booking, index) => {
                        const totalNoOfGuest = booking?.dataList?.reduce(
                            (a, v) => (a = a + v.no_of_guest),
                            0
                        );
                        return (
                            <tbody key={index}>
                                <tr>
                                    <td className='text-center'>
                                        {
                                            booking?.dataList[0]?.order_detail
                                                ?.booking_slot
                                        }{' '}
                                    </td>
                                    <td className='text-center '>
                                        {totalNoOfGuest}
                                    </td>
                                    <td className='text-center '>
                                        <button
                                            className='btn btn-primary'
                                            data-toggle='collapse'
                                            data-target={'#details-' + index}
                                        >
                                            <i className='fa fa-unsorted'></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='3' className='dataTD'>
                                        <div
                                            className='expandable-div collapse'
                                            id={'details-' + index}
                                        >
                                            <table className='table  table-order-inner'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>
                                                            Order ID
                                                        </th>
                                                        <th className='text-center'>
                                                            Booked at
                                                        </th>
                                                        <th className='text-center'>
                                                            Venue
                                                        </th>
                                                        <th className='text-center'>
                                                            Room/Villa No
                                                        </th>
                                                        <th className='text-center'>
                                                            Last Name
                                                        </th>
                                                        <th className='text-center'>
                                                            Number of Guests
                                                        </th>
                                                        <th className='text-center'>
                                                            Remarks
                                                        </th>
                                                        {/* <th
                        className="text-center"
                        style={{ borderTopWidth: "0" }}
                      >
                        Action
                      </th> */}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {booking?.dataList?.map(
                                                        (userData, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className='text-center'>
                                                                        {
                                                                            userData?.id
                                                                        }
                                                                    </td>

                                                                    <td
                                                                        className='text-center'
                                                                        style={{
                                                                            textTransform:
                                                                                'capitalize',
                                                                        }}
                                                                    >
                                                                        {moment(
                                                                            userData
                                                                                ?.order_detail
                                                                                ?.created_at
                                                                        ).format(
                                                                            'D MMM'
                                                                        )}
                                                                        <br />
                                                                        {moment(
                                                                            userData
                                                                                ?.order_detail
                                                                                ?.created_at
                                                                        ).format(
                                                                            'LT'
                                                                        )}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {
                                                                            userData
                                                                                ?.order_booking_services[0]
                                                                                ?.booking_service_name
                                                                        }
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {
                                                                            userData
                                                                                ?.primises
                                                                                ?.premise_no
                                                                        }
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {
                                                                            userData
                                                                                ?.guest
                                                                                ?.name
                                                                        }
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {
                                                                            userData?.no_of_guest
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        className='text-center'
                                                                        style={{
                                                                            maxWidth:
                                                                                '200px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            userData?.description
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        );
                    })
                )}
            </table>
        );
    };
    return <>{printNewOrder()}</>;
}

export default BookingTable;
