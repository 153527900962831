import React, { useState } from "react";
import AppConstant from "../../../constant/AppConstant.js";
import { getUrl } from "./service.js";
import Modal from "react-modal";
import AddEditTranslations from "./AddEditTranslations.js";
import { convertJSONtoFormData } from "../../../helper/_commonHelper.js";

const AddEditSubAddOn = ({
  title,
  data,
  ids,
  setShow,
  open,
  refresh,
  setSuccess,
}) => {
  const [formData, setFormData] = useState({
    menu_item_id: ids?.itemId,
    name: data?.name || "",
    description: data?.description || "",
    priority: data?.priority || 0,
    price: data?.price || 0,
    type: data?.type || "single_select",
    max_addon_per_order: data?.max_addon_per_order,
    min_addon_per_order: data?.min_addon_per_order || 0,
    tags: [],
    translations: data?.translations || [],
  });
  const [errors, setError] = useState(null);
  const [rows, setRows] =useState([]);

  const handleChange = (evt) => {
    let value = null;
    if (evt.target.type === "checkbox") {
      value = evt.target.checked;
    } else if (evt.target.type === "file") {
      value = evt.target.files[0];
    } else {
      value = evt.target.value;
    }
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  const saveForm = async () => {
    if (data?.id) {
      delete formData.menu_id;
    }

    try {

      let updatedRows=rows.map((row)=>({
        hotel_language_id:row.hotel_language_id,
        name:row.name,
        description:row.description,
        desclaimer:row.desclaimer,

      }))

      formData['translations']=updatedRows;
      const datatoSend=convertJSONtoFormData(formData);

      let option = {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          //   'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: "Bearer " + localStorage.getItem("user"),
        },
        body: datatoSend,
      };

      let response = await fetch(
        AppConstant.config.appBaseUrl +
          getUrl() +
          (data?.id
            ? "/update/menu/category/item/sub-addon"
            : "/menu/sub-addon") +
          (data?.id ? "/" + data?.id : ""),
        option
      );
      let result = await response.json();

      if (result?.message && response.status === 400) {
        const keys = Object.keys(result?.message);
        let errs = keys?.map((key) => {
          return {
            field: key,
            error: result?.message?.[key]?.[0],
          };
        });
        setError(errs);
      } else {
        if (response.ok) {
          setSuccess(result.message);
          refresh();
          setShow(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async () => {
    await saveForm();
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setShow(false)}
      className={"modal-dialog modal-lg modal-order-details"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Example Modal"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        <div className="modal-header">
          <div className="title">
            {data?.id ? "Edit " + title : "Add " + title}
          </div>

          <button className="btn btn-icon" onClick={() => setShow(false)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="modal-body">
          {/* FORM */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </div> */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Type</label>
            <select
              className="form-control"
              value={formData.type}
              onChange={(e) => handleChange(e)}
              name="type"
            >
              <option value={"single_select"}>Single Select</option>
              <option value={"multi_select"}>Multi Select</option>
            </select>
          </div>
          {/* {
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Image</label>
                <input
                  type="file"
                  className="form-control"
                  placeholder="image"
                  name="image"
                  // value={formData.image}
                  onChange={handleChange}
                />
              </div>
            } */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Priority</label>
            <input
              type="number"
              className="form-control"
              placeholder="priority"
              name="priority"
              value={formData.priority}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Maximum options that a guest can choose.
            </label>
            <input
              type="number"
              className="form-control"
              placeholder="Choose a number greater than 0"
              name="max_addon_per_order"
              value={formData.max_addon_per_order}
              onChange={handleChange}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="translations">Translations</label>
              <AddEditTranslations
              translations={formData.translations}
              rows={rows}
              setRows={setRows}
              showDescriptionField={false}
              />
            </div>
            </div>
          </div>
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">min per order</label>
              <input
                type="number"
                className="form-control"
                placeholder="min_addon_per_order"
                name="min_addon_per_order"
                value={formData.min_addon_per_order}
                onChange={handleChange}
              />
            </div> */}
          <div className="text-danger">
            {errors &&
              errors.map((err) => (
                <>
                  <div>
                    {err?.field?.toUpperCase()} : {err?.error}
                  </div>
                </>
              ))}
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn" onClick={() => setShow(false)}>
            Close
          </button>
          <button className="btn btn-primary" onClick={() => submit()}>
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddEditSubAddOn;
