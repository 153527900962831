import { BASE_URL, getOrderSource } from "../../../services/apiService";

const initialState = {
    loading: false,
    orderList: [],
    orderhistory: [],
    itemList: [],
    maxPageLimit: 0,
    totalOrderCount: 0,
    revenueData: {},
    revenueOrderList: {},
    analyticsData: [],
    deviceToken: 'abcdefghijkl',
    menuList: [],
    message: '',
    actionTakenOnOrderID: null,
    pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page:1,
        next_page_url: `${BASE_URL}api/v5.0.2/${getOrderSource()}/order?page=1`
    },
};

export default initialState;
